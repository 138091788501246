/* eslint-disable react/display-name */
import * as React from 'react'
import { BLOCKS } from '@contentful/rich-text-types'
import ContentfulRichText from 'components/rich-text'
import { IGenericPageSection } from 'interfaces/generic-page-section'
import twConfig from 'tailwind-light-config'
const { screens } = twConfig
import styles from './style.module.css'

interface IProps {
    section: IGenericPageSection
}

const lgBreakPoint = screens.lg
const _2xlBreakPoint = screens['2xl']

const headerRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => (
        <p className="text-1xl lg:text-3.25xl text-center lg:text-left font-medium tracking-tight leading-120%">
            {children}
        </p>
    ),
}

const subHeaderRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => (
        <p className="text-xsm text-center lg:text-left font-bold tracking-wider leading-120%">{children}</p>
    ),
}

const paragraphRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => (
        <p className="text-xsm lg:text-base text-center lg:text-left tracking-wider leading-120% lg:leading-140%">
            {children}
        </p>
    ),
}

export const TheoryOfChange = ({ section }: IProps): React.ReactElement => {
    if (!section) {
        return null
    }

    const { paragraphCopy, header, richSubHeader, mediaCollection, mobileMediaCollection, backgroundColor } = section

    const mobileImage = mobileMediaCollection?.items?.[0]
    const desktopImage = mediaCollection?.items?.[0]

    return (
        <section
            className="section section--bigger-padding lg:px-24 xl:px-40 border-t border-black border-opacity-20"
            style={{ backgroundColor }}
        >
            <div className={styles['columns']}>
                {/* header */}
                {header?.json && (
                    <ContentfulRichText content={header.json} className="header" renderNode={headerRenderNode} />
                )}

                <div className="mt-10 lg:mt-0">
                    {/* sub header */}
                    {richSubHeader?.json && (
                        <ContentfulRichText
                            content={richSubHeader.json}
                            className="sub-header"
                            renderNode={subHeaderRenderNode}
                        />
                    )}

                    {/* paragraph */}
                    {paragraphCopy?.json && (
                        <ContentfulRichText
                            content={paragraphCopy.json}
                            className="paragraph mt-5"
                            renderNode={paragraphRenderNode}
                        />
                    )}
                </div>
            </div>

            {/* image */}
            {(mobileImage?.url || desktopImage?.url) && (
                <picture>
                    {/* desktop image */}
                    {desktopImage?.url && (
                        <>
                            {desktopImage.url.endsWith('.svg') && (
                                <source
                                    type="image/svg+xml"
                                    media={`(min-width: ${lgBreakPoint})`}
                                    srcSet={desktopImage.url}
                                />
                            )}

                            <source
                                type="image/webp"
                                media={`(min-width: ${lgBreakPoint})`}
                                srcSet={`
                                            ${desktopImage.url}?fm=webp&w=1024 1024w,
                                            ${desktopImage.url}?fm=webp&w=1280 1280w,
                                            ${desktopImage.url}?fm=webp&w=1600 1600w,
                                            ${desktopImage.url}?fm=webp&w=2000 2000w
                                        `}
                                sizes={`(min-width: ${_2xlBreakPoint}) 1600px, 90vw`}
                            />

                            <source
                                type="image/jpeg"
                                media={`(min-width: ${lgBreakPoint})`}
                                srcSet={`
                                            ${desktopImage.url}?fm=jpg&w=1024 1024w,
                                            ${desktopImage.url}?fm=jpg&w=1280 1280w,
                                            ${desktopImage.url}?fm=jpg&w=1600 1600w,
                                            ${desktopImage.url}?fm=jpg&w=2000 2000w
                                        `}
                                sizes={`(min-width: ${_2xlBreakPoint}) 1600px, 90vw`}
                            />
                        </>
                    )}

                    {/* mobile image */}
                    {mobileImage?.url && (
                        <>
                            {mobileImage.url.endsWith('.svg') && (
                                <source type="image/svg+xml" srcSet={mobileImage.url} />
                            )}

                            <source
                                type="image/webp"
                                srcSet={`
                                            ${mobileImage.url}?fm=webp&w=320 320w,
                                            ${mobileImage.url}?fm=webp&w=640 640w,
                                            ${mobileImage.url}?fm=webp&w=820 820w,
                                            ${mobileImage.url}?fm=webp&w=1024 1024w
                                        `}
                            />

                            <source
                                type="image/jpeg"
                                srcSet={`
                                            ${mobileImage.url}?fm=jpg&w=320 320w,
                                            ${mobileImage.url}?fm=jpg&w=640 640w,
                                            ${mobileImage.url}?fm=jpg&w=820 820w,
                                            ${mobileImage.url}?fm=jpg&w=1024 1024w
                                        `}
                            />
                        </>
                    )}

                    <img
                        src={(mobileImage?.url || desktopImage?.url) + '?fm=jpg&w=1024'}
                        alt={
                            mobileImage?.title ||
                            mobileImage?.description ||
                            desktopImage?.title ||
                            desktopImage?.description
                        }
                        height={mobileImage?.height || desktopImage?.height}
                        width={mobileImage?.width || desktopImage?.width}
                        className="mt-16 lg:mt-28 block mx-auto w-full lg:w-full"
                        loading="lazy"
                    />
                </picture>
            )}
        </section>
    )
}
