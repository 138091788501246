import cn from 'classnames'
import * as React from 'react'
import Video from 'components/video/video'
import { IImage, IVideo } from 'interfaces/media'
import globalManifest from 'data/global-manifest.json'
import { IconButton } from 'components/buttons/button'
import { colors } from 'theme.cjs'
import PauseIcon from 'icons/pause-icon'
import PlayIcon from 'icons/play-icon'
import { LOADING } from 'enum/loading'

interface IProps {
    video: IVideo
    poster?: IImage
    className?: string
    posterClassName?: string
    iconButtonClassName?: string
    playButtonIconClassName?: string
    pauseButtonIconClassName?: string
    autoPlay?: boolean
    isGif?: boolean
    loading?: LOADING
    playMediaLabel?: string
    pauseMediaLabel?: string
}

const { resources: globalUIResources } = globalManifest

export const VideoCard = ({
    video,
    poster,
    playMediaLabel,
    pauseMediaLabel,
    className = '',
    posterClassName = '',
    iconButtonClassName = '',
    playButtonIconClassName = '',
    pauseButtonIconClassName = '',
    autoPlay,
    isGif,
    loading,
}: IProps): React.ReactElement => {
    const [isPlaying, setIsPlaying] = React.useState(false)
    const [videoPlayerEl, setVideoPlayerEl] = React.useState<HTMLVideoElement>()
    const [hasVideoStarted, setHasVideoStarted] = React.useState(false)

    const videoPlayerRef = React.useCallback((node) => {
        if (node !== null) {
            setVideoPlayerEl(node)
        }
    }, [])

    React.useEffect(() => {
        if (!videoPlayerEl) {
            return
        }

        const playEventListener = () => {
            setIsPlaying(true)
            setHasVideoStarted(true)
        }

        const pauseEventListener = () => {
            setIsPlaying(false)
        }

        videoPlayerEl.addEventListener('play', playEventListener)
        videoPlayerEl.addEventListener('pause', pauseEventListener)

        return () => {
            videoPlayerEl.removeEventListener('play', playEventListener)
            videoPlayerEl.removeEventListener('pause', pauseEventListener)
        }
    }, [videoPlayerEl])

    const toggleVideoPlay = () => {
        if (videoPlayerEl) {
            if (isPlaying) {
                videoPlayerEl.pause()
            } else {
                videoPlayerEl.play()
            }
        }
    }

    if (!video?.url) {
        return null
    }

    return (
        <div className="h-full relative group overflow-hidden">
            <Video
                ref={videoPlayerRef}
                className={cn('w-full h-full object-cover', className)}
                height={video.height}
                width={video.width}
                autoPlay={autoPlay || isGif}
                playMediaLabel={playMediaLabel}
                muted={autoPlay || isGif}
                loop={isGif}
                loading={loading}
                playsInline={autoPlay || isGif}
            >
                <source src={video.url} type={video.contentType} />
                <span>{globalUIResources?.['browserDoesNotSupportVideoMessage']?.value}</span>
            </Video>

            {/* poster */}
            {!hasVideoStarted && poster?.url && (
                <picture className="absolute left-0 top-0 w-full h-full">
                    <source type="image/webp" srcSet={poster.url + '?fm=webp&w=400'} />
                    <source type="image/jpeg" srcSet={poster.url + '?fm=jpg&w=400'} />

                    <img
                        src={poster.url + '?fm=jpg&w=400'}
                        aria-hidden={true}
                        height={poster.height}
                        width={poster.width}
                        className={cn('w-full h-full object-cover', posterClassName)}
                        loading={loading}
                        alt=""
                    />
                </picture>
            )}

            {/* play/pause button */}
            {!isGif && (
                <IconButton
                    onClick={toggleVideoPlay}
                    className={`
                    w-20 h-20 lg:w-28 lg:h-28
                    ${isPlaying ? 'opacity-0' : ''} group-hover:opacity-100 duration-200
                    rounded-full border border-solid border-white
                    flex justify-center items-center
                    absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-20
                    backdrop-blur-sm
                    ${iconButtonClassName}
                `}
                    label={isPlaying ? pauseMediaLabel : playMediaLabel}
                >
                    {isPlaying ? (
                        <PauseIcon className={`scale-150 ${pauseButtonIconClassName}`} fill={colors.white} />
                    ) : (
                        <PlayIcon className={`scale-150 ${playButtonIconClassName}`} fill={colors.white} />
                    )}
                </IconButton>
            )}
        </div>
    )
}
