import * as React from 'react'
import { IPageSectionIcons } from 'interfaces/page-section-icons'
import LoopHorizontal from 'components/loop-horizontal'
import { getHTMLClassTypeNameFromSection } from '@/lib/util/css'

interface IProps {
    section: IPageSectionIcons
}

export const IconsRepeatingTextSection = ({ section }: IProps): React.ReactElement => {
    if (!section) {
        return null
    }

    const totalItemsToFillSection = 10

    const { iconsCollection: textCollection, backgroundColor } = section
    const textItems = textCollection.items

    let items = textItems

    // repeat items to fill array
    while (items.length < totalItemsToFillSection) {
        items = items.concat(textItems)
    }

    return (
        <section
            className={`${getHTMLClassTypeNameFromSection(section)} section--v2 m-0 px-0 py-3 lg:py-6 overflow-hidden`}
            style={{ backgroundColor }}
        >
            <LoopHorizontal containerClassName="space-x-4 lg:space-x-5 before:w-5 sm:before:w-6 lg:before:w-15 after:w-5 sm:after:w-6 lg:after:w-15 before:flex-shrink-0 after:flex-shrink-0">
                <div className="flex space-x-4 lg:space-x-5 flex-shrink-0">
                    {items.map((item, index) => (
                        <div
                            key={item.key + index}
                            className="text-xsm tracking-wider font-bold flex items-center last:after:hiddenn after:h-[5px] after:w-[5px] after:bg-[#F6917C] after:rounded-full after:ml-4 lg:after:ml-5 flex-shrink-0"
                            // aria hide items that were duplicated to fill space
                            aria-hidden={index >= textItems.length}
                        >
                            {item.value}
                        </div>
                    ))}
                </div>
            </LoopHorizontal>
        </section>
    )
}
