import { Feature } from 'components/store-locator/store-locator-map'
import * as Sentry from '@sentry/nextjs'

const accessToken = process.env.MAPBOX_ACCESS_TOKEN
const mapboxDatasetID = process.env.NEXT_PUBLIC_MAPBOX_DATASET_ID

export async function fetchGeoFeatures(): Promise<Feature[]> {
    try {
        const response = await fetch(
            `https://api.mapbox.com/datasets/v1/tancabinet/${mapboxDatasetID}/features?access_token=${accessToken}`,
        )
        const data = await response.json()
        return data.features
    } catch (error) {
        Sentry.captureException(error)
        return []
    }
}
