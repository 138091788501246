/* eslint-disable react/display-name */
import * as React from 'react'
import { BLOCKS } from '@contentful/rich-text-types'
import ProgressBar from 'components/progress-bar/progress-bar'
import ContentfulRichText from 'components/rich-text'
import { IPageSectionProject } from 'interfaces/page-section-project'
import twConfig from 'tailwind-light-config'
const { screens } = twConfig
import { colors } from 'theme.cjs'
import { IImage } from 'interfaces/media'
import styles from './style.module.css'
import Link from 'next/link'

interface IProps {
    section: IPageSectionProject
}

const lgBreakPoint = screens.lg

const headerRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => (
        <p className="text-center text-3.5xl lg:text-6.25xl font-medium tracking-tight leading-110%">{children}</p>
    ),
}

const subHeaderRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => (
        <p className="text-xbase lg:text-base leading-140% text-center">{children}</p>
    ),
}

const tableCellRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => (
        <p className="text-xbase lg:text-base leading-140% font-medium">{children}</p>
    ),
}

const renderIcon = (image: IImage) => {
    if (!image?.url) {
        return null
    }

    return (
        <picture className="float-left w-8 flex justify-center">
            {image.url.endsWith('.svg') && <source type="image/svg+xml" srcSet={image.url} />}
            <source type="image/webp" srcSet={image.url + '?fm=webp&w=100'} />
            <source type="image/jpeg" srcSet={image.url + '?fm=jpg&w=100'} />

            <img
                src={image.url + '?fm=jpg&w=100'}
                aria-hidden
                height={image.height}
                width={image.width}
                loading="lazy"
                className="float-left h-7"
                alt=""
            />
        </picture>
    )
}

const Metric = ({ metric }: { metric: Record<string, string | number> }): React.ReactElement => {
    const [progressLabelEl, setProgressLabelEl] = React.useState<HTMLSpanElement>()

    const progressLabelRef = React.useCallback((node) => {
        if (node !== null) {
            setProgressLabelEl(node)
        }
    }, [])

    const title = metric['Title']
    const goalTitle = metric['Goal Title']
    const goal = metric['Goal Number'] as number
    const progressLabel = metric['Progress to Goal Title']
    const progress = metric['Progress to Goal Number'] as number
    const metricColor = metric['Metric Color'] as string

    const progressPercentage = (progress / goal) * 100
    const progressRatio = progress / goal

    return (
        <div className="mt-2.5 font-medium text-xbase lg:text-base overflow-hidden">
            <div className="flex justify-between space-x-2">
                <div>{title}</div>
                <div className="text-gray-darker text-right">{goalTitle}</div>
            </div>
            <div
                className="relative"
                style={{
                    ['--progress' as string]: progressRatio,
                    ['--labelWidth' as string]: progressLabelEl?.offsetWidth + 'px',
                }}
            >
                {/* progress */}
                <ProgressBar
                    progress={progressPercentage}
                    progressColor={metricColor || colors.highlightBlue}
                    className="rounded-xl border border-black h-3.5 mt-3"
                />
                <div className={`relative mt-2.5 w-max ${styles['progress-label']}`}>
                    <span ref={progressLabelRef} className="inline-block">
                        {progressLabel}
                    </span>
                </div>
                <div className={`absolute top-[-9px] w-[var(--labelWidth)] ${styles['progress-line']}`}>
                    <div
                        className={`w-px h-[34px] border-r border-dashed border-gray mx-auto ${
                            progressRatio === 1 ? 'hidden' : ''
                        }`}
                    />
                </div>
            </div>
        </div>
    )
}

export const ProjectSection = ({ section }: IProps): React.ReactElement => {
    if (!section) {
        return null
    }

    const { sectionTitle, header, subHeader, media, columnOneCollection, columnTwo, cta } = section

    const desktopImage = media?.desktopImagesCollection?.items?.[0]
    const mobileImage = media?.mobileImagesCollection?.items?.[0]

    return (
        <section className="section--v2 mb-0 after:block after:border-t after:border-black/20 after:mt-17.5 after:-mb-[var(--sectionBlockMarginNegativeAdjustment)] lg:after:mb-auto">
            <div className="max-w-[810px] mx-auto">
                <div className="text-xsm font-bold leading-120% tracking-wider text-center">{sectionTitle}</div>

                {header?.json && (
                    <ContentfulRichText
                        content={header.json}
                        className="header mt-4 lg:mt-5"
                        renderNode={headerRenderNode}
                    />
                )}

                {subHeader?.json && (
                    <ContentfulRichText
                        content={subHeader.json}
                        className="sub-header mt-5 lg:mt-6 lg:px-28"
                        renderNode={subHeaderRenderNode}
                    />
                )}
            </div>

            {/* image */}
            {(desktopImage?.url || mobileImage?.url) && (
                <picture>
                    {/* desktop image */}
                    {desktopImage?.url && (
                        <>
                            <source
                                type="image/webp"
                                media={`(min-width: ${lgBreakPoint})`}
                                srcSet={`
                                        ${desktopImage.url}?fm=webp&w=1024 1024w,
                                        ${desktopImage.url}?fm=webp&w=1280 1280w,
                                        ${desktopImage.url}?fm=webp&w=1600 1600w,
                                        ${desktopImage.url}?fm=webp&w=2000 2000w
                                    `}
                            />

                            <source
                                type="image/jpeg"
                                media={`(min-width: ${lgBreakPoint})`}
                                srcSet={`
                                        ${desktopImage.url}?fm=jpg&w=1024 1024w,
                                        ${desktopImage.url}?fm=jpg&w=1280 1280w,
                                        ${desktopImage.url}?fm=jpg&w=1600 1600w,
                                        ${desktopImage.url}?fm=jpg&w=2000 2000w
                                    `}
                            />
                        </>
                    )}

                    {/* mobile image */}
                    {mobileImage?.url && (
                        <>
                            <source
                                type="image/webp"
                                srcSet={`
                                        ${mobileImage.url}?fm=webp&w=320 320w,
                                        ${mobileImage.url}?fm=webp&w=640 640w,
                                        ${mobileImage.url}?fm=webp&w=820 820w,
                                        ${mobileImage.url}?fm=webp&w=1024 1024w
                                    `}
                            />

                            <source
                                type="image/jpeg"
                                srcSet={`
                                        ${mobileImage.url}?fm=jpg&w=320 320w,
                                        ${mobileImage.url}?fm=jpg&w=640 640w,
                                        ${mobileImage.url}?fm=jpg&w=820 820w,
                                        ${mobileImage.url}?fm=jpg&w=1024 1024w
                                    `}
                            />
                        </>
                    )}

                    <img
                        src={(mobileImage?.url || desktopImage?.url) + '?fm=jpg&w=1024'}
                        alt={media?.title || media?.description}
                        height={mobileImage?.height || desktopImage?.height}
                        width={mobileImage?.width || desktopImage?.width}
                        className="mt-11 lg:mt-12 w-full"
                        loading="lazy"
                    />
                </picture>
            )}

            {/* columns */}
            <div className="mt-8 lg:mt-14 grid grid-cols-1 lg:grid-cols-2 gap-x-20">
                {columnOneCollection?.items?.length > 0 && (
                    <table>
                        <tbody>
                            {columnOneCollection.items.map((item) => (
                                <tr key={item.sys.id} className="border-t border-black/30 group">
                                    <td className="align-top font-bold text-xbase lg:text-base pt-2.5 pb-6 lg:pb-9 group-last:pb-0 pr-4 min-w-32">
                                        {item.title}
                                    </td>
                                    <td
                                        className={`font-medium text-xbase lg:text-base leading-140% pt-2.5 pb-6 lg:pb-9 lg:pl-12 group-last:pb-0 ${styles['table-cell-with-icon']}`}
                                    >
                                        <ContentfulRichText
                                            content={item.value.json}
                                            links={item.value.links}
                                            className="column-one-cell"
                                            renderNode={tableCellRenderNode}
                                            renderPicture={renderIcon}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}

                <div>
                    {Object.keys(columnTwo ?? {}).map((property, index) => (
                        <div key={index} className="border-t border-black/30">
                            <div className="mt-2.5 font-bold text-xbase lg:text-base">{property}</div>
                            <div className="mt-3.5">
                                {Object.keys(columnTwo[property]).map((p, i) => {
                                    const metric = columnTwo[property][p]

                                    return <Metric key={i} metric={metric} />
                                })}
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {/* cta */}
            {cta?.href && (
                <Link
                    href={cta.href}
                    className="button button-primary block py-1 mt-9 lg:mt-20 max-w-lg lg:max-w-[25rem] mx-auto"
                >
                    {cta.name}
                </Link>
            )}
        </section>
    )
}
