import * as React from 'react'
import { IVideo } from 'interfaces/media'
import Video from 'components/video/video'
import globalManifest from 'data/global-manifest.json'
import styles from './style.module.css'
import { IUiResource } from 'interfaces/ui-resource'
import * as Sentry from '@sentry/nextjs'
import { IconButton } from 'components/buttons/button'
import PlayIcon from 'icons/play-icon'
import { colors } from 'theme.cjs'
import { notAllowedErrorName } from 'misc-variables'

interface IProps {
    video: IVideo
    slideIndex: number
    setActiveSlide: React.Dispatch<React.SetStateAction<number>>
    activeSlide: number
    poster?: string
    totalSlides: number
    isSectionVisible: boolean
    videoProgressBarAriaLabel: IUiResource
    playMediaLabel: IUiResource
}

const { resources: globalUIResources } = globalManifest ?? {}

export const VideoSlide = ({
    video,
    slideIndex,
    activeSlide,
    setActiveSlide,
    poster,
    totalSlides,
    isSectionVisible,
    videoProgressBarAriaLabel,
    playMediaLabel,
}: IProps): React.ReactElement => {
    const [videoEl, setVideoEl] = React.useState<HTMLVideoElement>()
    const [progress, setProgress] = React.useState(0)
    const [, setMetadataLoaded] = React.useState({})
    const [canPlay, setCanPlay] = React.useState(false)
    const [showPlayButton, setShowPlayButton] = React.useState(false)

    const videoElRef = React.useCallback((node) => {
        if (node !== null) {
            setVideoEl(node)
        }
    }, [])

    const isPlayingRef = React.useRef(false)

    React.useEffect(() => {
        if (!videoEl) {
            return
        }

        let timer

        if (!isSectionVisible) {
            if (isPlayingRef.current) {
                videoEl.pause()
            }
            return
        }

        if (activeSlide === slideIndex) {
            if (canPlay) {
                const playPromise = videoEl.play()

                // older browsers don't return promise
                if (typeof playPromise !== 'undefined') {
                    playPromise
                        .then(() => {
                            isPlayingRef.current = true
                        })
                        .catch((e) => {
                            if (e.name === notAllowedErrorName) {
                                // couldn't play because of user permission
                                // show play button so user can play it themselves
                                setShowPlayButton(true)
                            } else {
                                Sentry.captureException(e)
                            }
                        })
                }
            }
        } else {
            // setting current time to 0 when not active
            // removes flash of frames when playing the video
            // from the beginning next time
            //
            // use timer to remove flash of frame as video fades out
            timer = setTimeout(() => {
                videoEl.currentTime = 0
            }, 500)

            if (isPlayingRef.current) {
                videoEl.pause()
                isPlayingRef.current = false
            }
        }

        return () => {
            clearTimeout(timer)
        }
    }, [activeSlide, slideIndex, canPlay, videoEl, isSectionVisible])

    React.useEffect(() => {
        if (!videoEl) {
            return
        }

        const timeupdateEventListener = () => {
            setProgress(videoEl.currentTime)
        }

        const loadedMetadataEventListener = () => {
            // trigger rerender to use loaded duration
            setMetadataLoaded({})
        }

        const endedEventListener = () => {
            const nextSlide = slideIndex >= totalSlides - 1 ? 0 : slideIndex + 1
            setActiveSlide(nextSlide)
        }

        const canPlayEventListener = () => {
            setCanPlay(true)
        }

        videoEl.addEventListener('loadedmetadata', loadedMetadataEventListener)
        videoEl.addEventListener('timeupdate', timeupdateEventListener)
        videoEl.addEventListener('ended', endedEventListener)
        videoEl.addEventListener('canplay', canPlayEventListener)

        return () => {
            videoEl.removeEventListener('pause', loadedMetadataEventListener)
            videoEl.removeEventListener('timeupdate', timeupdateEventListener)
            videoEl.removeEventListener('ended', endedEventListener)
            videoEl.removeEventListener('canplay', canPlayEventListener)
        }
    }, [videoEl, slideIndex, totalSlides, setActiveSlide])

    if (!video) {
        return null
    }

    const isActiveSlide = activeSlide === slideIndex

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!videoEl) {
            return
        }

        // if slide is active then seek to changed position
        // else set slide active
        if (isActiveSlide) {
            const value = Number(e.target.value)

            setProgress(value)
            videoEl.currentTime = value
        } else {
            setActiveSlide(slideIndex)
        }
    }

    const videoDuration = isNaN(videoEl?.duration) ? 1 : videoEl?.duration
    let videoProgress = 0

    if (isActiveSlide) {
        videoProgress = progress
    } else if (activeSlide > slideIndex) {
        videoProgress = videoDuration
    }

    const handlePlayButtonClick = () => {
        const playPromise = videoEl.play()

        // older browsers don't return promise
        if (typeof playPromise !== 'undefined') {
            playPromise
                .then(() => {
                    // remove play button as the playback begins
                    setShowPlayButton(false)

                    /**
                     * would the user want a pause button if we're
                     * showing them play button? The purpose of play
                     * button is for user interaction, after user has
                     * interacted with the site video autoplays don't
                     * throw error
                     */
                })
                .catch((e) => {
                    Sentry.captureException(e)
                })
        }
    }

    return (
        <div className="mb-10 lg:mb-0">
            <Video
                className={`duration-500 ${isActiveSlide ? 'opacity-100' : 'opacity-0'}`}
                poster={poster}
                ref={videoElRef}
                width="100%"
                muted
                playsInline
                // start loading full video of the next video
                preload={slideIndex - 1 === activeSlide ? 'auto' : 'metadata'}
            >
                <source src={video.url} type={video.contentType} />
                <span>{globalUIResources?.['browserDoesNotSupportVideoMessage']?.value}</span>
            </Video>

            {/* progress bar */}
            <input
                className={`absolute left-0 h-10 bottom-0 lg:bottom-5 z-20 cursor-pointer ${styles['progress-bar']}`}
                type="range"
                step={0.1}
                // fill progress bar if next slide is active
                value={videoProgress}
                max={videoDuration}
                onChange={handleChange}
                aria-label={videoProgressBarAriaLabel?.value as string}
                style={{
                    ['--position' as string]: slideIndex,
                    ['--progress' as string]: isNaN(videoEl?.duration)
                        ? '0%'
                        : (videoProgress / videoEl?.duration) * 100 + '%',
                }}
            />

            {/* play button */}
            {showPlayButton && (
                <IconButton
                    onClick={handlePlayButtonClick}
                    className={`
                    w-14 h-14 lg:w-28 lg:h-28
                    rounded-full border-[3px] lg:border-[5px] border-white
                    flex justify-center items-center
                    absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-20
                `}
                    label={playMediaLabel.value as string}
                >
                    <PlayIcon className="lg:scale-150" fill={colors.white} />
                </IconButton>
            )}
        </div>
    )
}
