import * as React from 'react'
import { IGenericPageSection } from 'interfaces/generic-page-section'
import twConfig from 'tailwind-light-config'
const { screens } = twConfig
import Link from 'next/link'
import ContentfulRichText from 'components/rich-text'
import { BLOCKS } from '@contentful/rich-text-types'

interface IProps {
    section: IGenericPageSection
}

const lgBreakPoint = screens.lg

const headerRenderNode = {
    // eslint-disable-next-line react/display-name
    [BLOCKS.PARAGRAPH]: (_node, children) => <h2 className="section--header">{children}</h2>,
}

export const GenericBannerSection = ({ section }: IProps): React.ReactElement => {
    if (!section) {
        return null
    }

    const { header, primaryCtaLink, mobileMediaCollection, mediaCollection, backgroundColor } = section

    const mobileImage = mobileMediaCollection.items?.[0]
    const desktopImage = mediaCollection.items?.[0]

    return (
        <section className="section--v2">
            <div style={{ backgroundColor }} className="rounded-2lg overflow-hidden grid md:grid-cols-2 p-8">
                {/* image */}
                {(mobileImage?.url || desktopImage?.url) && (
                    <picture>
                        {desktopImage?.url && (
                            <>
                                <source
                                    type="image/webp"
                                    media={`(min-width: ${lgBreakPoint})`}
                                    srcSet={`
                                    ${desktopImage.url}?fm=webp&w=420 420w,
                                    ${desktopImage.url}?fm=webp&w=640 640w
                                `}
                                    sizes="50vw"
                                />
                                <source
                                    type="image/jpeg"
                                    media={`(min-width: ${lgBreakPoint})`}
                                    srcSet={`
                                    ${desktopImage.url}?fm=jpg&w=420 420w,
                                    ${desktopImage.url}?fm=jpg&w=640 640w
                                `}
                                    sizes="50vw"
                                />
                            </>
                        )}

                        {mobileImage?.url && (
                            <>
                                <source
                                    type="image/webp"
                                    srcSet={`
                                    ${mobileImage.url}?fm=webp&w=375 375w,
                                    ${mobileImage.url}?fm=webp&w=420 420w,
                                    ${mobileImage.url}?fm=webp&w=640 640w,
                                `}
                                />
                                <source
                                    type="image/jpeg"
                                    srcSet={`
                                    ${mobileImage.url}?fm=jpg&w=375 375w,
                                    ${mobileImage.url}?fm=jpg&w=420 420w,
                                    ${mobileImage.url}?fm=jpg&w=640 640w,
                                `}
                                />
                            </>
                        )}

                        <img
                            src={(mobileImage?.url || desktopImage?.url) + '?fm=jpg&w=420'}
                            alt={
                                mobileImage?.description ||
                                desktopImage?.description ||
                                mobileImage?.title ||
                                desktopImage?.title
                            }
                            height={mobileImage?.height || desktopImage?.height}
                            width={mobileImage?.width || desktopImage?.width}
                            loading="lazy"
                            className="md:-mb-8 mx-auto"
                        />
                    </picture>
                )}

                {/* header + cta */}
                <div className="mt-5 md:mt-0 md:ml-5 self-center">
                    {header?.json && (
                        <ContentfulRichText content={header.json} renderNode={headerRenderNode} ignoreMarkdownStyles />
                    )}

                    {primaryCtaLink?.href && (
                        <Link
                            href={primaryCtaLink.href}
                            className="button button-primary inline-block mt-8 section--primary-cta"
                        >
                            {primaryCtaLink.name}
                        </Link>
                    )}
                </div>
            </div>
        </section>
    )
}
