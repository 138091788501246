import * as React from 'react'
import { BLOCKS } from '@contentful/rich-text-types'
import ContentfulRichText from 'components/rich-text'
import { IGenericPageSection } from 'interfaces/generic-page-section'
import twConfig from 'tailwind-light-config'
const { screens } = twConfig
import Link from 'next/link'

interface IProps {
    section: IGenericPageSection
}

const lgBreakPoint = screens.lg

export const TextOnPlainBackgroundSection = ({ section }: IProps): React.ReactElement => {
    if (!section) {
        return null
    }

    const { backgroundColor, header, richSubHeader, mediaCollection, mobileMediaCollection, primaryCtaLink } = section

    const desktopImage = mediaCollection?.items?.[0]
    const mobileImage = mobileMediaCollection?.items?.[0]

    const hasImageOrSubHeaderOrCTA = desktopImage || mobileImage || richSubHeader?.json || primaryCtaLink?.href

    const renderNode = {
        // eslint-disable-next-line react/display-name
        [BLOCKS.PARAGRAPH]: (_node, children) => (
            <p
                className={`${
                    hasImageOrSubHeaderOrCTA ? 'text-1xl' : 'text-lg'
                } lg:text-3.25xl text-center font-medium leading-120% tracking-tight`}
            >
                {children}
            </p>
        ),
    }

    return (
        <section
            className="section section--bigger-padding px-12 flex flex-col items-center"
            style={{ backgroundColor }}
        >
            {header?.json && (
                <ContentfulRichText
                    content={header.json}
                    renderNode={renderNode}
                    className="max-w-3xl"
                    parseSuperscriptTag
                />
            )}

            {/* image */}
            {(desktopImage || mobileImage) && (
                <picture className="mt-10 lg:mt-12">
                    {desktopImage?.url && (
                        <>
                            {/* svg */}
                            {desktopImage.url.endsWith('.svg') && (
                                <source
                                    type="image/webp"
                                    media={`(min-width: ${lgBreakPoint})`}
                                    srcSet={desktopImage.url}
                                    sizes={`(min-width: ${lgBreakPoint}: 200px), 180px`}
                                />
                            )}

                            {/* webp */}
                            <source
                                type="image/webp"
                                media={`(min-width: ${lgBreakPoint})`}
                                srcSet={`
                                            ${desktopImage.url}?fm=webp&w=640 640w,
                                            ${desktopImage.url}?fm=webp&w=820 820w,
                                            ${desktopImage.url}?fm=webp&w=1024 1024w
                                        `}
                                sizes={`(min-width: ${lgBreakPoint}: 200px), 180px`}
                            />

                            {/* jpeg */}
                            <source
                                type="image/jpeg"
                                media={`(min-width: ${lgBreakPoint})`}
                                srcSet={`
                                            ${desktopImage.url}?fm=jpg&w=200 200w,
                                            ${desktopImage.url}?fm=jpg&w=400 400w,
                                            ${desktopImage.url}?fm=jpg&w=600 600w
                                        `}
                                sizes={`(min-width: ${lgBreakPoint}: 200px), 180px`}
                            />
                        </>
                    )}

                    {mobileImage?.url && (
                        <>
                            {/* svg */}
                            {mobileImage.url.endsWith('.svg') && (
                                <source
                                    type="image/webp"
                                    srcSet={mobileImage.url}
                                    sizes={`(min-width: ${lgBreakPoint}: 200px), 180px`}
                                />
                            )}

                            {/* webp */}
                            <source
                                type="image/webp"
                                srcSet={`
                                            ${mobileImage.url}?fm=webp&w=200 200w,
                                            ${mobileImage.url}?fm=webp&w=400 400w,
                                            ${mobileImage.url}?fm=webp&w=600 600w
                                        `}
                                sizes="180px"
                            />

                            {/* jpeg */}
                            <source
                                type="image/jpeg"
                                srcSet={`
                                            ${mobileImage.url}?fm=jpg&w=180 180w,
                                            ${mobileImage.url}?fm=jpg&w=360 360w,
                                            ${mobileImage.url}?fm=jpg&w=540 540w
                                        `}
                                sizes="180px"
                            />
                        </>
                    )}

                    <img
                        src={(mobileImage?.url || desktopImage?.url) + '?fm=jpg&w=820'}
                        alt={
                            mobileImage?.description ||
                            desktopImage?.description ||
                            mobileImage?.title ||
                            desktopImage?.title
                        }
                        height={mobileImage?.height || desktopImage?.height}
                        width={mobileImage?.width || desktopImage?.width}
                        className="w-44 lg:w-50"
                        loading="lazy"
                    />
                </picture>
            )}

            {/* sub header */}
            {richSubHeader?.json && (
                <ContentfulRichText
                    content={richSubHeader.json}
                    renderNode={renderNode}
                    className="max-w-3xl mt-10 lg:mt-12"
                />
            )}

            {/* cta */}
            {primaryCtaLink?.href && (
                <Link
                    href={primaryCtaLink.href}
                    className="button button-secondary section--primary-cta mt-10 lg:mt-12 text-xbase"
                >
                    {primaryCtaLink.name}
                </Link>
            )}
        </section>
    )
}
