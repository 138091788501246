import * as React from 'react'
import RangeSlider from 'components/range-slider/range-slider'
import useIsomorphicLayoutEffect from 'hooks/useIsomorphicLayoutEffect'

interface IProps {
    children: React.ReactNode
    className?: string
    rangeClassName?: string
    rangeLabel: string
    hideSlider?: boolean
}

const rangeMax = 100

export const HorizontalScrollWithSlider = ({
    children,
    className = '',
    rangeClassName = '',
    rangeLabel,
    hideSlider,
}: IProps): React.ReactElement => {
    const containerElRef = React.useRef<HTMLDivElement>()
    const isDraggingRef = React.useRef(false)

    const [range, setRange] = React.useState(0)
    const [doesContainerOverflow, setDoesContainerOverflow] = React.useState(true)

    useIsomorphicLayoutEffect(() => {
        if (hideSlider) {
            return
        }

        const checkIfContentOverflows = () => {
            return containerElRef.current?.scrollWidth > containerElRef.current?.offsetWidth
        }

        // only show range input when container has scroll
        setDoesContainerOverflow(checkIfContentOverflows())

        let timer: NodeJS.Timeout

        const resizeEventListener = () => {
            clearTimeout(timer)

            // debounce
            timer = setTimeout(() => {
                setDoesContainerOverflow(checkIfContentOverflows())
            }, 300)
        }

        window.addEventListener('resize', resizeEventListener)

        return () => {
            window.removeEventListener('resize', resizeEventListener)
            clearTimeout(timer)
        }
    }, [hideSlider])

    React.useEffect(() => {
        const reviewsListEl = containerElRef.current

        if (!reviewsListEl || hideSlider) {
            return
        }

        const scrollListener = (e) => {
            if (isDraggingRef.current) return

            const scrollLeft = e.currentTarget.scrollLeft
            const clientWidth = e.currentTarget.clientWidth
            const scrollWidth = e.currentTarget.scrollWidth

            // range is ratio of current horizontal scroll over available horizontal scroll
            // ** multiply with caroulseItemsLength because max value of range is caroulseItemsLength
            const range = (scrollLeft / (scrollWidth - clientWidth)) * rangeMax
            if (!isNaN(range)) {
                setRange(range)
            }
        }

        reviewsListEl.addEventListener('scroll', scrollListener)

        return () => {
            reviewsListEl.removeEventListener('scroll', scrollListener)
        }
    }, [hideSlider])

    const rangeOnChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const draggedRange = Number(e.target.value)
        setRange(draggedRange)

        if (!containerElRef.current) {
            return
        }

        const clientWidth = containerElRef.current?.clientWidth
        const scrollWidth = containerElRef.current?.scrollWidth

        // scroll left is range multiplied by total horizontal scroll
        const scrollLeft = (draggedRange * (scrollWidth - clientWidth)) / rangeMax
        containerElRef.current.scrollLeft = scrollLeft
    }

    return (
        <>
            <div className={`overflow-x-auto scrollbar-hidden ${className}`} ref={containerElRef}>
                {children}
            </div>
            {doesContainerOverflow && !hideSlider && (
                <RangeSlider
                    label={rangeLabel}
                    onMouseDown={() => {
                        isDraggingRef.current = true
                    }}
                    onMouseUp={() => {
                        isDraggingRef.current = false
                    }}
                    onChange={rangeOnChangeHandler}
                    value={range}
                    min={0}
                    // set small step to have smooth animation
                    step="0.1"
                    className={rangeClassName}
                    max={rangeMax}
                />
            )}
        </>
    )
}
