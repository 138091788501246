/* eslint-disable react/display-name */
/* eslint-disable prettier/prettier */
import * as React from 'react'
import { BLOCKS } from '@contentful/rich-text-types'
import ContentfulRichText from 'components/rich-text'
import { DIRECTION_HORIZONTAL } from 'enum/direction'
import { IGenericPageSection } from 'interfaces/generic-page-section'

const headerRenderOptions = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (_node, children): React.ReactElement => <h2 className="section--header">{children}</h2>,
    },
}

const subHeaderRenderOptions = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (_node, children): React.ReactElement => (
            <div className="section--subheader text-xl lg:text-5xl lg:leading-13">{children}</div>
        ),
    },
}

const paragraphRenderOptions = {
    renderNode: {
        // eslint-disable-next-line react/display-name
        [BLOCKS.PARAGRAPH]: (_node, children): React.ReactElement => (
            <div className="section--paragraph text-sm mt-5 font-bold uppercase tracking-wider">{children}</div>
        ),
    },
}

// for lack of a better name
const ColoredTextSection = ({
    header,
    richSubHeader,
    paragraphCopy,
    align,
    mediaCollection,
    backgroundColor,
}: IGenericPageSection): React.ReactElement => {
    const circleMarkImage = mediaCollection?.items?.[0]

    return (
        <section className="section" style={{ backgroundColor }}>
            <div className="max-w-[865px]">
                <ContentfulRichText className="header" content={header?.json} renderOptions={headerRenderOptions} />
            </div>
            <div
                className={`mt-16 lg:mt-28 pt-1.5 flex justify-between items-center lg:items-end ${
                    align === DIRECTION_HORIZONTAL.LEFT ? 'flex-row-reverse' : 'flex-row'
                }`}
            >
                <div>
                    <ContentfulRichText content={richSubHeader?.json} renderOptions={subHeaderRenderOptions} />
                    <div className="hidden lg:block">
                        <ContentfulRichText content={paragraphCopy?.json} renderOptions={paragraphRenderOptions} />
                    </div>
                </div>
                {circleMarkImage && (
                    <img
                        alt={circleMarkImage?.title}
                        height={circleMarkImage?.height}
                        width={circleMarkImage?.width}
                        src={`${circleMarkImage?.url}?w=200`}
                        className="ml-2 h-20 w-20 lg:h-24 lg:w-24 lg:mr-7.5 shrink-0"
                        loading="lazy"
                    />
                )}
            </div>
        </section>
    )
}

export default ColoredTextSection
