/* eslint-disable react/display-name */
import * as React from 'react'
import { BLOCKS } from '@contentful/rich-text-types'
import CabinetTokenMask from 'components/cabinet-token/cabinet-token'
import ContentfulRichText from 'components/rich-text'
import { IFeaturedReview } from 'interfaces/featured-review'
import { IImage, IVideo } from 'interfaces/media'
import TitleWithColonIcon from 'components/title-with-colon-icon/title-with-colon-icon'
import { ICommonCopyDict } from 'interfaces/common-copy'
import { colors } from 'theme.cjs'
import Link from 'next/link'
import { IUiResources } from 'interfaces/ui-resource'
import VideoCard from 'components/video-card'

interface IProps {
    featuredReview: IFeaturedReview
    commonCopy: ICommonCopyDict
    uiResources: IUiResources
}

interface IReviewer {
    photo: IImage
    name: string
    title?: string
    handle?: string
    slug?: string
    handleLink?: string
}

const reviewRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => <p className="lg:text-xl leading-120% tracking-tight">{children}</p>,
}

// renders author image, name, title/handle and link
const Reviewer = ({ reviewer }: { reviewer: IReviewer }): React.ReactElement => {
    if (!reviewer) {
        return null
    }

    const { photo, name, title, handle, handleLink, slug } = reviewer

    return (
        <div className="flex mt-5 items-center">
            {/* reviewer photo */}
            {photo && (
                <CabinetTokenMask className="overflow-hidden" wrapperClassName="!h-11 !w-11 lg:!h-15 lg:!w-15 shrink-0">
                    <picture>
                        {/* webp */}
                        <source type="image/webp" srcSet={photo.url + '?fm=webp&w=300 300w'} />
                        {/* jpeg */}
                        <source type="image/jpeg" srcSet={photo.url + '?fm=jpg&w=300 300w'} />
                        <img
                            src={photo.url + '?fm=jpg&w=300'}
                            width={photo.width}
                            height={photo.height}
                            alt={photo.description || photo.title}
                            className="h-full w-full object-cover"
                            loading="lazy"
                        />
                    </picture>
                </CabinetTokenMask>
            )}

            {/* reviewer details */}
            <div className={photo ? 'ml-3.5 lg:ml-5' : ''}>
                {/* slug points to an internal page */}
                {slug ? (
                    <Link
                        href={'/authors/' + slug}
                        className="flex flex-col text-xsm font-bold not-italic uppercase leading-120% tracking-wider"
                    >
                        <span>{name}</span>
                        <div>{title}</div>
                    </Link>
                ) : (
                    <>
                        {/* handleLink points to an external page */}
                        {(handle || name) && (
                            <div className="text-xsm font-bold not-italic uppercase leading-120% tracking-wider">
                                {handleLink ? (
                                    <a
                                        className="flex flex-col text-xsm font-bold not-italic uppercase leading-120% tracking-wider"
                                        href={handleLink}
                                    >
                                        <span>{handle || name}</span>
                                    </a>
                                ) : (
                                    <span>{handle || name}</span>
                                )}
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    )
}

/**
 * Renders text review
 * @param props Iprops
 * @returns React.ReactElement
 */
const TextReview = ({ review }: { review: IFeaturedReview }): React.ReactElement => {
    if (!review) {
        return null
    }

    const reviewer: IReviewer = {
        name: review.author?.name || review.authorName,
        photo: review.author?.picture || review.authorPhoto,
        title: review.author?.title,
        handle: review.authorHandle,
        slug: review.author?.slug,
        handleLink: review.authorHandleLink,
    }

    return (
        <figure className="bg-black text-white h-full p-5 lg:p-7.5 flex flex-col justify-between">
            <span className={`h-0 grow overflow-y-auto`}>
                <ContentfulRichText content={review.content?.json} renderNode={reviewRenderNode} />
            </span>
            <figcaption>
                <cite className="mt-10 block">
                    <Reviewer reviewer={reviewer} />
                </cite>
            </figcaption>
        </figure>
    )
}

/**
 * Renders video review
 * @param props Iprops
 * @returns React.ReactElement
 */
const VideoReview = ({
    video,
    poster,
    uiResources,
    artist,
}: {
    video: IVideo
    poster: IImage
    uiResources: IUiResources
    artist: IReviewer
}): React.ReactElement => {
    if (!video) {
        return null
    }

    const { name, handle, handleLink, slug } = artist ?? {}

    return (
        <div className="h-full relative rounded-3xl overflow-hidden">
            <VideoCard
                video={video}
                playMediaLabel={uiResources['playMediaLabel'].value}
                pauseMediaLabel={uiResources['pauseMediaLabel'].value}
                poster={poster}
            />

            {/* artist handle */}
            {(handleLink || slug || handle || name) && (
                <div className="tag bg-white border-none absolute left-5 top-5 lg:left-7.5 lg:top-7.5">
                    {handleLink || slug ? (
                        <Link href={handleLink || slug} className="text-xsm font-bold tracking-wider">
                            {handle || name}
                        </Link>
                    ) : (
                        <span className="text-xsm font-bold tracking-wider">{handle || name}</span>
                    )}
                </div>
            )}
        </div>
    )
}

/**
 * Renders expert advice
 * @param props Iprops
 * @returns React.ReactElement
 */
const ExpertAdvice = ({
    advice,
    commonCopy,
}: {
    advice: IFeaturedReview
    commonCopy: ICommonCopyDict
}): React.ReactElement => {
    if (!advice) {
        return null
    }

    const reviewer: IReviewer = {
        name: advice.author?.name || advice.authorName,
        photo: advice.author?.picture || advice.authorPhoto,
        title: advice.author?.title,
        handle: advice.authorHandle,
        slug: advice.author?.slug,
        handleLink: advice.authorHandleLink,
    }

    return (
        <div className="h-full bg-black text-white p-5 lg:p-7.5 flex flex-col">
            <TitleWithColonIcon colonIconFill={colors.white} content={commonCopy?.['expertAdviceLabel']?.value?.json} />
            <figure className="mt-5 lg:mt-6 flex flex-col justify-between grow">
                <blockquote className="h-0 grow overflow-y-auto">
                    <ContentfulRichText content={advice.content?.json} renderNode={reviewRenderNode} />
                </blockquote>
                <figcaption>
                    <cite className="mt-10 block">
                        <Reviewer reviewer={reviewer} />
                    </cite>
                </figcaption>
            </figure>
        </div>
    )
}

export const FeaturedReview = ({ featuredReview, commonCopy, uiResources }: IProps): React.ReactElement => {
    if (!featuredReview) {
        return null
    }

    const renderReview = () => {
        switch (featuredReview.renderType) {
            case 'Text':
                return <TextReview review={featuredReview} />

            case 'Video':
                const artist: IReviewer = {
                    name: featuredReview.author?.name || featuredReview.authorName,
                    photo: featuredReview.author?.picture || featuredReview.authorPhoto,
                    title: featuredReview.author?.title,
                    handle: featuredReview.authorHandle,
                    slug: featuredReview.author?.slug,
                    handleLink: featuredReview.authorHandleLink,
                }

                return (
                    <VideoReview
                        uiResources={uiResources}
                        video={featuredReview.video}
                        poster={featuredReview.videoPosterImage}
                        artist={artist}
                    />
                )

            case 'Expert Advice':
                return <ExpertAdvice advice={featuredReview} commonCopy={commonCopy} />

            default:
                return null
        }
    }

    return <div className="rounded-3xl overflow-hidden">{renderReview()}</div>
}
