import * as React from 'react'
import styles from './style.module.css'

interface IProps {
    children: React.ReactNode
    containerClassName?: string
    loopDuration?: string
    direction?: 'right' | 'left'
}

export const LoopHorizontal = ({
    children,
    containerClassName,
    loopDuration = '20s',
    direction = 'left',
}: IProps): React.ReactElement => {
    const [containerEl, setContainerEl] = React.useState<HTMLDivElement>()
    const containerRef = React.useCallback((node) => {
        if (node !== null) {
            setContainerEl(node)
        }
    }, [])

    const [loop, setLoop] = React.useState(false)

    // workaround for a bug in safari where
    // css animation does not trigger on initial render
    React.useEffect(() => {
        setLoop(true)
    }, [])

    const loopStyle = direction === 'left' ? styles['loop-left'] : styles['loop-right']

    return (
        <div
            className={`flex ${containerClassName} ${loop ? loopStyle : ''}`}
            ref={containerRef}
            style={{
                ['--containerWidth' as string]: containerEl?.scrollWidth + 'px',
                ['--loopDuration' as string]: loopDuration,
            }}
        >
            {children}

            {/* duplicate content */}
            <div aria-hidden className="flex-shrink-0">
                {children}
            </div>
        </div>
    )
}
