/* eslint-disable react/display-name */
import * as React from 'react'
import ContentfulRichText from 'components/rich-text'
import { IGenericPageSection } from 'interfaces/generic-page-section'
import { BLOCKS } from '@contentful/rich-text-types'
import styles from './style.module.css'
import Link from 'next/link'
import featureFlags from 'feature-flags.json'
import Loader from 'components/loading/loader'
import twConfig from 'tailwind-light-config'
const { screens } = twConfig

const lgBreakPoint = Number(screens.lg.replace('px', ''))

const headerRenderOptions = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (_node, children): React.ReactElement => (
            <h2 className="section--header leading-10 lg:leading-13 tracking-tight">{children}</h2>
        ),
    },
}

const subHeaderRenderOptions = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (_node, children): React.ReactElement => (
            <p className="section--subheader mt-5 lg:text-2xl">{children}</p>
        ),
    },
}

const paragraphRenderOptions = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (_node, children): React.ReactElement => <p className="section--paragraph">{children}</p>,
    },
}

const TwoImagesSection = ({
    header,
    richSubHeader,
    mediaCollection,
    paragraphCopy,
    secondaryCtaLink,
    mobileMediaCollection,
    internalName,
}: IGenericPageSection): React.ReactElement => {
    const desktopIllustration = mediaCollection?.items?.[0]
    const mobileIllustration = mobileMediaCollection?.items?.[0]
    const desktopTeamImage = mediaCollection?.items?.[1]
    const mobileTeamImage = mobileMediaCollection?.items?.[1]

    const [isRedirecting, setIsRedirecting] = React.useState(false)
    const isStickersSection = internalName === 'Stickers'

    return (
        <section className={`${isStickersSection ? 'section--v2--sm' : 'section'}`}>
            <div className={`px-4 py-6 lg:p-7 rounded-2lg ${isStickersSection ? '' : 'bg-white'}`}>
                <div className={`${styles['header']}`}>
                    <ContentfulRichText className="header" content={header?.json} renderOptions={headerRenderOptions} />
                    <ContentfulRichText
                        className="subheader"
                        content={richSubHeader?.json}
                        renderOptions={subHeaderRenderOptions}
                    />
                </div>
                <div className="mt-10 grid grid-cols-1 lg:grid-cols-3 gap-x-7 gap-y-5 lg:gap-y-10">
                    {/* illustration */}
                    <div className="lg:col-start-1 lg:col-end-4">
                        {(desktopIllustration || mobileIllustration) && (
                            <picture>
                                {desktopIllustration && (
                                    <>
                                        {/* large screens */}
                                        {/* webp */}
                                        <source
                                            type="image/webp"
                                            media={`(min-width: ${lgBreakPoint}px)`}
                                            srcSet={`
                                                ${desktopIllustration.url}?w=1024&fm=webp&q=75 1024w,
                                                ${desktopIllustration.url}?w=1440&fm=webp&q=75 1440w,
                                                ${desktopIllustration.url}?w=1600&fm=webp&q=75 1600w
                                            `}
                                        />
                                        {/* jpg */}
                                        <source
                                            type="image/jpeg"
                                            media={`(min-width: ${lgBreakPoint}px)`}
                                            srcSet={`
                                                ${desktopIllustration.url}?w=1024&fm=jpg&q=75 1024w,
                                                ${desktopIllustration.url}?w=1440&fm=jpg&q=75 1440w,
                                                ${desktopIllustration.url}?w=1600&fm=jpg&q=75 1600w
                                            `}
                                        />
                                    </>
                                )}
                                {mobileIllustration && (
                                    <>
                                        {/* small screens */}
                                        {/* webp */}
                                        <source
                                            type="image/webp"
                                            srcSet={`
                                                ${mobileIllustration.url}?w=640&fm=webp&q=75 640w
                                            `}
                                        />
                                        {/* jpg */}
                                        <source
                                            type="image/jpeg"
                                            srcSet={`
                                                ${mobileIllustration.url}?w=640&fm=jpg&q=75 640w
                                            `}
                                        />
                                    </>
                                )}
                                <img
                                    width={desktopIllustration?.width || mobileIllustration?.width}
                                    alt={desktopIllustration?.title || mobileIllustration?.title}
                                    height={desktopIllustration?.height || mobileIllustration?.height}
                                    src={`${desktopIllustration?.url || mobileIllustration?.url}?w=1024&fm=jpg&q=75`}
                                    loading="lazy"
                                />
                            </picture>
                        )}
                    </div>
                    <div className="row-start-3 row-end-4 lg:row-start-2 lg:row-end-3 lg:col-start-1 lg:col-end-2">
                        <ContentfulRichText
                            className="paragraph"
                            content={paragraphCopy?.json}
                            renderOptions={paragraphRenderOptions}
                        />
                        {featureFlags?.meetTheTeam.enabled && (
                            <div className="flex justify-center lg:justify-start">
                                <Link
                                    href={secondaryCtaLink?.href}
                                    onClick={() => setIsRedirecting(true)}
                                    className="button button-secondary inline-block mt-5 lg:mt-7 min-w-250 group"
                                >
                                    {isRedirecting ? (
                                        <Loader dotClassname="text-black group-hover:text-white" />
                                    ) : (
                                        secondaryCtaLink?.name
                                    )}
                                </Link>
                            </div>
                        )}
                    </div>
                    {/* team photo */}
                    {(desktopTeamImage || mobileTeamImage) && (
                        <picture className="lg:col-start-2 lg:col-end-4">
                            {desktopTeamImage && (
                                <>
                                    {/* large screens */}
                                    {/* webp */}
                                    <source
                                        type="image/webp"
                                        media={`(min-width: ${lgBreakPoint}px)`}
                                        srcSet={`
                                            ${desktopTeamImage.url}?w=1024&fm=webp&q=75 1024w,
                                            ${desktopTeamImage.url}?w=1440&fm=webp&q=75 1440w,
                                            ${desktopTeamImage.url}?w=1600&fm=webp&q=75 1600w
                                        `}
                                        sizes="(min-width: 1024px) 67vw, 100vw"
                                    />
                                    {/* jpg */}
                                    <source
                                        type="image/jpeg"
                                        media={`(min-width: ${lgBreakPoint}px)`}
                                        srcSet={`
                                            ${desktopTeamImage.url}?w=1024&fm=jpg&q=75 1024w,
                                            ${desktopTeamImage.url}?w=1440&fm=jpg&q=75 1440w,
                                            ${desktopTeamImage.url}?w=1600&fm=jpg&q=75 1600w
                                        `}
                                        sizes="(min-width: 1024px) 67vw, 100vw"
                                    />
                                </>
                            )}
                            {mobileTeamImage && (
                                <>
                                    {/* small screens */}
                                    {/* webp */}
                                    <source
                                        type="image/webp"
                                        srcSet={`
                                            ${mobileTeamImage.url}?w=640&fm=webp&q=75 640w,
                                            ${mobileTeamImage.url}?w=1024&fm=webp&q=75 1024w
                                        `}
                                    />
                                    {/* jpg */}
                                    <source
                                        type="image/jpeg"
                                        srcSet={`
                                            ${mobileTeamImage.url}?w=640&fm=jpg&q=75 640w,
                                            ${mobileTeamImage.url}?w=1024&fm=jpg&q=75 1024w
                                        `}
                                    />
                                </>
                            )}
                            <img
                                width={desktopTeamImage?.width || mobileTeamImage?.width}
                                alt={desktopTeamImage?.title || mobileTeamImage?.title}
                                height={desktopTeamImage?.height || mobileTeamImage?.height}
                                src={`${desktopTeamImage?.url || mobileTeamImage?.url}?w=1024&fm=jpg&q=75`}
                                loading="lazy"
                            />
                        </picture>
                    )}
                </div>
            </div>
        </section>
    )
}

export default TwoImagesSection
