/* eslint-disable react/display-name */
import { BLOCKS } from '@contentful/rich-text-types'
import RangeSlider from 'components/range-slider/range-slider'
import ContentfulRichText from 'components/rich-text'
import useMobileScreenDetection from 'hooks/useMobileScreenDetection'
import StarIcon from 'icons/pointed-star'
import { IPageSectionTestimonials } from 'interfaces/page-section-testimonials'
import * as React from 'react'

interface IProps {
    section: IPageSectionTestimonials
}

const headerRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => <h2 className="section--header">{children}</h2>,
}

const reviewRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => <p className="">{children}</p>,
}

const rangeInputMax = 100

export const TestimonialsColouredCarouselSection = ({ section }: IProps): React.ReactElement => {
    const scrollableContainerRef = React.useRef<HTMLDivElement>()

    const [rangeSliderPosition, setRangeSliderPosition] = React.useState(0)
    const [doesSectionHaveScrollbar, setDoesSectionHaveScrollbar] = React.useState(false)

    const isMobileScreen = useMobileScreenDetection()

    React.useEffect(() => {
        if (isMobileScreen) {
            return
        }

        setDoesSectionHaveScrollbar(
            scrollableContainerRef.current?.scrollWidth > scrollableContainerRef.current?.clientWidth,
        )

        let timer: NodeJS.Timeout

        const windowResizeHandler = () => {
            clearTimeout(timer)

            timer = setTimeout(() => {
                setDoesSectionHaveScrollbar(
                    scrollableContainerRef.current?.scrollWidth > scrollableContainerRef.current?.clientWidth,
                )
            }, 1000)
        }

        window.addEventListener('resize', windowResizeHandler)

        return () => {
            window.removeEventListener('resize', windowResizeHandler)
        }
    }, [isMobileScreen])

    const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
        if (isMobileScreen) {
            return
        }

        const scrollLeft = e.currentTarget.scrollLeft
        const scrollWidth = e.currentTarget.scrollWidth
        const clientWidth = e.currentTarget.clientWidth

        const newRangeSliderPosition = (scrollLeft * rangeInputMax) / (scrollWidth - clientWidth)
        setRangeSliderPosition(newRangeSliderPosition)
    }

    const handleRangeInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const draggedRange = Number(e.target.value)

        if (!scrollableContainerRef.current) {
            return
        }

        const clientWidth = scrollableContainerRef.current?.clientWidth
        const scrollWidth = scrollableContainerRef.current?.scrollWidth

        // scroll left is range multiplied by total horizontal scroll
        const scrollLeft = (draggedRange * (scrollWidth - clientWidth)) / rangeInputMax
        scrollableContainerRef.current.scrollLeft = scrollLeft
    }

    if (!section) {
        return null
    }

    const { header, featuredReviewsCollection, renderType } = section
    const featuredReviews = featuredReviewsCollection.items

    return (
        <section className="section--v2">
            {header?.json && (
                <ContentfulRichText
                    content={header.json}
                    renderNode={headerRenderNode}
                    className={`${renderType === 'Carousel - Coloured, Left Aligned' ? 'text-left' : 'text-center'}`}
                    ignoreMarkdownStyles
                />
            )}

            <div className="flex justify-center">
                <div
                    ref={scrollableContainerRef}
                    className="mt-10 flex space-x-9 lg:space-x-10 center pb-10 snap-x lg:snap-none lg:snap-center scroll-px-5 sm:scroll-px-6 lg:scroll-px-0 bleed-scroll--make_element_flex overflow-auto scrollbar-hidden"
                    onScroll={handleScroll}
                >
                    {featuredReviews.map((featuredReview) => {
                        const { authorName, content, associatedColors, sys } = featuredReview
                        const [backgroundColor, textColor, borderColor, shadowColor] = associatedColors.split('|')
                        return (
                            <div
                                key={sys.id}
                                className="px-6 py-9 rounded-2lg rounded-br-none border flex flex-col shadow-[10px_13px_0_var(--shadowColor)] w-56 lg:w-80 flex-shrink-0 snap-start lg:snap-align-none"
                                style={{
                                    ['--shadowColor' as string]: shadowColor,
                                    backgroundColor,
                                    color: textColor,
                                    borderColor,
                                }}
                            >
                                <div className="flex space-x-1">
                                    {[1, 2, 3, 4, 5].map((i) => (
                                        <StarIcon key={i} width="12" height="12" />
                                    ))}
                                </div>

                                <figure className="mt-2.5 flex flex-col justify-between flex-grow">
                                    <blockquote className="">
                                        <ContentfulRichText
                                            className="text-sm lg:text-xbase leading-140%a font-medium"
                                            content={content.json}
                                            renderNode={reviewRenderNode}
                                            ignoreMarkdownStyles
                                        />
                                    </blockquote>
                                    <figcaption className="text-xsm font-bold tracking-wider mt-9">
                                        {authorName}
                                    </figcaption>
                                </figure>
                            </div>
                        )
                    })}
                </div>
            </div>

            {doesSectionHaveScrollbar && !isMobileScreen && (
                <RangeSlider
                    label=""
                    aria-hidden
                    className="mt-10 hidden lg:block w-full"
                    max={rangeInputMax}
                    onChange={handleRangeInputChange}
                    value={rangeSliderPosition}
                    step="0.1"
                />
            )}
        </section>
    )
}
