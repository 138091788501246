/* eslint-disable react/display-name */
import * as React from 'react'
import { IGenericPageSection } from 'interfaces/generic-page-section'
import ContentfulRichText from 'components/rich-text'
import { BLOCKS } from '@contentful/rich-text-types'
import Link from 'next/link'
import { VideoSlide } from './video-slide'
import { IUiResource } from 'interfaces/ui-resource'
import useIntersectionObserver from 'hooks/useIntersectionObserver'
import twConfig from 'tailwind-light-config'
import { getHTMLClassTypeNameFromSection } from '@/lib/util/css'
const { screens } = twConfig

interface IProps {
    section: IGenericPageSection
    stepLabelUIResource: IUiResource
    videoProgressBarAriaLabel: IUiResource
    playMediaLabel: IUiResource
}

const intersectionOptions: IntersectionObserverInit = {
    threshold: 0.7,
}

const lgBreakPoint = screens.lg

const SlideTitle = ({ children }: { children: React.ReactNode }) => (
    <p className="lg:max-w-[50%] mt-2.5 lg:mt-3.5 section--header leading-120% lg:leading-110% lg:text-white">
        {children}
    </p>
)

const subHeaderRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => (
        <span className="text-xsm lg:text-sm font-bold leading-120% tracking-wider">{children}</span>
    ),
}

export const MediaSlideShowSection = ({
    section,
    stepLabelUIResource,
    videoProgressBarAriaLabel,
    playMediaLabel,
}: IProps): React.ReactElement => {
    const [activeSlide, setActiveSlide] = React.useState(0)
    const [isBrowsingInSmallScreens, setIsBrowsingInSmallScreens] = React.useState(true)

    const [videoContainerEl, setVideoContainerEl] = React.useState<HTMLDivElement>()

    const videoContainerElRef = React.useCallback((node) => {
        if (node !== null) {
            setVideoContainerEl(node)
        }
    }, [])

    const { entry } = useIntersectionObserver({
        element: videoContainerEl,
        options: intersectionOptions,
    })

    React.useEffect(() => {
        const mql = window.matchMedia(`(min-width: ${lgBreakPoint})`)

        if (mql.matches) {
            setIsBrowsingInSmallScreens(false)
        }

        const handleMediaChangeListener = (e: MediaQueryListEvent) => {
            setIsBrowsingInSmallScreens(!e.matches)
        }

        if (typeof mql.addEventListener === 'function') {
            mql.addEventListener('change', handleMediaChangeListener)
        } else {
            // backwards compatability
            mql.addListener(handleMediaChangeListener)
        }

        return () => {
            if (typeof mql.removeEventListener === 'function') {
                mql.removeEventListener('change', handleMediaChangeListener)
            } else {
                // backwards compatability
                mql.removeListener(handleMediaChangeListener)
            }
        }
    }, [])

    if (!section) {
        return null
    }

    const {
        richSubHeader,
        mediaCollection,
        mobileMediaCollection,
        primaryCtaLink,
        videoPosterImage,
        videoPosterImageMobile,
    } = section

    const mobileSlides = mobileMediaCollection?.items ?? []
    const desktopSlides = mediaCollection?.items ?? []

    const totalMobileSlides = mobileSlides.length
    const totalDesktopSlides = desktopSlides.length

    return (
        <section
            className={`section ${getHTMLClassTypeNameFromSection(
                section,
            )} py-0 px-5 sm:px-6 lg:px-15 grid relative bg-white`}
        >
            {(totalMobileSlides > 0 || totalDesktopSlides > 0) && (
                <div
                    ref={videoContainerElRef}
                    className={`lg:col-start-1 lg:col-end-2 lg:row-start-1 lg:row-end-2 relative`}
                    style={{
                        ['--totalMobileVideos' as string]: totalMobileSlides,
                        ['--totalDesktopVideos' as string]: totalDesktopSlides,
                    }}
                >
                    <div className="-mx-5 sm:-mx-6 lg:-mx-15">
                        {/* mobile media */}
                        {totalMobileSlides > 0 && isBrowsingInSmallScreens && (
                            <div className="grid lg:hidden grid-flow-col auto-cols-[100%]">
                                {mobileSlides.map((mobileMedia, index) => {
                                    if (!mobileMedia) {
                                        return null
                                    }

                                    return (
                                        <div
                                            key={mobileMedia.sys?.id}
                                            className="col-start-1 col-end-1 row-start-1 row-end-2"
                                        >
                                            <VideoSlide
                                                video={mobileMedia}
                                                slideIndex={index}
                                                setActiveSlide={setActiveSlide}
                                                activeSlide={activeSlide}
                                                poster={
                                                    index === 0
                                                        ? videoPosterImageMobile?.url + '?fm=jpg&w=420'
                                                        : undefined
                                                }
                                                totalSlides={totalMobileSlides}
                                                isSectionVisible={entry?.isIntersecting}
                                                videoProgressBarAriaLabel={videoProgressBarAriaLabel}
                                                playMediaLabel={playMediaLabel}
                                            />
                                        </div>
                                    )
                                })}
                            </div>
                        )}

                        {/* desktop media */}
                        {totalDesktopSlides > 0 && !isBrowsingInSmallScreens && (
                            <div className="hidden lg:grid grid-flow-col auto-cols-[100%]">
                                {mediaCollection?.items?.map((desktopMedia, index) => {
                                    if (!desktopMedia) {
                                        return null
                                    }

                                    return (
                                        <div
                                            key={desktopMedia.sys?.id}
                                            className="col-start-1 col-end-1 row-start-1 row-end-2"
                                        >
                                            <VideoSlide
                                                video={desktopMedia}
                                                slideIndex={index}
                                                setActiveSlide={setActiveSlide}
                                                activeSlide={activeSlide}
                                                poster={
                                                    index === 0 ? videoPosterImage?.url + '?fm=jpg&w=1024' : undefined
                                                }
                                                totalSlides={totalDesktopSlides}
                                                isSectionVisible={entry?.isIntersecting}
                                                videoProgressBarAriaLabel={videoProgressBarAriaLabel}
                                                playMediaLabel={playMediaLabel}
                                            />
                                        </div>
                                    )
                                })}
                            </div>
                        )}
                    </div>
                </div>
            )}

            {/* content */}
            <div className="bg-white lg:bg-transparent pb-10 lg:py-0 lg:col-start-1 lg:col-end-2 lg:row-start-1 lg:row-end-2 z-10 flex flex-col justify-center">
                {/* active slide index */}
                <div className="lg:text-xl lg:text-white font-bold tracking-wider mt-4 lg:mt-0">
                    {stepLabelUIResource?.value} {(activeSlide + 1).toString().padStart(2, '0')}
                </div>

                {/* each video uses it's own description
                define ui here to place this in above defined
                grid layout */}
                {totalMobileSlides > 0 && (
                    <div className="lg:hidden">
                        {mobileSlides.map(
                            (mobileMedia, index) =>
                                mobileMedia &&
                                index === activeSlide && (
                                    <SlideTitle key={mobileMedia.sys?.id}>{mobileMedia.description}</SlideTitle>
                                ),
                        )}
                    </div>
                )}

                {totalDesktopSlides > 0 && (
                    <div className="hidden lg:block">
                        {desktopSlides.map(
                            (desktopMedia, index) =>
                                desktopMedia &&
                                activeSlide === index && (
                                    <SlideTitle key={desktopMedia.sys?.id}>{desktopMedia.description}</SlideTitle>
                                ),
                        )}
                    </div>
                )}

                {richSubHeader?.json && (
                    <div className="absolute top-7 lg:top-15">
                        <ContentfulRichText
                            className="tag bg-white px-2.5 py-1 border-none"
                            content={richSubHeader.json}
                            renderNode={subHeaderRenderNode}
                        />
                    </div>
                )}

                {primaryCtaLink?.href && (
                    <Link
                        href={primaryCtaLink.href}
                        className="button bg-white lg:border-none inline-block sm:self-start px-5 py-2.5 lg:px-12 mt-10 text-xbase"
                    >
                        {primaryCtaLink.name}
                    </Link>
                )}
            </div>
        </section>
    )
}
