import * as React from 'react'
import { BLOCKS } from '@contentful/rich-text-types'
import ContentfulRichText from 'components/rich-text'
import { IGenericPageSection } from 'interfaces/generic-page-section'
import twConfig from 'tailwind-light-config'
const { screens } = twConfig

interface IProps {
    section: IGenericPageSection
}

const lgBreakPoint = screens.lg

const headerRenderNode = {
    // eslint-disable-next-line react/display-name
    [BLOCKS.PARAGRAPH]: (_node, children) => (
        <h2 className="section--header text-1xl lg:text-5xl font-medium leading-120%">{children}</h2>
    ),
}

const subheaderRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children): React.ReactElement => (
        <span className="font-bold text-base tracking-wider text-center">{children}</span>
    ),
}

const paragraphRenderNode = {
    // eslint-disable-next-line react/display-name
    [BLOCKS.PARAGRAPH]: (_node, children) => <p className="text-xbase lg:text-base leading-140%">{children}</p>,
}
export const SingleIconWithCopySideBySideSection = ({ section }: IProps): React.ReactElement => {
    if (!section) {
        return null
    }

    const { header, richSubHeader, mobileMediaCollection, mediaCollection, paragraphCopy, internalName } = section
    const isGiftsAndPrizesSection = internalName === 'Gifts & Prizes'

    const mobileImage = mobileMediaCollection?.items?.[0]
    const desktopImage = mediaCollection?.items?.[0]

    return (
        <section
            className={`relative lg:pb-24 lg:-mb-12 ${
                isGiftsAndPrizesSection ? 'section--v2--sm' : 'section--v2 lg:pt-20'
            }`}
        >
            <div className="relative flex flex-col justify-start items-center z-10">
                {richSubHeader?.json && (
                    <div className="text-center max-w-xl">
                        <ContentfulRichText content={richSubHeader?.json} renderNode={subheaderRenderNode} />
                    </div>
                )}
                {header?.json && (
                    <div className="text-center sm:max-w-2xl -mt-10">
                        <ContentfulRichText content={header?.json} renderNode={headerRenderNode} />
                    </div>
                )}
                <div
                    className={`grid items-center mt-4 max-w-xl  ${
                        paragraphCopy ? 'sm:grid-cols-2' : 'sm:grid-cols-1'
                    }`}
                >
                    {(mobileImage || desktopImage) && (
                        <picture>
                            {desktopImage && (
                                <>
                                    {desktopImage.url?.endsWith('.svg') && (
                                        // desktop svg
                                        <source
                                            type="image/svg+xml"
                                            media={`(min-width: ${lgBreakPoint})`}
                                            srcSet={desktopImage.url}
                                        />
                                    )}
                                    {/* desktop webp */}
                                    <source
                                        type="image/webp"
                                        media={`(min-width: ${lgBreakPoint})`}
                                        srcSet={`${desktopImage.url}?fm=webp&w=300`}
                                    />
                                    {/* dekstop jpeg */}
                                    <source
                                        type="image/jpeg"
                                        media={`(min-width: ${lgBreakPoint})`}
                                        srcSet={`${desktopImage.url}?fm=jpg&w=300`}
                                    />
                                </>
                            )}
                            {mobileImage && (
                                <>
                                    {mobileImage.url?.endsWith('.svg') && (
                                        // desktop svg
                                        <source type="image/svg+xml" srcSet={mobileImage.url} />
                                    )}
                                    {/* desktop webp */}
                                    <source type="image/webp" srcSet={`${mobileImage.url}?fm=webp&w=180`} />
                                    {/* dekstop jpeg */}
                                    <source type="image/jpeg" srcSet={`${mobileImage}.url}?fm=jpg&w=180`} />
                                </>
                            )}

                            <img
                                src={(mobileImage?.url || desktopImage?.url) + '?fm=jpg&w=300'}
                                alt={mobileImage?.title || desktopImage?.title}
                                height={mobileImage?.height || desktopImage?.height}
                                width={mobileImage?.width || desktopImage?.width}
                                className="h-24 lg:h-32 w-auto hidden sm:block"
                                loading="lazy"
                            />
                        </picture>
                    )}

                    {/* paragraph */}
                    {paragraphCopy?.json && (
                        <div className="text-center sm:text-left max-w-xl -mt-2">
                            <ContentfulRichText content={paragraphCopy?.json} renderNode={paragraphRenderNode} />
                        </div>
                    )}
                </div>
            </div>
        </section>
    )
}
