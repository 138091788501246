/* eslint-disable react/display-name */
import * as React from 'react'
import ContentfulRichText from 'components/rich-text'
import { IGenericPageSection } from 'interfaces/generic-page-section'
import Link from 'next/link'
import { BLOCKS } from '@contentful/rich-text-types'
import CabinetTokenMask from 'components/cabinet-token/cabinet-token'
import styles from './style.module.css'
import twConfig from 'tailwind-light-config'
const { screens } = twConfig

interface IProps {
    section: IGenericPageSection
}

const lgBreakPoint = screens.lg

const headerRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => (
        <p className="text-sm font-bold uppercase leading-120% tracking-wider">{children}</p>
    ),
}

const subHeaderRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => <p className="inline lg:block section--header hero--header">{children}</p>,
}

const paragraphRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => <p className="section--paragraph lg:text-xl">{children}</p>,
}

export const TwoColumnsWithTokenImageAndIllustration = ({ section }: IProps): React.ReactElement => {
    if (!section) {
        return null
    }

    const {
        header,
        richSubHeader,
        paragraphCopy,
        primaryCtaLink,
        mediaCollection,
        mobileMediaCollection,
        align: alignImage,
    } = section

    // first image in collection is primary token image
    const mobilePrimaryTokenImage = mobileMediaCollection?.items?.[0]
    const desktopPrimaryTokenImage = mediaCollection?.items?.[0]

    // second image is illustration
    const mobileIllustration = mobileMediaCollection?.items?.[1]
    const desktopIllustration = mediaCollection?.items?.[1]

    // thirs image in collection is secondary token image
    const mobileSecondaryTokenImage = mobileMediaCollection?.items?.[2]
    const desktopSecondaryTokenImage = mediaCollection?.items?.[2]

    const isImageLeftAligned = alignImage === 'left'

    return (
        <section
            className={`section ${styles['section-grid']} ${
                isImageLeftAligned ? styles['section-grid--image-on-left'] : styles['section-grid--image-on-right']
            }`}
        >
            {/* content */}
            <div
                className={`order-2 lg:pr-10 ${
                    mobileIllustration || desktopIllustration ? '-mt-3.5 lg:mt-0' : 'mt-5 lg:mt-0'
                } ${isImageLeftAligned ? 'lg:order-2 lg:pl-8' : 'lg:order-1'}`}
            >
                <div>
                    {header?.json && (
                        <div className="mt-8 sm:mt-0">
                            <ContentfulRichText content={header.json} renderNode={headerRenderNode} />
                        </div>
                    )}

                    {richSubHeader?.json && (
                        <div
                            className={`mt-3.75 ${
                                mobileSecondaryTokenImage || desktopSecondaryTokenImage ? 'lg:pr-15' : ''
                            }`}
                        >
                            <ContentfulRichText
                                className="header"
                                content={richSubHeader.json}
                                renderNode={subHeaderRenderNode}
                            />
                        </div>
                    )}

                    {paragraphCopy?.json && (
                        <div className="mt-5 lg:mt-7.5 lg:pr-10">
                            <ContentfulRichText
                                className="paragraph"
                                content={paragraphCopy.json}
                                renderNode={paragraphRenderNode}
                            />
                        </div>
                    )}
                </div>

                {primaryCtaLink?.href && (
                    <div className="mt-10 lg:mt-7.5">
                        <Link
                            href={primaryCtaLink.href}
                            className="button button-primary section--primary-cta lg:px-11 block lg:inline-block"
                        >
                            {primaryCtaLink.name}
                        </Link>
                    </div>
                )}
            </div>

            <div
                className={`relative lg:pr-7.5 2xl:pr-15 order-1 flex flex-col lg:flex-row lg:items-end ${
                    isImageLeftAligned ? 'lg:order-1' : 'lg:order-2'
                }`}
            >
                <div className={`relative ${isImageLeftAligned ? '' : 'lg:order-2'}`}>
                    {/* primary token image */}
                    <CabinetTokenMask>
                        {(mobilePrimaryTokenImage || desktopPrimaryTokenImage) && (
                            <picture>
                                {/* desktop image */}
                                {desktopPrimaryTokenImage && (
                                    <>
                                        {/* desktop - webp */}
                                        <source
                                            type="image/webp"
                                            media={`(min-width: ${lgBreakPoint})`}
                                            srcSet={`
                                            ${desktopPrimaryTokenImage.url}?fm=webp&w=1024 1024w,
                                            ${desktopPrimaryTokenImage.url}?fm=webp&w=1280 1280w,
                                            ${desktopPrimaryTokenImage.url}?fm=webp&w=1600 1600w
                                        `}
                                        />

                                        {/* desktop - jpeg */}
                                        <source
                                            type="image/jpeg"
                                            media={`(min-width: ${lgBreakPoint})`}
                                            srcSet={`
                                            ${desktopPrimaryTokenImage.url}?fm=jpg&w=1024 1024w,
                                            ${desktopPrimaryTokenImage.url}?fm=jpg&w=1280 1280w,
                                            ${desktopPrimaryTokenImage.url}?fm=jpg&w=1600 1600w
                                        `}
                                        />
                                    </>
                                )}

                                {/* mobile image */}
                                {mobilePrimaryTokenImage && (
                                    <>
                                        {/* mobile - webp */}
                                        <source
                                            type="image/webp"
                                            srcSet={`
                                            ${mobilePrimaryTokenImage.url}?fm=webp&w=360 360w,
                                            ${mobilePrimaryTokenImage.url}?fm=webp&w=420 420w,
                                            ${mobilePrimaryTokenImage.url}?fm=webp&w=640 640w,
                                            ${mobilePrimaryTokenImage.url}?fm=webp&w=1024 1024w,
                                            ${mobilePrimaryTokenImage.url}?fm=webp&w=1280 1280w
                                        `}
                                        />

                                        {/* mobile - jpeg */}
                                        <source
                                            type="image/jpeg"
                                            srcSet={`
                                            ${mobilePrimaryTokenImage.url}?fm=jpg&w=360 360w,
                                            ${mobilePrimaryTokenImage.url}?fm=jpg&w=420 420w,
                                            ${mobilePrimaryTokenImage.url}?fm=jpg&w=640 640w,
                                            ${mobilePrimaryTokenImage.url}?fm=jpg&w=1024 1024w,
                                            ${mobilePrimaryTokenImage.url}?fm=jpg&w=1280 1280w
                                        `}
                                        />
                                    </>
                                )}

                                <img
                                    src={
                                        (mobilePrimaryTokenImage.url || desktopPrimaryTokenImage.url) + '?fm=jpg&w=1024'
                                    }
                                    alt={
                                        mobilePrimaryTokenImage.description ||
                                        mobilePrimaryTokenImage.title ||
                                        desktopPrimaryTokenImage.description ||
                                        desktopPrimaryTokenImage.title
                                    }
                                    height={mobilePrimaryTokenImage.height || desktopPrimaryTokenImage.height}
                                    width={mobilePrimaryTokenImage.width || desktopPrimaryTokenImage.width}
                                    loading="lazy"
                                />
                            </picture>
                        )}
                    </CabinetTokenMask>

                    {/* small image */}
                    {(mobileSecondaryTokenImage || desktopSecondaryTokenImage) && (
                        <div
                            className={`hidden lg:block absolute top-16 ${
                                isImageLeftAligned ? 'right-0 translate-x-2/3' : 'left-0 -translate-x-2/3'
                            }`}
                        >
                            <CabinetTokenMask wrapperClassName="!h-24 !w-24 xl:!h-36 xl:!w-36 overflow-hidden">
                                <picture>
                                    {/* desktop image */}
                                    {desktopSecondaryTokenImage && (
                                        <>
                                            {/* desktop - webp */}
                                            <source
                                                type="image/webp"
                                                media={`(min-width: ${lgBreakPoint})`}
                                                srcSet={desktopSecondaryTokenImage.url + '?fm=webp&w=420 420w'}
                                            />

                                            {/* desktop - jpeg */}
                                            <source
                                                type="image/jpeg"
                                                media={`(min-width: ${lgBreakPoint})`}
                                                srcSet={desktopSecondaryTokenImage.url + '?fm=jpg&w=420 420w'}
                                            />
                                        </>
                                    )}

                                    {/* mobile image */}
                                    {mobileSecondaryTokenImage && (
                                        <>
                                            {/* mobile - webp */}
                                            <source
                                                type="image/webp"
                                                srcSet={mobileSecondaryTokenImage.url + '?fm=webp&w=360 360w'}
                                            />

                                            {/* mobile - jpeg */}
                                            <source
                                                type="image/jpeg"
                                                srcSet={mobileSecondaryTokenImage.url + '?fm=jpg&w=360 360w'}
                                            />
                                        </>
                                    )}

                                    <img
                                        src={
                                            (mobileSecondaryTokenImage.url || desktopSecondaryTokenImage.url) +
                                            '?fm=jpg&w=1024'
                                        }
                                        alt={
                                            mobileSecondaryTokenImage.description ||
                                            mobileSecondaryTokenImage.title ||
                                            desktopSecondaryTokenImage.description ||
                                            desktopSecondaryTokenImage.title
                                        }
                                        height={mobileSecondaryTokenImage.height || desktopSecondaryTokenImage.height}
                                        width={mobileSecondaryTokenImage.width || desktopSecondaryTokenImage.width}
                                        loading="lazy"
                                    />
                                </picture>
                            </CabinetTokenMask>
                        </div>
                    )}
                </div>

                {/* illustration image */}
                {(mobileIllustration || desktopIllustration) && (
                    <picture
                        className={`xl:shrink-0 min-w-32 lg:absolute ${styles['illustration']} ${
                            isImageLeftAligned
                                ? styles['illustration--left']
                                : `lg:order-1 ${styles['illustration--right']}`
                        }`}
                    >
                        {/* desktop image */}
                        {desktopIllustration && (
                            <>
                                {/* desktop - webp */}
                                <source
                                    type="image/webp"
                                    media={`(min-width: ${lgBreakPoint})`}
                                    srcSet={desktopIllustration.url + '?fm=webp&w=420w 420'}
                                />

                                {/* desktop - jpeg */}
                                <source
                                    type="image/jpeg"
                                    media={`(min-width: ${lgBreakPoint})`}
                                    srcSet={desktopIllustration.url + '?fm=jpg&w=420w 420'}
                                />
                            </>
                        )}

                        {/* mobile image */}
                        {mobileIllustration && (
                            <>
                                {/* mobile - webp */}
                                <source type="image/webp" srcSet={mobileIllustration.url + '?fm=webp&w=360 360w'} />

                                {/* mobile - jpeg */}
                                <source type="image/jpeg" srcSet={mobileIllustration.url + '?fm=jpg&w=360 360w'} />
                            </>
                        )}

                        <img
                            src={(mobileIllustration.url || desktopIllustration.url) + '?fm=jpg&w=420'}
                            alt={
                                mobileIllustration.description ||
                                mobileIllustration.title ||
                                desktopIllustration.description ||
                                desktopIllustration.title
                            }
                            height={mobileIllustration.height || desktopIllustration.height}
                            width={mobileIllustration.width || desktopIllustration.width}
                            className={`w-32 xl:w-44 h-auto`}
                            loading="lazy"
                        />
                    </picture>
                )}
            </div>
        </section>
    )
}
