import * as React from 'react'
import { BLOCKS } from '@contentful/rich-text-types'
import ContentfulRichText from 'components/rich-text'
import { IGenericPageSection } from 'interfaces/generic-page-section'
import twConfig from 'tailwind-light-config'
import { IUiResources } from 'interfaces/ui-resource'
import Link from 'next/link'
import { RenderNode } from '@contentful/rich-text-react-renderer'

const { screens } = twConfig

interface IProps {
    section: IGenericPageSection
    uiResources: IUiResources
}

const lgBreakPoint = screens.lg

const headerRenderNode: RenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => (
        <h2 className="section--header text-1xl lg:text-5xl font-medium leading-120%">{children}</h2>
    ),
}

const subheaderRenderNode: RenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children): React.ReactElement => (
        <span className="font-bold text-base tracking-wider text-center">{children}</span>
    ),
}

const paragraphRenderNode: RenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => <p className="text-xbase lg:text-base leading-140%">{children}</p>,
}
export const HeaderWithCenteredImageAndCTASection = ({ section }: IProps): React.ReactElement => {
    if (!section) {
        return null
    }

    const {
        header,
        richSubHeader,
        mobileMediaCollection,
        mediaCollection,
        backgroundColor,
        paragraphCopy,
        primaryCtaLink,
    } = section

    const mobileImage = mobileMediaCollection?.items?.[0]
    const desktopImage = mediaCollection?.items?.[0]

    return (
        <section
            className="section--v2--sm relative py-16 lg:pt-20 lg:pb-24 pb-0 pt-[70px]"
            style={{ backgroundColor }}
        >
            <div className="relative flex flex-col justify-start items-center z-10">
                {richSubHeader?.json && (
                    <div className="text-center max-w-xl">
                        <ContentfulRichText content={richSubHeader?.json} renderNode={subheaderRenderNode} />
                    </div>
                )}
                {header?.json && (
                    <div className="text-center -mt-10">
                        <ContentfulRichText content={header?.json} renderNode={headerRenderNode} />
                    </div>
                )}
                {/* Using the primaryCtaLink href in place of updating the content model*/}
                {(mobileImage || desktopImage) && (
                    <Link href={primaryCtaLink?.href}>
                        <picture>
                            {desktopImage && (
                                <>
                                    {desktopImage.url?.endsWith('.svg') && (
                                        // desktop svg
                                        <source
                                            type="image/svg+xml"
                                            media={`(min-width: ${lgBreakPoint})`}
                                            srcSet={desktopImage.url}
                                        />
                                    )}
                                    {/* desktop webp */}
                                    <source
                                        type="image/webp"
                                        media={`(min-width: ${lgBreakPoint})`}
                                        srcSet={`
                                            ${desktopImage.url}?fm=webp&w=300 300w,
                                            ${desktopImage.url}?fm=webp&w=600 600w,
                                            ${desktopImage.url}?fm=webp&w=900 900w,
                                            ${desktopImage.url}?fm=webp&w=1200 1200w
                                        `}
                                        sizes="(min-width: 1024px) 300px, 100vw"
                                    />
                                    {/* desktop jpeg */}
                                    <source
                                        type="image/jpeg"
                                        media={`(min-width: ${lgBreakPoint})`}
                                        srcSet={`
                                            ${desktopImage.url}?fm=jpg&w=300 300w,
                                            ${desktopImage.url}?fm=jpg&w=600 600w,
                                            ${desktopImage.url}?fm=jpg&w=900 900w,
                                            ${desktopImage.url}?fm=jpg&w=1200 1200w
                                        `}
                                        sizes="(min-width: 1024px) 300px, 100vw"
                                    />
                                </>
                            )}
                            {mobileImage && (
                                <>
                                    {mobileImage.url?.endsWith('.svg') && (
                                        // desktop svg
                                        <source type="image/svg+xml" srcSet={mobileImage.url} />
                                    )}
                                    {/* desktop webp */}
                                    <source type="image/webp" srcSet={`${mobileImage.url}?fm=webp&w=180`} />
                                    {/* desktop jpeg */}
                                    <source type="image/jpeg" srcSet={`${mobileImage}.url}?fm=jpg&w=180`} />
                                </>
                            )}

                            <img
                                src={(mobileImage?.url || desktopImage?.url) + '?fm=jpg&w=300'}
                                alt={mobileImage?.title || desktopImage?.title}
                                height={mobileImage?.height || desktopImage?.height}
                                width={mobileImage?.width || desktopImage?.width}
                                className="object-contain mb-8 sm:mb-0"
                                loading="lazy"
                            />
                        </picture>
                    </Link>
                )}

                {/* paragraph */}
                {paragraphCopy?.json && (
                    <div className="text-left max-w-xl">
                        <ContentfulRichText content={paragraphCopy?.json} renderNode={paragraphRenderNode} />
                    </div>
                )}

                {primaryCtaLink?.href && (
                    <Link
                        style={{
                            background: primaryCtaLink.associatedColors,
                        }}
                        href={primaryCtaLink.href}
                        className="button text-white transition-colors duration-200 lg:inline-block min-w-[350px] sm:text-xl py-4 border-black border-2 text-lg font-bold mb-2 sm:mt-8"
                    >
                        {primaryCtaLink.name}
                    </Link>
                )}
            </div>
        </section>
    )
}
