import * as React from 'react'
import { IMedicineCategory } from 'interfaces/medicine-category'
import Link from 'next/link'
import twConfig from 'tailwind-light-config'
const { screens } = twConfig
import Button from 'components/buttons/button'
import { IImage } from 'interfaces/media'

interface IProps {
    productCategory: IMedicineCategory
    cardImage: IImage
    cardTitle: string
    cardDescription: string
    cardCtaLabel: string
    cardCtaHrefLanding: string
}

const lgBreakPoint = screens.lg

export const InfoProductCategory = ({
    productCategory,
    cardImage,
    cardTitle,
    cardCtaLabel,
    cardDescription,
    cardCtaHrefLanding,
}: IProps): React.ReactElement => {
    const { name, sys, abstractCategoryImage } = productCategory

    return (
        <div
            key={sys.id}
            id={`${name}-panel`}
            role="tabpabel"
            aria-labelledby={`${name}-tab`}
            // '--cardWidthMobile' and '--cardWidthDesktop' defined in parent component
            className="grid grid-flow-col auto-cols-[var(--cardWidthMobile)] lg:auto-cols-[var(--cardWidthDesktop)] gap-5"
        >
            {/* abstract image */}
            {abstractCategoryImage?.url && (
                <picture>
                    <source type="image/webp" srcSet={abstractCategoryImage.url + '?fm=webp&w=800'} />
                    <source type="image/jpeg" srcSet={abstractCategoryImage.url + '?fm=jpg&w=800'} />

                    <img
                        src={abstractCategoryImage.url + '?fm=jpg&w=800'}
                        alt={abstractCategoryImage.description || abstractCategoryImage.title}
                        height={abstractCategoryImage.height}
                        width={abstractCategoryImage.width}
                        className="h-full w-full object-cover rounded-2lg"
                        loading="lazy"
                    />
                </picture>
            )}

            {productCategory.internalName === 'Rx' && (
                <Link href={cardCtaHrefLanding} key={sys.id} className="flex flex-col snap-center">
                    {cardImage.url && (
                        <picture>
                            <source
                                type="image/webp"
                                media={`(min-width: ${lgBreakPoint})`}
                                srcSet={cardImage.url + '?fm=webp&w=1200'}
                            />
                            <source
                                type="image/jpeg"
                                media={`(min-width: ${lgBreakPoint})`}
                                srcSet={cardImage.url + '?fm=jpg&w=1200'}
                            />

                            <source type="image/webp" srcSet={cardImage.url + '?fm=webp&w=800'} />
                            <source type="image/jpeg" srcSet={cardImage.url + '?fm=jpg&w=800'} />

                            <img
                                src={cardImage.url + '?fm=jpg&w=1200'}
                                alt={cardImage.description || cardImage.title}
                                height={cardImage.height}
                                width={cardImage.width}
                                className="h-full w-full object-cover rounded-2lg"
                                loading="lazy"
                            />
                        </picture>
                    )}

                    <div className="flex flex-col justify-between self-end flex-grow w-full">
                        <div className="mt-5">
                            <div>
                                <div className="flex justify-between space-x-2 lg:text-lg font-bold leading-120%">
                                    <span>{cardTitle}</span>
                                </div>
                            </div>
                            <p className="mt-2 text-sm lg:text-base leading-140$ font-medium">{cardDescription}</p>
                        </div>
                        <Button className="px-10 mt-5 lg:ml-auto self-start min-w-[17ch]">{cardCtaLabel}</Button>
                    </div>
                </Link>
            )}
        </div>
    )
}
