import * as React from 'react'

const ArrowRightLongBodyIcon = ({
    className = '',
    stroke = '#3c3c3c',
    fill = '#3c3c3c',
    height = '10',
    width = '6',
} = {}): React.ReactElement => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 14 12"
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect x="0.5" y="6.5" width="1" height="10" transform="rotate(-90 0.5 6.5)" fill={fill} stroke={stroke} />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.00195 1.49997L6.08771 1.41421L7.41617 0.0857601L7.50193 0L8.91614 1.41421L12.0876 4.58568L13.5018 5.99989L13.4161 6.08565L12.0876 7.41411L12.0018 7.49987L12.001 7.49898L8.91419 10.5858L7.49997 12L7.41421 11.9142L6.08576 10.5858L6 10.5L7.41421 9.08579L10.501 5.99901L7.41617 2.91419L6.00195 1.49997Z"
            fill={fill}
        />
    </svg>
)

export default ArrowRightLongBodyIcon
