import * as React from 'react'
import { IMedicineCategory } from 'interfaces/medicine-category'
import PageSectionProductCategoryOTCCard from './page-section-product-category-otc-card'
import PageSectionProductCategoryRxCard from './page-section-product-category-rx-card'

interface IProps {
    productCategory: IMedicineCategory
    fullDrugFactsLabel: string
    cardCtaHrefEligibility: string
    genericForLabel: string
    rxCardCtaLabel: string
}

export const MedicineProductCategory = ({
    productCategory,
    fullDrugFactsLabel,
    cardCtaHrefEligibility,
    genericForLabel,
    rxCardCtaLabel,
}: IProps): React.ReactElement => {
    const {
        name,
        sys,
        linkedFrom: { medicineCollection },
        abstractCategoryImage,
    } = productCategory
    const medicines = medicineCollection.items

    return (
        <div
            key={sys.id}
            id={`${name}-panel`}
            role="tabpabel"
            aria-labelledby={`${name}-tab`}
            // '--cardWidthMobile' and '--cardWidthDesktop' defined in parent component
            className="grid grid-flow-col auto-cols-[var(--cardWidthMobile)] lg:auto-cols-[var(--cardWidthDesktop)] gap-5"
        >
            {/* abstract image */}
            {abstractCategoryImage?.url && (
                <picture>
                    <source type="image/webp" srcSet={abstractCategoryImage.url + '?fm=webp&w=800'} />
                    <source type="image/jpeg" srcSet={abstractCategoryImage.url + '?fm=jpg&w=800'} />

                    <img
                        src={abstractCategoryImage.url + '?fm=jpg&w=800'}
                        alt={abstractCategoryImage.description || abstractCategoryImage.title}
                        height={abstractCategoryImage.height}
                        width={abstractCategoryImage.width}
                        className="h-full w-full object-cover rounded-2lg snap-center"
                        loading="lazy"
                    />
                </picture>
            )}

            {/* medicines */}
            {medicines.map((medicine) => {
                if (medicine.productType === 'OTC') {
                    return (
                        <PageSectionProductCategoryOTCCard
                            key={medicine.sys.id}
                            medicine={medicine}
                            fullDrugFactsLabel={fullDrugFactsLabel}
                        />
                    )
                }

                return (
                    <PageSectionProductCategoryRxCard
                        cardCtaHrefEligibility={cardCtaHrefEligibility}
                        genericForLabel={genericForLabel}
                        rxCardCtaLabel={rxCardCtaLabel}
                        medicine={medicine}
                        key={medicine.sys.id}
                    />
                )
            })}
        </div>
    )
}
