import * as React from 'react'
import { BLOCKS } from '@contentful/rich-text-types'
import ContentfulRichText from 'components/rich-text'
import { IGenericPageSection } from 'interfaces/generic-page-section'
import twConfig from 'tailwind-light-config'
const { screens } = twConfig
import Link from 'next/link'

interface IProps {
    section: IGenericPageSection
}

const lgBreakPoint = screens.lg

const paragraphRenderNode = {
    // eslint-disable-next-line react/display-name
    [BLOCKS.PARAGRAPH]: (_node, children) => <p className="text-xbase lg:text-base leading-140%">{children}</p>,
}
export const SingleIconWithTextSection = ({ section }: IProps): React.ReactElement => {
    if (!section) {
        return null
    }

    const { mobileMediaCollection, mediaCollection, backgroundColor, paragraphCopy, primaryCtaLink } = section

    const mobileImage = mobileMediaCollection?.items?.[0]
    const desktopImage = mediaCollection?.items?.[0]

    const mobileBackgroundImage = mobileMediaCollection?.items?.[1]
    const desktopBackgroundImage = mediaCollection?.items?.[1]

    const theresBackgroundImage = mobileBackgroundImage || desktopBackgroundImage
    const theresBackgroundImageAndCTA = theresBackgroundImage && primaryCtaLink?.href

    return (
        <section
            className={`section relative ${
                theresBackgroundImageAndCTA ? 'pt-[70px] pb-[74px] lg:pt-[106px] lg:pb-20' : 'py-16 lg:pt-20 lg:pb-24'
            }`}
            style={{ backgroundColor }}
        >
            {/* background image */}
            {theresBackgroundImage && (
                <picture>
                    {desktopBackgroundImage && (
                        <>
                            {/* webp */}
                            <source
                                type="image/webp"
                                media={`(min-width: ${lgBreakPoint})`}
                                srcSet={`
                                ${desktopBackgroundImage.url}?fm=webp&w=1024 1024w,
                                ${desktopBackgroundImage.url}?fm=webp&w=1280 1280w,
                                ${desktopBackgroundImage.url}?fm=webp&w=1600 1600w
                            `}
                            />

                            {/* jpeg */}
                            <source
                                type="image/jpeg"
                                media={`(min-width: ${lgBreakPoint})`}
                                srcSet={`
                                ${desktopBackgroundImage.url}?fm=jpg&w=1024 1024w,
                                ${desktopBackgroundImage.url}?fm=jpg&w=1280 1280w,
                                ${desktopBackgroundImage.url}?fm=jpg&w=1600 1600w
                            `}
                            />
                        </>
                    )}
                    {mobileBackgroundImage && (
                        <>
                            {/* webp */}
                            <source
                                type="image/webp"
                                srcSet={`
                                ${mobileBackgroundImage.url}?fm=webp&w=360 360w,
                                ${mobileBackgroundImage.url}?fm=webp&w=480 480w,
                                ${mobileBackgroundImage.url}?fm=webp&w=768 768w
                            `}
                            />

                            {/* jpeg */}
                            <source
                                type="image/jpeg"
                                srcSet={`
                                ${mobileBackgroundImage.url}?fm=jpg&w=360 360w,
                                ${mobileBackgroundImage.url}?fm=jpg&w=480 480w,
                                ${mobileBackgroundImage.url}?fm=jpg&w=768 768w
                            `}
                            />
                        </>
                    )}

                    <img
                        src={(mobileBackgroundImage?.url || desktopBackgroundImage?.url) + '?fm=jpg&w=1024'}
                        alt={
                            mobileBackgroundImage?.title ||
                            desktopBackgroundImage?.title ||
                            mobileBackgroundImage?.description ||
                            desktopBackgroundImage?.description
                        }
                        height={mobileBackgroundImage?.height || desktopBackgroundImage?.height}
                        width={mobileBackgroundImage?.width || desktopBackgroundImage?.width}
                        className="absolute top-0 left-0 h-full w-full object-cover"
                        loading="lazy"
                    />
                </picture>
            )}

            <div className="relative flex flex-col justify-start items-center z-10">
                {(mobileImage || desktopImage) && (
                    <picture>
                        {desktopImage && (
                            <>
                                {desktopImage.url?.endsWith('.svg') && (
                                    // desktop svg
                                    <source
                                        type="image/svg+xml"
                                        media={`(min-width: ${lgBreakPoint})`}
                                        srcSet={desktopImage.url}
                                    />
                                )}
                                {/* desktop webp */}
                                <source
                                    type="image/webp"
                                    media={`(min-width: ${lgBreakPoint})`}
                                    srcSet={`${desktopImage.url}?fm=webp&w=300`}
                                />
                                {/* dekstop jpeg */}
                                <source
                                    type="image/jpeg"
                                    media={`(min-width: ${lgBreakPoint})`}
                                    srcSet={`${desktopImage.url}?fm=jpg&w=300`}
                                />
                            </>
                        )}
                        {mobileImage && (
                            <>
                                {mobileImage.url?.endsWith('.svg') && (
                                    // desktop svg
                                    <source type="image/svg+xml" srcSet={mobileImage.url} />
                                )}
                                {/* desktop webp */}
                                <source type="image/webp" srcSet={`${mobileImage.url}?fm=webp&w=180`} />
                                {/* dekstop jpeg */}
                                <source type="image/jpeg" srcSet={`${mobileImage}.url}?fm=jpg&w=180`} />
                            </>
                        )}

                        <img
                            src={(mobileImage?.url || desktopImage?.url) + '?fm=jpg&w=300'}
                            alt={mobileImage?.title || desktopImage?.title}
                            height={mobileImage?.height || desktopImage?.height}
                            width={mobileImage?.width || desktopImage?.width}
                            className="h-24 lg:h-32 w-auto"
                            loading="lazy"
                        />
                    </picture>
                )}

                {/* paragraph */}
                {paragraphCopy?.json && (
                    <div className={`text-center max-w-xl ${theresBackgroundImageAndCTA ? 'mt-7.5' : 'mt-10'}`}>
                        <ContentfulRichText
                            className={theresBackgroundImage ? 'text-white' : ''}
                            content={paragraphCopy?.json}
                            renderNode={paragraphRenderNode}
                        />
                    </div>
                )}

                {/* primary cta */}
                {primaryCtaLink?.href && (
                    <Link
                        href={primaryCtaLink.href}
                        className={`button button-secondary no-hover mt-7.5 lg:mt-10 section--primary-cta ${
                            theresBackgroundImage ? 'text-white border-white' : ''
                        }`}
                    >
                        {primaryCtaLink.name}
                    </Link>
                )}
            </div>
        </section>
    )
}
