/* eslint-disable react/display-name */
import * as React from 'react'
import ContentfulRichText from 'components/rich-text'
import { IGenericPageSection } from 'interfaces/generic-page-section'
import { BLOCKS } from '@contentful/rich-text-types'
import Link from 'next/link'
import styles from './style.module.css'
import Loader from 'components/loading/loader'
import twConfig from 'tailwind-light-config'
const { screens } = twConfig

const lgBreakPoint = Number(screens.lg.replace('px', ''))

const headerRenderOptions = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (_node, children): React.ReactElement => (
            <h2 className="leading-10 lg:leading-13 tracking-tight text-center">{children}</h2>
        ),
    },
}

const subHeaderRenderOptions = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (_node, children): React.ReactElement => (
            <p className="text-white text-3xl lg:text-6xl leading-10 lg:leading-13">{children}</p>
        ),
    },
}

const paragraphRenderOptions = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (_node, children): React.ReactElement => (
            <p className="text-white lg:text-xl mt-5">{children}</p>
        ),
        [BLOCKS.HEADING_5]: (_node, children): React.ReactElement => (
            <h5 className="text-sm lg:text-2xl text-white mt-4 first:mt-7 uppercase lg:capitalize">{children}</h5>
        ),
    },
}

const TwoColumnsWithCoverImageSection = ({
    mediaCollection,
    primaryCtaLink,
    header,
    richSubHeader,
    paragraphCopy,
    mobileMediaCollection,
}: IGenericPageSection): React.ReactElement => {
    const [isRedirecting, setIsRedirecting] = React.useState(false)

    const coverImageDesktop = mediaCollection?.items?.[0]
    const coverImageMobile = mobileMediaCollection?.items?.[0]
    const circleMark = mediaCollection?.items?.[1]

    return (
        <section className="section">
            <div className="flex flex-wrap lg:flex-nowrap relative overflow-hidden rounded-2lg">
                {/* cover image */}
                <div className="relative flex-basis-full">
                    {(coverImageDesktop || coverImageMobile) && (
                        <picture>
                            {/* only provide desktop options if desktop image is defined */}
                            {coverImageDesktop && (
                                <>
                                    {/* large screens */}
                                    {/* webp */}
                                    <source
                                        type="image/webp"
                                        media={`(min-width: ${lgBreakPoint}px)`}
                                        srcSet={`
                                            ${coverImageDesktop.url}?w=1024&fm=webp&q=75 1024w,
                                            ${coverImageDesktop.url}?w=1440&fm=webp&q=75 1440w,
                                            ${coverImageDesktop.url}?w=1600&fm=webp&q=75 1600w
                                        `}
                                        sizes="(min-width: 1024px) 50vw, 100vw"
                                    />
                                    {/* jpg */}
                                    <source
                                        type="image/jpeg"
                                        media={`(min-width: ${lgBreakPoint}px)`}
                                        srcSet={`
                                            ${coverImageDesktop.url}?w=1024&fm=jpg&q=75 1024w,
                                            ${coverImageDesktop.url}?w=1440&fm=jpg&q=75 1440w,
                                            ${coverImageDesktop.url}?w=1600&fm=jpg&q=75 1600w
                                        `}
                                        sizes="(min-width: 1024px) 50vw, 100vw"
                                    />
                                </>
                            )}
                            {/* only provide mobile options if mobile image is defined */}
                            {coverImageMobile && (
                                <>
                                    {/* small screens */}
                                    {/* webp */}
                                    <source
                                        type="image/webp"
                                        srcSet={`
                                            ${coverImageMobile.url}?w=640&fm=webp&q=75 640w,
                                            ${coverImageMobile.url}?w=1024&fm=webp&q=75 1024w
                                        `}
                                    />
                                    {/* jpg */}
                                    <source
                                        type="image/jpeg"
                                        srcSet={`
                                            ${coverImageMobile.url}?w=640&fm=jpg&q=75 640w,
                                            ${coverImageMobile.url}?w=1024&fm=jpg&q=75 1024w
                                        `}
                                    />
                                </>
                            )}

                            {/* fallback image - priority to desktop image */}
                            <img
                                width={coverImageDesktop?.width || coverImageMobile.width}
                                alt={coverImageDesktop?.title || coverImageMobile.title}
                                height={coverImageDesktop?.height || coverImageMobile.height}
                                src={`${coverImageDesktop?.url || coverImageMobile.url}?w=1024&fm=jpg&q=75`}
                                className={`w-full object-cover object-bottom ${styles['cover-image']}`}
                                loading="lazy"
                            />
                        </picture>
                    )}
                    <div className="w-full px-2.5 flex flex-col items-center absolute top-0 left-1/2 -translate-x-1/2">
                        <div className={styles['header']}>
                            <ContentfulRichText content={header?.json} renderOptions={headerRenderOptions} />
                        </div>
                        {circleMark && (
                            <img
                                className="mt-5 lg:mt-10 h-24 lg:h-40 w-auto"
                                src={`${circleMark?.url}?w=170`}
                                alt={circleMark?.title}
                                height={circleMark?.height}
                                width={circleMark?.width}
                                loading="lazy"
                            />
                        )}
                    </div>
                </div>

                {/* content */}
                <div className="flex-basis-full px-4 pt-3 pb-6 lg:px-20 lg:py-10 bg-mustard">
                    <ContentfulRichText content={richSubHeader?.json} renderOptions={subHeaderRenderOptions} />
                    <ContentfulRichText content={paragraphCopy?.json} renderOptions={paragraphRenderOptions} />
                    <div className="flex justify-center lg:justify-start">
                        <Link
                            href={primaryCtaLink?.href}
                            onClick={() => setIsRedirecting(true)}
                            className="button button-primary inline-block mt-7 min-w-250"
                        >
                            {isRedirecting ? <Loader /> : primaryCtaLink?.name}
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TwoColumnsWithCoverImageSection
