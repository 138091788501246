/* eslint-disable react/display-name */
import * as React from 'react'
import ContentfulRichText from 'components/rich-text'
import { BLOCKS } from '@contentful/rich-text-types'
import { IGenericPageSection } from 'interfaces/generic-page-section'
import Link from 'next/link'
import twConfig from 'tailwind-light-config'
import { genericSectionCabinetCVSInternalName } from 'constants/contentful'
const { screens } = twConfig

interface IProps {
    section: IGenericPageSection
}

const headerRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => (
        <div className="text-4xl lg:text-6.25xl text-black text-center lg:text-black leading-110% tracking-tight">
            {children}
        </div>
    ),
}

const subheaderRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children): React.ReactElement => (
        <p className="font-bold text-base tracking-wider text-center">{children}</p>
    ),
}

const smBreakPoint = screens.sm

export const HeaderWithLargeButtonsSection = ({ section }: IProps): React.ReactElement => {
    const {
        header,
        richSubHeader,
        primaryCtaLink,
        secondaryCtaLink,
        mediaCollection,
        mobileMediaCollection,
        internalName,
    } = section

    const mobileImage = mobileMediaCollection?.items?.[0]
    const desktopImage = mediaCollection?.items?.[0]

    return (
        <>
            <section className={`section--v2--sm grid grid-cols-1 lg:px-20 mb-20`}>
                {/* section header image */}
                <Link href={desktopImage?.url || mobileImage?.url}>
                    <picture>
                        {desktopImage?.url && (
                            <>
                                <source
                                    type="image/webp"
                                    media={`(min-width: ${smBreakPoint})`}
                                    srcSet={desktopImage.url + '?fm=webp&w=420'}
                                />
                                <source
                                    type="image/jpg"
                                    media={`(min-width: ${smBreakPoint})`}
                                    srcSet={desktopImage.url + '?fm=jpg&w=420'}
                                />
                            </>
                        )}

                        {mobileImage?.url && (
                            <>
                                <source type="image/webp" srcSet={mobileImage.url + '?fm=webp&w=420'} />
                                <source type="image/jpg" srcSet={mobileImage.url + '?fm=jpg&w=420'} />
                            </>
                        )}

                        <img
                            src={(mobileImage?.url || desktopImage?.url) + '?fm=jpg&w=420'}
                            alt={
                                mobileImage?.description ||
                                desktopImage?.description ||
                                mobileImage?.title ||
                                desktopImage?.title
                            }
                            height={mobileImage?.height || desktopImage?.height}
                            width={mobileImage?.width || desktopImage?.width}
                            loading="lazy"
                            className="sm:max-w-sm max-w-screen-xs object-cover sm:object-contain mx-auto sm:h-10"
                        />
                    </picture>
                </Link>
                {richSubHeader?.json && (
                    <div className="flex justify-center">
                        <ContentfulRichText content={richSubHeader.json} renderNode={subheaderRenderNode} />
                    </div>
                )}
                {/* content */}
                <div className="p-5 pt-[2.5rem] lg:px-15 flex flex-col items-start relative z-10">
                    {header?.json && (
                        <h2>
                            <ContentfulRichText content={header.json} renderNode={headerRenderNode} />
                        </h2>
                    )}
                </div>

                {/* Check internalName to set white text only on CVS pages */}
                <div
                    className={`flex sm:flex-row flex-col items-center justify-between sm:space-x-4 w-full px-12 ${
                        internalName === genericSectionCabinetCVSInternalName ? 'text-white' : ''
                    }`}
                >
                    {primaryCtaLink?.href && (
                        <Link
                            style={{
                                background: primaryCtaLink.associatedColors,
                            }}
                            href={primaryCtaLink.href}
                            className="button can-hover:hover:text-white transition-colors duration-200 mt-8 lg:mt-6 lg:inline-block sm:min-w-[165px] min-w-[350px] w-full sm:text-2xl py-6 border-black border-2 text-xl font-bold"
                        >
                            {primaryCtaLink.name}
                        </Link>
                    )}
                    {secondaryCtaLink?.href && (
                        <Link
                            style={{
                                background: secondaryCtaLink.associatedColors,
                            }}
                            href={secondaryCtaLink.href}
                            className="button can-hover:hover:text-white transition-colors duration-200 mt-8 lg:mt-6 block lg:inline-block sm:min-w-[165px] min-w-[350px] w-full  sm:text-2xl py-6 border-black border-2 text-xl font-bold"
                        >
                            {secondaryCtaLink.name}
                        </Link>
                    )}
                </div>
            </section>
        </>
    )
}
