import * as React from 'react'
import Link from 'next/link'
import { IMedicine } from 'interfaces/medicine'
import twConfig from 'tailwind-light-config'

interface IProps {
    medicine: IMedicine
    fullDrugFactsLabel: string
}

const { screens } = twConfig
const lgBreakPoint = screens.lg

export const PageSectionProductCategoryOTCCard = ({ medicine, fullDrugFactsLabel }: IProps): React.ReactElement => {
    const {
        sys,
        abstractImage,
        cabinetDrugName,
        linkedFrom: { productPageMedicineCollection },
    } = medicine

    const medicineProductPage = productPageMedicineCollection.items[0].linkedFrom.productPageCollection.items[0]

    const { shortDescription, slug } = medicineProductPage ?? {}

    return (
        <Link href={'/products/' + slug} key={sys.id} className="flex flex-col snap-center">
            {abstractImage?.url && (
                <picture>
                    <source
                        type="image/webp"
                        media={`(min-width: ${lgBreakPoint})`}
                        srcSet={abstractImage.url + '?fm=webp&w=1200'}
                    />
                    <source
                        type="image/jpeg"
                        media={`(min-width: ${lgBreakPoint})`}
                        srcSet={abstractImage.url + '?fm=jpg&w=1200'}
                    />

                    <source type="image/webp" srcSet={abstractImage.url + '?fm=webp&w=800'} />
                    <source type="image/jpeg" srcSet={abstractImage.url + '?fm=jpg&w=800'} />

                    <img
                        src={abstractImage.url + '?fm=jpg&w=1200'}
                        alt={abstractImage.description || abstractImage.title}
                        height={abstractImage.height}
                        width={abstractImage.width}
                        className="h-full w-full object-cover rounded-2lg"
                        loading="lazy"
                    />
                </picture>
            )}

            <div className="flex flex-col justify-between self-end flex-grow w-full">
                <div className="mt-5">
                    <div>
                        <div className="flex justify-between space-x-2 lg:text-lg font-bold leading-120%">
                            <span>{cabinetDrugName}</span>
                        </div>
                    </div>
                    <p className="mt-2 text-sm lg:text-base leading-140$ font-medium">{shortDescription}</p>
                </div>
                <div className="capitalize flex justify-between lg:space-x-4 items-end mt-5">
                    {/* mb-px needed to remove vertical scroll in the card */}
                    <div className="text-xs leading-110% font-medium hidden lg:block mb-px">
                        <button className="underline text-gray-darker mt-0.5 block">{fullDrugFactsLabel}</button>
                    </div>
                </div>
            </div>
        </Link>
    )
}
