import { BLOCKS } from '@contentful/rich-text-types'
import ContentfulRichText from 'components/rich-text'
import { IGenericPageSection } from 'interfaces/generic-page-section'
import * as React from 'react'
import ContentfulImage from 'components/image'

interface IProps {
    section: IGenericPageSection
}

const renderNode = {
    // eslint-disable-next-line react/display-name
    [BLOCKS.PARAGRAPH]: (_node, children) => (
        <p className="text-white text-3xl lg:text-[2.75rem] leading-110% font-medium tracking-tight">{children}</p>
    ),
}

export const TextLeftRightOverMedia = ({ section }: IProps): React.ReactElement => {
    if (!section) {
        return null
    }

    const { mediaCollection, mobileMediaCollection, header, richSubHeader } = section

    const desktopImage = mediaCollection?.items?.[0]
    const mobileImage = mobileMediaCollection?.items?.[0]

    return (
        <section className="section section--bigger-padding p-0 grid grid-cols-1 grid-rows-1">
            {/* image */}
            {(desktopImage || mobileImage) && (
                <ContentfulImage
                    image={desktopImage}
                    mobileImage={mobileImage}
                    className="h-full w-full max-h-[var(--screenHeightDeductingHeader)]"
                    pictureClassName="col-start-1 row-start-1"
                    desktopImageSrcSizesOverride={['1024', '1280', '1600', '2000']}
                    mobileImageSrcSizesOverride={['420', '640', '820']}
                />
            )}

            <div className="col-start-1 row-start-1 flex flex-col justify-between p-5 lg:p-15">
                {/* header */}
                {header?.json && (
                    <ContentfulRichText content={header.json} renderNode={renderNode} className="header" />
                )}

                {/* sub header */}
                {richSubHeader?.json && (
                    <ContentfulRichText
                        content={richSubHeader.json}
                        renderNode={renderNode}
                        className="sub-header ml-auto"
                    />
                )}
            </div>
        </section>
    )
}
