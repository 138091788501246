import * as React from 'react'
import { BLOCKS } from '@contentful/rich-text-types'
import ContentfulRichText from 'components/rich-text'
import { IGenericPageSection } from 'interfaces/generic-page-section'
import twConfig from 'tailwind-light-config'
const { screens } = twConfig
import styles from './style.module.css'
import useMobileScreenDetection from 'hooks/useMobileScreenDetection'
import { IImage, IMedia, IVideo } from 'interfaces/media'
import VideoCard from 'components/video-card'
import { IUiResources } from 'interfaces/ui-resource'
import { ILink } from 'interfaces/link'
import Link from 'next/link'

interface IProps {
    section: IGenericPageSection
    uiResources: IUiResources
}

const headerRenderNode = {
    // eslint-disable-next-line react/display-name
    [BLOCKS.PARAGRAPH]: (_node, children) => (
        <h2 className="section--header text-3.25xl lg:text-5xl font-medium leading-120%">{children}</h2>
    ),
}

const subheaderRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children): React.ReactElement => (
        <span className="font-bold text-base tracking-wider text-center">{children}</span>
    ),
}

const smBreakPoint = screens.sm
const cellsInGridRowMobile = 3
const cellsInGridRowDesktop = 6

export const Media = ({
    mobileMedia,
    desktopMedia,
    uiResources,
    link,
}: {
    mobileMedia: IMedia
    desktopMedia: IMedia
    uiResources: IUiResources
    link: ILink
}): React.ReactElement => {
    const isMobileScreen = useMobileScreenDetection()

    if (mobileMedia?.contentType?.includes('video') || desktopMedia?.contentType?.includes('video')) {
        const mobileVideo = mobileMedia as IVideo
        const desktopVideo = desktopMedia as IVideo

        if (isMobileScreen) {
            // set radius className here too, video does not get radius from parent in ios safari
            return (
                <VideoCard
                    video={mobileVideo}
                    playMediaLabel={uiResources['playMediaLabel'].value}
                    pauseMediaLabel={uiResources['pauseMediaLabel'].value}
                    className="rounded-2lg overflow-hidden"
                />
            )
        }

        return (
            <VideoCard
                video={desktopVideo}
                playMediaLabel={uiResources['playMediaLabel'].value}
                pauseMediaLabel={uiResources['pauseMediaLabel'].value}
                className="rounded-2lg overflow-hidden"
            />
        )
    }

    if (mobileMedia?.contentType?.includes('image') || desktopMedia?.contentType?.includes('image')) {
        const mobileImage = mobileMedia as IImage
        const desktopImage = mobileMedia as IImage

        return (
            <Link href={link.href}>
                <picture>
                    {desktopImage?.url && (
                        <>
                            <source
                                type="image/webp"
                                media={`(min-width: ${smBreakPoint})`}
                                srcSet={desktopImage.url + '?fm=webp&w=420'}
                            />
                            <source
                                type="image/jpg"
                                media={`(min-width: ${smBreakPoint})`}
                                srcSet={desktopImage.url + '?fm=jpg&w=420'}
                            />
                        </>
                    )}

                    {mobileImage?.url && (
                        <>
                            <source type="image/webp" srcSet={mobileImage.url + '?fm=webp&w=420'} />
                            <source type="image/jpg" srcSet={mobileImage.url + '?fm=jpg&w=420'} />
                        </>
                    )}

                    <img
                        src={(mobileImage?.url || desktopImage?.url) + '?fm=jpg&w=420'}
                        alt={
                            mobileImage?.description ||
                            desktopImage?.description ||
                            mobileImage?.title ||
                            desktopImage?.title
                        }
                        height={mobileImage?.height || desktopImage?.height}
                        width={mobileImage?.width || desktopImage?.width}
                        loading="lazy"
                        className="h-full w-full object-cover"
                    />
                </picture>
            </Link>
        )
    }

    return null
}

export const GenericMasonrySection = ({ section, uiResources }: IProps): React.ReactElement => {
    const isMobileScreen = useMobileScreenDetection()

    if (!section) {
        return null
    }

    const { header, mobileMediaCollection, mediaCollection, primaryCtaLink, richSubHeader, internalName } = section
    const isSustainableHighlightsSection = internalName === 'Highlights'

    const renderGrid = () => {
        const cells = []

        // using these to position grid cells
        let rowPosition = 1
        const cellsInRow = isMobileScreen ? cellsInGridRowMobile : cellsInGridRowDesktop

        for (let i = 0; i < Math.max(mobileMediaCollection.items.length, mediaCollection.items.length); i++) {
            const mobileMedia = mobileMediaCollection.items[i]
            const desktopMedia = mediaCollection.items[i]

            cells.push(
                <div
                    key={i}
                    className={`${styles['grid-cell']} rounded-2lg overflow-hidden`}
                    style={{
                        ['--rowPosition' as string]: rowPosition,
                    }}
                >
                    <Media
                        mobileMedia={mobileMedia}
                        desktopMedia={desktopMedia}
                        uiResources={uiResources}
                        link={primaryCtaLink}
                    />
                </div>,
            )

            // span cell to two rows on first and then every fourth cell
            if ((i + 1) % cellsInRow === 0) {
                rowPosition += 2
            }
        }

        return cells
    }

    return (
        <section className={`${isSustainableHighlightsSection ? 'section--v2--sm' : 'section--v2'}`}>
            {richSubHeader?.json && (
                <ContentfulRichText
                    content={richSubHeader.json}
                    renderNode={subheaderRenderNode}
                    className="text-center mb-2"
                    ignoreMarkdownStyles
                />
            )}

            {header?.json && (
                <ContentfulRichText
                    content={header.json}
                    renderNode={headerRenderNode}
                    className="text-center"
                    ignoreMarkdownStyles
                />
            )}

            {/* grid */}
            <div
                className={`grid lg:grid-cols-4 gap-2.5 lg:gap-8 mt-7.5 lg:mt-12.5 auto-rows-[235px] ${
                    isSustainableHighlightsSection ? 'grid-cols-1' : 'grid-cols-2'
                }`}
            >
                {renderGrid()}
            </div>
        </section>
    )
}
