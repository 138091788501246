import * as React from 'react'
import { IPageSectionIcons } from 'interfaces/page-section-icons'
import LoopHorizontal from 'components/loop-horizontal'
import { iconSectionSustainableTourBannerInternalName } from 'constants/contentful'
import useMobileScreenDetection from 'hooks/useMobileScreenDetection'
import { getHTMLClassTypeNameFromSection } from '@/lib/util/css'

interface IProps {
    section: IPageSectionIcons
}
export const IconsIconsOnlySection = ({ section }: IProps): React.ReactElement => {
    const { backgroundColor, textColor, backgroundImage, iconsCollection, internalName, renderType } = section ?? {}
    const isMobileScreen = useMobileScreenDetection()

    const isSustainableTourBanner = internalName === iconSectionSustainableTourBannerInternalName
    const isStaticDesktopAnimatedMobile = renderType === 'Static icons desktop, animated icons mobile'

    if (!iconsCollection?.items.length) {
        return null
    }

    const renderIcons = () =>
        iconsCollection.items.map(
            (icon, index) =>
                icon && (
                    <picture key={`${icon.key}-${index}`} className="flex-shrink-0">
                        {icon.media?.url?.endsWith('.svg') && <source type="image/svg+xml" srcSet={icon.media?.url} />}
                        <source type="image/webp" srcSet={icon.media?.url + '?w=600&fm=webp'} />
                        <source type="image/jpeg" srcSet={icon.media?.url + '?w=600&fm=jpg'} />

                        <img
                            alt={(icon.value as string) || icon.media?.description || icon.media?.title}
                            src={icon.media?.url + '?w=600&fm=jpg'}
                            height={icon.media?.height}
                            width={icon.media?.width}
                            className={`w-auto ${isSustainableTourBanner ? 'h-4' : 'h-16'}`}
                        />
                    </picture>
                ),
        )

    return (
        <section
            style={{
                background: backgroundColor,
                color: textColor,
            }}
            className={`${getHTMLClassTypeNameFromSection(section)} relative py-7 overflow-hidden ${
                isStaticDesktopAnimatedMobile
                    ? 'sm:section--small-margin-block px-4 sm:px-6 lg:px-8'
                    : 'section--v2 section--small-margin-block px-0 lg:mt-6 mt-3'
            }`}
        >
            {isStaticDesktopAnimatedMobile && <hr className="mb-16 h-[0.25px] border-t-0 bg-gray-medium" />}
            {isStaticDesktopAnimatedMobile && !isMobileScreen ? (
                <div className="flex justify-center items-center gap-6 lg:gap-8 xl:gap-24 overflow-auto scrollbar-hidden">
                    {renderIcons()}
                </div>
            ) : (
                <LoopHorizontal
                    containerClassName="space-x-4 lg:space-x-6 before:w-4 sm:before:w-4 lg:before:w-6 after:w-4 sm:after:w-4 lg:after:w-6 before:flex-shrink-0 after:flex-shrink-0"
                    direction={isSustainableTourBanner ? 'right' : 'left'}
                >
                    <div className="flex space-x-6 lg:space-x-8 flex-shrink-0">{renderIcons()}</div>
                </LoopHorizontal>
            )}
            {isStaticDesktopAnimatedMobile && <hr className="mt-16 h-[0.25px] border-t-0 bg-gray-medium" />}

            {/* background image */}
            {backgroundImage && (
                <picture>
                    {/* webp */}
                    <source
                        type="image/webp"
                        srcSet={`
                            ${backgroundImage?.url}?w=360&fm=webp 360w,
                            ${backgroundImage?.url}?w=720&fm=webp 720w,
                            ${backgroundImage?.url}?w=1024&fm=webp 1024w,
                            ${backgroundImage?.url}?w=1280&fm=webp 1280w,
                            ${backgroundImage?.url}?w=1600&fm=webp 1600w
                        `}
                    />
                    {/* jpeg */}
                    <source
                        type="image/jpeg"
                        srcSet={`
                            ${backgroundImage?.url}?w=360&fm=jpg 360w,
                            ${backgroundImage?.url}?w=720&fm=jpg 720w,
                            ${backgroundImage?.url}?w=1024&fm=jpg 1024w,
                            ${backgroundImage?.url}?w=1280&fm=jpg 1280w,
                            ${backgroundImage?.url}?w=1600&fm=jpg 1600w
                        `}
                    />

                    <img
                        aria-hidden
                        className="w-full h-full object-cover absolute left-0 top-0"
                        src={backgroundImage.url + '?w=1024&fm=jpg'}
                        height={backgroundImage?.height}
                        width={backgroundImage?.width}
                        loading="lazy"
                        alt=""
                    />
                </picture>
            )}
        </section>
    )
}
