import React, { useState, useEffect, useMemo, ReactElement } from 'react'
import Map, { Marker, Popup, NavigationControl } from 'react-map-gl'
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'
import GeocoderControl from './store-locator-geocoder'
import { fetchGeoFeatures } from 'lib/api/geo-features'
import { GEOMETRY_TYPE } from 'enum/mapbox/geometry-type'
import * as Sentry from '@sentry/nextjs'
import CVSHeartIcon from 'icons/cvs-heart-icon'
import { IUiResources } from 'interfaces/ui-resource'
import Loader from 'components/loading/loader'
import {
    useCustomerContext,
    STORE_LOCATOR_SELECT_CLICK_GET_DIRECTIONS_EVENT_NAME,
    STORE_LOCATOR_SELECT_SEARCH_RESULT_EVENT_NAME,
} from 'context/customer-context'
import TargetIcon from 'icons/target-icon'
import { RETAILERS } from 'enum/retailers'
import useMobileScreenDetection from 'hooks/useMobileScreenDetection'

export interface Feature {
    id: string
    properties: {
        STORE_NBR: string
        Zip: string
        Street: string
        Number: string
        City: string
        State: string
        retailer_type: RETAILERS
    }
    geometry: {
        coordinates: [number, number]
        type: GEOMETRY_TYPE
    }
}

interface IProps {
    uiResources?: IUiResources
    genericSection?: boolean
}

const accessToken = process.env.MAPBOX_ACCESS_TOKEN

export default function StoreLocatorMap({ uiResources, genericSection }: IProps): ReactElement {
    const [features, setFeatures] = useState<Feature[]>([])
    const [popupInfo, setPopupInfo] = useState(null)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(true)
    const [userMarker, setUserMarker] = useState([-73, 40])
    const [waitForCustomerLoading, setWaitForCustomerLoading] = useState(true)
    const customer = useCustomerContext()
    const isMobileScreen = useMobileScreenDetection()

    // We need to wait for the customer context to load b/c the React Mapbox lib useControl
    // hook doesn't listen to a change in props. This means the customerContext gets stuck
    // in a loading state in a closure. So instead, we wait for customer context to finish
    // loading and then add the control. This timeout below puts a limit to that wait for
    // situations where those SDKs don't load (e.g. ad blockers)
    useEffect(() => {
        const timeout = setTimeout(() => {
            setWaitForCustomerLoading(false)
        }, 3000)

        return () => clearTimeout(timeout)
    }, [setWaitForCustomerLoading])

    useEffect(() => {
        if (accessToken) {
            fetchGeoFeatures()
                .then((data) => {
                    const featuresWithRetailerType = data.map((feature) => ({
                        ...feature,
                        properties: {
                            ...feature.properties,
                            retailer_type: feature.properties.STORE_NBR ? RETAILERS.CVS : RETAILERS.TARGET,
                        },
                    }))
                    setFeatures(featuresWithRetailerType)
                    setError(false)
                    setLoading(false)
                })
                .catch((error) => {
                    Sentry.captureException(error)
                    setError(true)
                    setLoading(false)
                })
        }
    }, [])

    const renderMarker = (feature: Feature) => {
        const MarkerIcon = feature.properties.retailer_type === RETAILERS.CVS ? CVSHeartIcon : TargetIcon
        return (
            <div key={feature.id}>
                <Marker
                    longitude={feature.geometry.coordinates[0]}
                    latitude={feature.geometry.coordinates[1]}
                    anchor="bottom"
                    onClick={(e) => {
                        e.originalEvent.stopPropagation()
                        setPopupInfo(feature)
                    }}
                >
                    <MarkerIcon width="20" height="20" />
                </Marker>
            </div>
        )
    }

    const mapMarkers = useMemo(() => features.map((feature) => renderMarker(feature)), [features])

    // Event handler for when a customer interacts with a geocoder control result
    // (not popups, but the search dropdown)
    const onResult = (evt: {
        result?: {
            place_name: string
        }
    }) => {
        customer.track(STORE_LOCATOR_SELECT_SEARCH_RESULT_EVENT_NAME, {
            place_name: evt?.result.place_name as string,
        })
    }

    if (error) {
        return (
            <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 mt-32 sm:mt-16">
                <div className="w-full sm:px-4">
                    <div className="flex flex-col items-center justify-center overflow-y-auto overflow-x-hidden h-[700px] text-center">
                        <p className="mb-4">{uiResources['storeLocatorErrorLabel'].value}</p>
                        <span>{uiResources['storeLocatorTryAgainLabel'].value}</span>
                        <a
                            rel="noopener noreferrer"
                            href="https://cabinethealth.com/"
                            className="px-6 py-3 font-semibold rounded-xl bg-painFever text-white mt-4"
                        >
                            {uiResources['storeLocatorHomeLabel'].value}
                        </a>
                    </div>
                </div>
            </div>
        )
    }

    if (loading) {
        return (
            <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 mt-32 sm:mt-16">
                <Loader secondary />
            </div>
        )
    }

    return (
        <div
            className="
		grid grid-cols-1 gap-y-6 sm:gap-y-0 sm:grid-cols-4"
        >
            <div className="sm:col-span-1 order-2 sm:order-1">
                <Sidebar
                    uiResources={uiResources}
                    features={features}
                    userMarker={userMarker}
                    customer={customer}
                    genericSection={genericSection}
                />
            </div>
            <div
                className={`col-span-3 shadow-md border-[1px] border-black-lighter/20 rounded-tr-lg rounded-br-lg ${
                    genericSection ? 'order-1 sm:order-2 border-2' : 'order-2 sm:order-1'
                }`}
            >
                <Map
                    mapLib={mapboxgl}
                    initialViewState={{
                        longitude: -73.990593,
                        latitude: 40.740121,
                        zoom: 10,
                    }}
                    mapStyle="mapbox://styles/mapbox/streets-v12"
                    mapboxAccessToken={accessToken}
                    style={{ width: isMobileScreen ? 380 : '100%', height: isMobileScreen ? 500 : 650 }}
                    maxBounds={[
                        [-140.7911, 15.7763],
                        [-66.93457, 71.5388],
                    ]}
                >
                    {(!waitForCustomerLoading || !customer.loading) && (
                        <GeocoderControl
                            onResult={onResult}
                            mapboxAccessToken={accessToken}
                            setUserMarker={setUserMarker}
                            position="top-left"
                        />
                    )}
                    <NavigationControl position="bottom-right" />
                    {/* TODO: Use geojson layers: fetch -> store data in a source -> inject layer binded to source  */}
                    {mapMarkers}
                    {popupInfo && (
                        <PopupBox
                            popupInfo={popupInfo}
                            onClose={() => setPopupInfo(null)}
                            uiResources={uiResources}
                            customer={customer}
                        />
                    )}
                </Map>
            </div>
        </div>
    )
}

const Sidebar = ({ uiResources, features, userMarker, customer, genericSection }) => {
    const searchResults = useMemo(() => {
        const getNearestStores = () => {
            if (!userMarker) {
                return []
            }

            // calculate distance between user's marker and the stores around it
            const storesWithDistance = features.map((feature) => {
                const storeCoordinates = feature.geometry.coordinates
                const distance = Math.sqrt(
                    Math.pow(userMarker[0] - storeCoordinates[0], 2) + Math.pow(userMarker[1] - storeCoordinates[1], 2),
                )
                return { feature, distance }
            })
            // sort stores based on distance, return the top 15 nearest
            const nearestStores = storesWithDistance.sort((a, b) => a.distance - b.distance).slice(0, 15)
            return nearestStores
        }

        const nearestStores = getNearestStores()

        return nearestStores.map((nearestStore) => {
            const details = `${
                nearestStore.feature.properties.retailer_type === RETAILERS.CVS &&
                nearestStore.feature.properties.Number
                    ? nearestStore.feature.properties.Number + ' '
                    : ''
            }${nearestStore.feature.properties.Street}, ${nearestStore.feature.properties.City}, ${
                nearestStore.feature.properties.State
            } ${nearestStore.feature.properties.Zip} `

            return (
                <ul
                    className="px-6 py-4 flex flex-col bg-white text-black shadow-lg border-b border-gray-medium"
                    key={nearestStore.feature.id}
                >
                    <li className="flex flex-col">
                        <div>
                            {nearestStore.feature.properties.retailer_type === RETAILERS.CVS ? (
                                <div className="flex justify-start items-center space-x-1">
                                    <p className="font-bold">{uiResources['storeLocatorCVSLabel'].value}</p>
                                    <CVSHeartIcon width="14" height="14" />
                                </div>
                            ) : (
                                <div className="flex justify-start items-center space-x-1">
                                    <p className="font-bold">{uiResources['storeLocatorTargetLabel'].value}</p>
                                    <TargetIcon width="14" height="14" />
                                </div>
                            )}
                        </div>

                        {nearestStore.feature.properties.retailer_type === RETAILERS.CVS && (
                            <p className="text-sm">
                                {uiResources['storeLocatorCVSHealthHubLabel'].value}
                                {nearestStore.feature.properties.STORE_NBR}
                            </p>
                        )}

                        <p className="text-sm">
                            {nearestStore.feature.properties.Number} {nearestStore.feature.properties.Street}
                        </p>
                        <p className="text-sm">
                            {nearestStore.feature.properties.City}, {nearestStore.feature.properties.State}{' '}
                            {nearestStore.feature.properties.Zip}
                        </p>
                        <a
                            target="_new"
                            href={`http://maps.google.com/maps?q=${encodeURIComponent(details)}`}
                            className="underline underline-offset-8 text-painFever text-sm"
                            onClick={() => {
                                customer.track(STORE_LOCATOR_SELECT_CLICK_GET_DIRECTIONS_EVENT_NAME, {
                                    details,
                                })
                            }}
                        >
                            {uiResources['storeLocatorDirectionsLabel'].value}
                        </a>
                    </li>
                </ul>
            )
        })
    }, [features, userMarker, uiResources, customer])

    return (
        <div className="w-full shadow-md border-[1px] border-black-lighter/20 rounded-lg hidden sm:block">
            <div className="font-bold text-left text-base xl:text-lg bg-painFever text-white rounded-tl-lg py-2.5 px-6 sticky top-0 w-full">
                {uiResources['storeLocatorSidebarLabel'].value}
            </div>

            <div
                className={`
			flex flex-col overflow-y-auto overflow-x-hidden w-full rounded-bl-lg ${genericSection ? 'h-[600px]' : 'h-[600px]'}`}
            >
                {searchResults}
            </div>
        </div>
    )
}

interface Customer {
    track: (eventName: string, eventDetails: Record<string, unknown>) => void
}
interface PopupBoxProps {
    popupInfo: Feature | null
    onClose: () => void
    uiResources: IUiResources
    customer: Customer
}

const PopupBox: React.FC<PopupBoxProps> = ({ popupInfo, onClose, uiResources, customer }) => {
    if (!popupInfo) return null

    const popupInfoDetails = `${
        popupInfo.properties.retailer_type === RETAILERS.CVS && popupInfo.properties.Number
            ? popupInfo.properties.Number + ' '
            : ''
    }${popupInfo.properties.Street}, ${popupInfo.properties.City}, ${popupInfo.properties.State} ${
        popupInfo.properties.Zip
    } `

    return (
        <Popup
            anchor="bottom"
            longitude={Number(popupInfo.geometry.coordinates[0])}
            latitude={Number(popupInfo.geometry.coordinates[1])}
            onClose={onClose}
            closeButton={false}
            closeOnClick={true}
            focusAfterOpen={true}
            offset={20}
            className="rounded-lg sm:min-w-250"
        >
            <div className="py-2 px-12 flex flex-col space-y-0.5 items-center whitespace-nowrap">
                <div className="flex justify-start items-center space-x-1">
                    {popupInfo.properties.retailer_type === RETAILERS.CVS ? (
                        <>
                            <p className="font-bold text-lg">{uiResources['storeLocatorCVSLabel'].value}</p>
                            <CVSHeartIcon width="14" height="14" />
                        </>
                    ) : (
                        <>
                            <p className="font-bold text-lg">{uiResources['storeLocatorTargetLabel'].value}</p>
                            <TargetIcon width="14" height="14" />
                        </>
                    )}
                </div>

                {popupInfo.properties.retailer_type === RETAILERS.CVS && (
                    <p>
                        {uiResources['storeLocatorCVSHealthHubLabel'].value}
                        {popupInfo.properties.STORE_NBR}
                    </p>
                )}
                <p>
                    {popupInfo.properties.Number} {popupInfo.properties.Street}
                </p>
                <p>
                    {popupInfo.properties.City}, {popupInfo.properties.State}{' '}
                </p>
                <p>{popupInfo.properties.Zip}</p>
                <a
                    target="_new"
                    href={`http://maps.google.com/maps?q=${encodeURIComponent(popupInfoDetails)}`}
                    onClick={() => {
                        customer.track(STORE_LOCATOR_SELECT_CLICK_GET_DIRECTIONS_EVENT_NAME, {
                            popupInfoDetails,
                        })
                    }}
                    className="underline underline-offset-4 text-painFever text-sm"
                >
                    {uiResources['storeLocatorDirectionsLabel'].value}
                </a>
            </div>
        </Popup>
    )
}
