/* eslint-disable react/display-name */
/* eslint-disable prettier/prettier */
import * as React from 'react'
import { BLOCKS } from '@contentful/rich-text-types'
import ContentfulRichText from 'components/rich-text'
import { IGenericPageSection } from 'interfaces/generic-page-section'
import twConfig from 'tailwind-light-config'
const { screens } = twConfig
import ArrowRightLongBodyIcon from 'icons/arrow-right-long-body-icon'
import Link from 'next/link'
import { colors } from 'theme.cjs'

interface IProps {
    section: IGenericPageSection
}

const headerRenderOptions = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (_node, children): React.ReactElement => (
            <h2 className="section--header text-3.5xl lg:text-6.5xl text-center lg:text-right font-medium leading-110% tracking-tight lg:relative lg:-right-20 xl:-right-28">
                {children}
            </h2>
        ),
    },
}

const subHeaderRenderOptions = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (_node, children): React.ReactElement => (
            <div className="section--subheader text-3.5xl lg:text-6.5xl text-center lg:text-left font-medium leading-110% tracking-tight">
                {children}
            </div>
        ),
    },
}

const paragraphRenderOptions = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (_node, children): React.ReactElement => (
            <div className="section--paragraph text-xbase font-medium text-center lg:text-left leading-140%">
                {children}
            </div>
        ),
    },
}

const lgBreakPoint = screens.lg

export const CenteredLayoutWithZigZagText = ({ section }: IProps): React.ReactElement => {
    if (!section) {
        return null
    }

    const {
        header,
        richSubHeader,
        paragraphCopy,
        mediaCollection,
        backgroundColor,
        mobileMediaCollection,
        primaryCtaLink,
    } = section

    const desktopImage = mediaCollection?.items[0]
    const mobileImage = mobileMediaCollection.items[0]

    return (
        <section
            className="section--v2 grid justify-items-center lg:justify-items-start lg:grid-cols-2 gap-6 lg:gap-0"
            style={{ backgroundColor }}
        >
            {header?.json && (
                <ContentfulRichText
                    className="lg:row-start-2 lg:row-end-3 lg:ml-20 lg:mt-16 lg:whitespace-nowrap"
                    content={header.json}
                    renderOptions={headerRenderOptions}
                    ignoreMarkdownStyles
                />
            )}

            {richSubHeader?.json && (
                <ContentfulRichText
                    className="lg:row-start-3 lg:row-end-4 lg:col-start-2 lg:mt-5"
                    content={richSubHeader.json}
                    renderOptions={subHeaderRenderOptions}
                    parseColor
                />
            )}

            {(desktopImage?.url || mobileImage?.url) && (
                <picture className="lg:row-start-1 lg:row-end-2 lg:col-span-2 mx-auto">
                    {desktopImage?.url && (
                        <>
                            {desktopImage.url.endsWith('.svg') && (
                                <source
                                    media={`(min-width: ${lgBreakPoint})`}
                                    type="image/svg+xml"
                                    srcSet={desktopImage.url}
                                />
                            )}
                            <source
                                type="image/webp"
                                media={`(min-width: ${lgBreakPoint})`}
                                srcSet={`
                                    ${desktopImage.url}?fm=webp&w=420 420w
                                `}
                            />
                            <source
                                type="image/jpeg"
                                media={`(min-width: ${lgBreakPoint})`}
                                srcSet={`
                                    ${desktopImage.url}?fm=jpg&w=420 420w
                                `}
                            />
                        </>
                    )}

                    {mobileImage?.url && (
                        <>
                            {mobileImage.url.endsWith('.svg') && (
                                <source type="image/svg+xml" srcSet={mobileImage.url} />
                            )}
                            <source
                                type="image/webp"
                                srcSet={`
                                    ${mobileImage.url}?fm=webp&w=320 320w
                                `}
                            />
                            <source
                                type="image/jpeg"
                                srcSet={`
                                    ${mobileImage.url}?fm=jpg&w=320 320w
                                `}
                            />
                        </>
                    )}

                    <img
                        src={(mobileImage?.url || desktopImage?.url) + '?fm=jpg&w=320'}
                        alt={
                            mobileImage?.description ||
                            desktopImage?.description ||
                            mobileImage?.title ||
                            desktopImage?.title
                        }
                        height={mobileImage?.height || desktopImage?.height}
                        width={mobileImage?.width || desktopImage?.width}
                        className="h-24 lg:h-36"
                        loading="lazy"
                    />
                </picture>
            )}

            {paragraphCopy?.json && (
                <ContentfulRichText
                    className="lg:row-start-4 lg:row-end-5 lg:col-start-2 lg:mt-5"
                    content={paragraphCopy.json}
                    renderOptions={paragraphRenderOptions}
                />
            )}

            {/* button */}
            {primaryCtaLink?.href && (
                <Link
                    href={primaryCtaLink.href}
                    className="flex flex-col lg:flex-row items-center lg:row-start-5 lg:row-end-6 lg:col-start-2 lg:mt-8 group"
                >
                    <div className="rounded-full h-9 w-9 border flex justify-center items-center overflow-hidden relative">
                        <ArrowRightLongBodyIcon
                            height="12"
                            width="13.5"
                            stroke={colors.white}
                            fill={colors.white}
                            className="-translate-x-9 group-hover:translate-x-2 duration-[415ms] ease-out relative z-10"
                        />
                        <ArrowRightLongBodyIcon
                            height="12"
                            width="13.5"
                            className="-translate-x-1.5 group-hover:translate-x-9 duration-300 ease-out"
                        />
                        <div className="h-full w-full absolute bg-black -translate-x-full group-hover:translate-x-0 duration-200 ease-out" />
                    </div>
                    <div className="mt-4 lg:mt-0 lg:ml-4 text-xsm font-bold text-center lg:text-left tracking-wider max-w-[12.5rem] hover:underline">
                        {primaryCtaLink.name}
                    </div>
                </Link>
            )}
        </section>
    )
}
