import * as React from 'react'

const CVSHeartIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1516 1259" width={1516} height={1259} {...props}>
        <title>{'CVS_Health_Logo-svg'}</title>
        <style>{'\n    .s0 { fill: #cc0000 }\n  '}</style>
        <g id="Layer">
            <path
                id="Layer"
                className="s0"
                d="m301.1 51.3c30.4-30.6 74.4-50.9 121.8-50.9 47.3 0 94.7 16.9 128.5 50.9 67.7 67.8 138.7 139 206.4 206.8 67.6-67.8 138.7-139 206.3-206.8 30.5-30.6 77.9-50.9 121.8-50.9 47.4 0 91.4 16.9 125.2 50.9q126.9 127.1 253.7 254.3c33.9 33.9 54.2 84.8 50.8 132.2-3.4 44.1-20.3 84.8-50.8 115.3-236.8 234-470.2 471.4-707 705.3-236.8-233.9-470.2-471.3-707.1-705.3-30.4-30.5-47.3-71.2-50.7-111.9v-27.1c3.4-40.7 20.3-81.4 50.7-111.9 84.6-81.4 165.8-166.2 250.4-250.9z"
            />
        </g>
    </svg>
)
export default CVSHeartIcon
