import useMobileScreenDetection from 'hooks/useMobileScreenDetection'
import { IGenericPageSection } from 'interfaces/generic-page-section'
import { IImage } from 'interfaces/media'
import * as React from 'react'
import twConfig from 'tailwind-light-config'
const { screens } = twConfig

interface IProps {
    section: IGenericPageSection
}

const lgBreakPoint = screens.lg
const _2xlBreakPoint = screens['2xl']

const Card = ({ mobileImage, desktopImage }: { mobileImage: IImage; desktopImage: IImage }): React.ReactElement => {
    const isMobileScreen = useMobileScreenDetection()

    if (!mobileImage?.url && !desktopImage?.url) {
        return null
    }

    let title = ''
    let description = ''

    // use title + description from mobile image in mobile
    // screen and desktop image for desktop screen, use the
    // other image as fallback
    if (isMobileScreen) {
        title = mobileImage?.title ?? desktopImage?.title
        description = mobileImage?.description ?? desktopImage?.description
    } else {
        title = desktopImage?.title ?? mobileImage?.title
        description = desktopImage?.description ?? mobileImage?.description
    }

    return (
        <div>
            <picture>
                {desktopImage?.url && (
                    <>
                        <source
                            media={`(min-width: ${lgBreakPoint})`}
                            type="image/webp"
                            srcSet={`
                        ${desktopImage.url}?fm=webp&w=1024 1024w,
                        ${desktopImage.url}?fm=webp&w=1280 1280w,
                        ${desktopImage.url}?fm=webp&w=1600 1600w
                    `}
                            sizes={`(min-width: ${_2xlBreakPoint}): 800px, 50vw`}
                        />

                        <source
                            media={`(min-width: ${lgBreakPoint})`}
                            type="image/jpeg"
                            srcSet={`
                        ${desktopImage.url}?fm=jpg&w=1024 1024w,
                        ${desktopImage.url}?fm=jpg&w=1280 1280w,
                        ${desktopImage.url}?fm=jpg&w=1600 1600w
                    `}
                            sizes={`(min-width: ${_2xlBreakPoint}): 800px, 50vw`}
                        />
                    </>
                )}

                {mobileImage?.url && (
                    <>
                        <source
                            type="image/webp"
                            srcSet={`
                        ${mobileImage.url}?fm=webp&w=320 320w,
                        ${mobileImage.url}?fm=webp&w=420 420w,
                        ${mobileImage.url}?fm=webp&w=640 640w
                    `}
                        />

                        <source
                            type="image/jpeg"
                            srcSet={`
                        ${mobileImage.url}?fm=jpg&w=320 320w,
                        ${mobileImage.url}?fm=jpg&w=420 420w,
                        ${mobileImage.url}?fm=jpg&w=640 640w
                    `}
                        />
                    </>
                )}

                <img
                    src={(mobileImage?.url || desktopImage?.url) + '?fm=jpg&w=640'}
                    alt={mobileImage?.title || desktopImage?.title}
                    height={mobileImage?.height || desktopImage?.height}
                    width={mobileImage?.width || desktopImage?.width}
                    loading="lazy"
                    className="rounded-2lg overflow-hidden"
                />
            </picture>

            <div className="mt-7.5 lg:mt-6 text-lg md:text-xl font-medium leading-120% tracking-tight">{title}</div>
            <div className="mt-3.5 text-xbase lg:text-base font-medium leading-140%">{description}</div>
        </div>
    )
}

export const TwoCardsSideBySideSection = ({ section }: IProps): React.ReactElement => {
    if (!section) {
        return null
    }

    const { mediaCollection, mobileMediaCollection } = section

    const firstMobileImage = mobileMediaCollection.items[0]
    const firstDesktopImage = mediaCollection.items[0]

    const secondMobileImage = mobileMediaCollection.items[1]
    const secondDesktopImage = mediaCollection.items[1]

    return (
        <section className="section--v2 grid md:grid-cols-2 gap-16 md:gap-4">
            {/* first card */}
            {(firstMobileImage || firstDesktopImage) && (
                <Card mobileImage={firstMobileImage} desktopImage={firstDesktopImage} />
            )}

            {/* second card */}
            {(secondMobileImage || secondDesktopImage) && (
                <Card mobileImage={secondMobileImage} desktopImage={secondDesktopImage} />
            )}
        </section>
    )
}
