import * as React from 'react'
import { IPageSectionComparisonTable } from 'interfaces/page-section-comparison-table'
import ComparisonTableColumnsSection from './comparison-table-columns-section'
import ComparisonTableSection from './comparison-table-section'

interface IProps {
    section: IPageSectionComparisonTable
}

const PageSectionComparisonTable = ({ section }: IProps): React.ReactElement => {
    if (!section) {
        return null
    }

    switch (section.renderType) {
        case 'Table':
            return <ComparisonTableSection section={section} />
        case 'Columns':
            return <ComparisonTableColumnsSection section={section} />
    }
}

export default PageSectionComparisonTable
