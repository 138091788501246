import * as React from 'react'
import { BLOCKS } from '@contentful/rich-text-types'
import ContentfulRichText from 'components/rich-text'
import { IGenericPageSection } from 'interfaces/generic-page-section'
import { IUiResources } from 'interfaces/ui-resource'
import StoreLocatorMap from 'components/store-locator/store-locator-map'
import Link from 'next/link'
import { RenderNode } from '@contentful/rich-text-react-renderer'

interface IProps {
    section: IGenericPageSection
    uiResources: IUiResources
}

const headerRenderNode: RenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => (
        <h2 className="section--header text-1xl lg:text-5xl font-medium leading-120%">{children}</h2>
    ),
}

const subheaderRenderNode: RenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children): React.ReactElement => (
        <span className="font-bold text-base tracking-wider text-center">{children}</span>
    ),
}

export const StoreLocatorSection = ({ section, uiResources }: IProps): React.ReactElement => {
    if (!section) {
        return null
    }

    const { header, richSubHeader, backgroundColor, primaryCtaLink } = section

    return (
        <section
            id="store-locator"
            className="section--v2--sm relative py-16 lg:pt-20 pb-0 lg:pb-24"
            style={{ backgroundColor }}
        >
            <div className="relative flex flex-col justify-start items-center z-10">
                {richSubHeader?.json && (
                    <div className="text-center max-w-xl">
                        <ContentfulRichText content={richSubHeader?.json} renderNode={subheaderRenderNode} />
                    </div>
                )}
                {header?.json && (
                    <div className="text-center -mt-10">
                        <ContentfulRichText content={header?.json} renderNode={headerRenderNode} />
                    </div>
                )}

                <div>
                    <StoreLocatorMap uiResources={uiResources} genericSection />
                </div>

                {primaryCtaLink?.href && (
                    <Link
                        style={{
                            background: primaryCtaLink.associatedColors,
                        }}
                        href={primaryCtaLink.href}
                        className="button text-white transition-colors duration-200 lg:inline-block min-w-[350px] sm:text-xl py-4 border-black border-2 text-lg font-bold mb-2 sm:mt-8"
                    >
                        {primaryCtaLink.name}
                    </Link>
                )}
            </div>
        </section>
    )
}
