import * as React from 'react'
import { BLOCKS } from '@contentful/rich-text-types'
import ContentfulRichText from 'components/rich-text'
import { IPageSectionTestimonials } from 'interfaces/page-section-testimonials'
import StarIcon from 'icons/pointed-star'
import twConfig from 'tailwind-light-config'

const { screens } = twConfig
const lgBreakPoint = Number(screens.lg.replace('px', ''))

interface IProps {
    section: IPageSectionTestimonials
}

const reviewRenderNode = {
    // eslint-disable-next-line react/display-name
    [BLOCKS.PARAGRAPH]: (_node, children) => <p className="blockquote">{children}</p>,
}

export const SingleReviewSection = ({ section }: IProps): React.ReactElement => {
    if (!section) {
        return null
    }

    const { featuredReviewsCollection } = section

    const featuredReview = featuredReviewsCollection?.items?.[0]

    return (
        <section className="section p-0 relative h-[33rem] lg:h-[38rem] grid grid-cols-1 grid-rows-1">
            {/* background picture */}
            <picture className="row-start-1 row-end-2 col-start-1 col-end-2">
                {/* Mobile image */}
                {featuredReview?.videoPosterImage?.url && (
                    <>
                        <source
                            type="image/webp"
                            media={`(max-width: ${lgBreakPoint - 1}px)`}
                            srcSet={`
                    ${featuredReview.videoPosterImage.url}?fm=webp&w=420 420w,
                    ${featuredReview.videoPosterImage.url}?fm=webp&w=640 640w
                `}
                        />
                        <source
                            type="image/jpeg"
                            media={`(max-width: ${lgBreakPoint - 1}px)`}
                            srcSet={`
                    ${featuredReview.videoPosterImage.url}?fm=jpg&w=420 420w,
                    ${featuredReview.videoPosterImage.url}?fm=jpg&w=640 640w
                `}
                        />
                    </>
                )}

                {/* Desktop image */}
                {featuredReview?.authorPhoto?.url && (
                    <>
                        <source
                            type="image/webp"
                            media={`(min-width: ${lgBreakPoint}px)`}
                            srcSet={`
                    ${featuredReview.authorPhoto.url}?fm=webp&w=1024 1024w,
                    ${featuredReview.authorPhoto.url}?fm=webp&w=1280 1280w,
                    ${featuredReview.authorPhoto.url}?fm=webp&w=1600 1600w
                `}
                        />
                        <source
                            type="image/jpeg"
                            media={`(min-width: ${lgBreakPoint}px)`}
                            srcSet={`
                    ${featuredReview.authorPhoto.url}?fm=jpg&w=1024 1024w,
                    ${featuredReview.authorPhoto.url}?fm=jpg&w=1280 1280w,
                    ${featuredReview.authorPhoto.url}?fm=jpg&w=1600 1600w
                `}
                        />
                    </>
                )}

                <img
                    src={featuredReview.authorPhoto?.url || featuredReview.videoPosterImage?.url}
                    alt={featuredReview.authorPhoto?.title || featuredReview.videoPosterImage?.title || 'Review author'}
                    className="h-full w-full object-cover"
                    loading="lazy"
                />
            </picture>

            {/* review */}
            {featuredReview?.content?.json && (
                <div className="px-5 lg:px-15 py-5 lg:py-20 row-start-1 row-end-2 col-start-1 col-end-2 relative mt-auto">
                    <div className="bg-white px-5 py-7 lg:px-7.5 lg:py-9 rounded-3xl max-w-sm">
                        {/* stars */}
                        <div className="flex space-x-1.5">
                            {new Array(5).fill('').map((_, index) => (
                                <StarIcon key={index} height="15" width="15" />
                            ))}
                        </div>

                        <figure className="mt-4 lg:mt-5">
                            <blockquote>
                                <ContentfulRichText
                                    content={featuredReview.content.json}
                                    renderNode={reviewRenderNode}
                                    className="lg:text-xl leading-140% lg:tracking-tight"
                                />
                            </blockquote>
                            <figcaption>
                                <cite className="mt-4 lg:mt-5 block">
                                    <span className="text-xsm leading-120% font-bold uppercase tracking-wider not-italic">
                                        - {featuredReview.authorName}
                                    </span>
                                </cite>
                            </figcaption>
                        </figure>
                    </div>
                </div>
            )}
        </section>
    )
}
