import { BLOCKS } from '@contentful/rich-text-types'
import ContentfulRichText from 'components/rich-text'
import { IGenericPageSection } from 'interfaces/generic-page-section'
import { IImage } from 'interfaces/media'
import Link from 'next/link'
import * as React from 'react'
import twConfig from 'tailwind-light-config'
const { screens } = twConfig

interface IProps {
    section: IGenericPageSection
}

const lgBreakPoint = screens.lg
const _2xlBreakPoint = screens['2xl']

const headerRenderNode = {
    // eslint-disable-next-line react/display-name
    [BLOCKS.PARAGRAPH]: (_node, children) => (
        <div className="text-3.5xl lg:text-6.25xl text-black leading-110% tracking-tight">{children}</div>
    ),
}

const Card = ({
    mobileImage,
    desktopImage,
    videoPosterImage,
}: {
    mobileImage: IImage
    desktopImage: IImage
    videoPosterImage?: IImage
}): React.ReactElement => {
    if (!mobileImage?.url && !desktopImage?.url) {
        return null
    }

    return (
        <div>
            <picture className="">
                {desktopImage?.url && (
                    <>
                        <source
                            media={`(min-width: ${lgBreakPoint})`}
                            type="image/webp"
                            srcSet={`
                        ${desktopImage.url}?fm=webp&w=1024 1024w,
                        ${desktopImage.url}?fm=webp&w=1280 1280w,
                        ${desktopImage.url}?fm=webp&w=1600 1600w
                    `}
                            sizes={`(min-width: ${_2xlBreakPoint}): 800px, 50vw`}
                        />

                        <source
                            media={`(min-width: ${lgBreakPoint})`}
                            type="image/jpeg"
                            srcSet={`
                        ${desktopImage.url}?fm=jpg&w=1024 1024w,
                        ${desktopImage.url}?fm=jpg&w=1280 1280w,
                        ${desktopImage.url}?fm=jpg&w=1600 1600w
                    `}
                            sizes={`(min-width: ${_2xlBreakPoint}): 800px, 50vw`}
                        />
                    </>
                )}

                {mobileImage?.url && (
                    <>
                        <source
                            type="image/webp"
                            srcSet={`
                        ${mobileImage.url}?fm=webp&w=320 320w,
                        ${mobileImage.url}?fm=webp&w=420 420w,
                        ${mobileImage.url}?fm=webp&w=640 640w
                    `}
                        />

                        <source
                            type="image/jpeg"
                            srcSet={`
                        ${mobileImage.url}?fm=jpg&w=320 320w,
                        ${mobileImage.url}?fm=jpg&w=420 420w,
                        ${mobileImage.url}?fm=jpg&w=640 640w
                    `}
                        />
                    </>
                )}

                {videoPosterImage?.url && (
                    <>
                        <source
                            type="image/webp"
                            srcSet={`
                        ${mobileImage.url}?fm=webp&w=320 320w,
                        ${mobileImage.url}?fm=webp&w=420 420w,
                        ${mobileImage.url}?fm=webp&w=640 640w
                    `}
                        />

                        <source
                            type="image/jpeg"
                            srcSet={`
                        ${mobileImage.url}?fm=jpg&w=320 320w,
                        ${mobileImage.url}?fm=jpg&w=420 420w,
                        ${mobileImage.url}?fm=jpg&w=640 640w
                    `}
                        />
                    </>
                )}

                <img
                    src={(mobileImage?.url || desktopImage?.url) + '?fm=jpg&w=640'}
                    alt={mobileImage?.title || desktopImage?.title}
                    height={mobileImage?.height || desktopImage?.height}
                    width={mobileImage?.width || desktopImage?.width}
                    loading="lazy"
                    className="overflow-hidden rounded-2xl"
                />
            </picture>
        </div>
    )
}

export const IconMiddleImageSidesSection = ({ section }: IProps): React.ReactElement => {
    if (!section) {
        return null
    }

    const {
        mediaCollection,
        mobileMediaCollection,
        header,
        videoPosterImage,
        primaryCtaLink,
        secondaryCtaLink,
        renderType,
    } = section

    const firstMobileImage = mobileMediaCollection.items[0]
    const firstDesktopImage = mediaCollection.items[0]

    const secondMobileImage = mobileMediaCollection.items[1]
    const secondDesktopImage = mediaCollection.items[1]

    return (
        <>
            {/* content */}
            {renderType === 'Icon Middle Image Both Sides' && (
                <div className="lg:px-15 px-5 flex flex-col items-start">
                    {header?.json && (
                        <h2>
                            <ContentfulRichText content={header.json} renderNode={headerRenderNode} />
                        </h2>
                    )}
                </div>
            )}

            {renderType === 'Icon Middle Image Both Sides' && (
                <section className="px-5 sm:px-6 lg:px-15 grid md:grid-cols-2 mt-12 relative gap-2 sm:gap-0">
                    {/* first card */}
                    <div className="relative">
                        {(firstMobileImage || firstDesktopImage) && (
                            <Card mobileImage={firstMobileImage} desktopImage={firstDesktopImage} />
                        )}
                        {primaryCtaLink && (
                            <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10">
                                <Link
                                    href={primaryCtaLink.href}
                                    className="button button-primary mt-6 inline-block min-w-250"
                                >
                                    {primaryCtaLink.name}
                                </Link>
                            </div>
                        )}
                    </div>

                    {/* centered media */}
                    <picture className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 hidden lg:block">
                        <>
                            <source
                                media={`(min-width: ${lgBreakPoint})`}
                                type="image/webp"
                                srcSet={`
                        ${videoPosterImage?.url}?fm=webp&w=1024 1024w,
                        ${videoPosterImage?.url}?fm=webp&w=1280 1280w,
                        ${videoPosterImage?.url}?fm=webp&w=1600 1600w
                    `}
                                sizes={`(min-width: ${_2xlBreakPoint}): 800px, 50vw`}
                            />

                            <source
                                media={`(min-width: ${lgBreakPoint})`}
                                type="image/jpeg"
                                srcSet={`
                        ${videoPosterImage?.url}?fm=jpg&w=1024 1024w,
                        ${videoPosterImage?.url}?fm=jpg&w=1280 1280w,
                        ${videoPosterImage?.url}?fm=jpg&w=1600 1600w
                    `}
                                sizes={`(min-width: ${_2xlBreakPoint}): 800px, 50vw`}
                            />

                            <source
                                media={`(min-width: ${lgBreakPoint})`}
                                type="image/svg"
                                srcSet={`
                        ${videoPosterImage?.url}?fm=svg&w=1024 1024w,
                        ${videoPosterImage?.url}?fm=svg&w=1280 1280w,
                        ${videoPosterImage?.url}?fm=svg&w=1600 1600w
                    `}
                                sizes={`(min-width: ${_2xlBreakPoint}): 800px, 50vw`}
                            />
                        </>
                        <img
                            src={videoPosterImage?.url + '?fm=jpg&w=640'}
                            alt={videoPosterImage?.url}
                            height={videoPosterImage?.height}
                            width={videoPosterImage?.width}
                            loading="lazy"
                            className="rounded-2lg overflow-hidden"
                        />
                    </picture>

                    {/* second card */}
                    <div className="relative">
                        {(secondMobileImage || secondDesktopImage) && (
                            <Card mobileImage={secondMobileImage} desktopImage={secondDesktopImage} />
                        )}
                        {/* secondary CTA */}
                        {secondaryCtaLink && (
                            <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10">
                                <Link
                                    href={secondaryCtaLink.href}
                                    className="button button-primary mt-6 inline-block min-w-250 bg-[#FFFFFF] text-[#3C3C3C] border-none"
                                >
                                    {secondaryCtaLink.name}
                                </Link>
                            </div>
                        )}
                    </div>
                </section>
            )}

            {/* Icon Middle Image Both Sides V2 */}
            {renderType === 'Icon Middle Image Both Sides V2' && (
                <section className="px-5 sm:px-6 lg:px-15 grid md:grid-cols-3 mt-12 relative gap-6">
                    <div className="px-5 flex flex-col items-start justify-center">
                        {header?.json && (
                            <h2>
                                <ContentfulRichText content={header.json} renderNode={headerRenderNode} />
                            </h2>
                        )}
                    </div>
                    {/* first card */}
                    <div className="relative">
                        {(firstMobileImage || firstDesktopImage) && (
                            <Card mobileImage={firstMobileImage} desktopImage={firstDesktopImage} />
                        )}
                        {primaryCtaLink && (
                            <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10">
                                <Link
                                    href={primaryCtaLink.href}
                                    className="button button-primary mt-6 inline-block min-w-250 bg-[#FFFFFF] text-[#3C3C3C] border-none"
                                >
                                    {primaryCtaLink.name}
                                </Link>
                            </div>
                        )}
                        {/* sticker */}
                        <picture className="absolute top-8 left-11 xl:left-0 transform -translate-x-1/2 -translate-y-1/2 z-10 h-28 w-28 sm:h-28 sm:w-28 xl:h-32 xl:w-32">
                            <>
                                <source
                                    media={`(min-width: ${lgBreakPoint})`}
                                    type="image/webp"
                                    srcSet={`
                                   ${videoPosterImage?.url}?fm=webp&w=1024 1024w,
                                   ${videoPosterImage?.url}?fm=webp&w=1280 1280w,
                                   ${videoPosterImage?.url}?fm=webp&w=1600 1600w
                               `}
                                    sizes={`(min-width: ${_2xlBreakPoint}): 800px, 50vw`}
                                />

                                <source
                                    media={`(min-width: ${lgBreakPoint})`}
                                    type="image/jpeg"
                                    srcSet={`
                                   ${videoPosterImage?.url}?fm=jpg&w=1024 1024w,
                                   ${videoPosterImage?.url}?fm=jpg&w=1280 1280w,
                                   ${videoPosterImage?.url}?fm=jpg&w=1600 1600w
                               `}
                                    sizes={`(min-width: ${_2xlBreakPoint}): 800px, 50vw`}
                                />

                                <source
                                    media={`(min-width: ${lgBreakPoint})`}
                                    type="image/svg"
                                    srcSet={`
                                   ${videoPosterImage?.url}?fm=svg&w=1024 1024w,
                                   ${videoPosterImage?.url}?fm=svg&w=1280 1280w,
                                   ${videoPosterImage?.url}?fm=svg&w=1600 1600w
                               `}
                                    sizes={`(min-width: ${_2xlBreakPoint}): 800px, 50vw`}
                                />
                            </>
                            <img
                                src={videoPosterImage?.url + '?fm=jpg&w=640'}
                                alt={videoPosterImage?.url}
                                height={130}
                                width={130}
                                loading="lazy"
                                className="rounded-full overflow-hidden"
                            />
                        </picture>
                    </div>

                    {/* second card */}
                    <div className="relative">
                        {(secondMobileImage || secondDesktopImage) && (
                            <Card mobileImage={secondMobileImage} desktopImage={secondDesktopImage} />
                        )}
                        {/* secondary CTA */}
                        {secondaryCtaLink && (
                            <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10">
                                <Link
                                    href={secondaryCtaLink.href}
                                    className="button button-primary mt-6 inline-block min-w-250 bg-[#FFFFFF] text-[#3C3C3C] border-none"
                                >
                                    {secondaryCtaLink.name}
                                </Link>
                            </div>
                        )}
                    </div>
                </section>
            )}
        </>
    )
}
