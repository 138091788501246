import { BLOCKS } from '@contentful/rich-text-types'
import ContentfulRichText from 'components/rich-text'
import { IGenericPageSection } from 'interfaces/generic-page-section'
import Link from 'next/link'
import * as React from 'react'
import twConfig from 'tailwind-light-config'
const { screens } = twConfig

interface IProps {
    section: IGenericPageSection
}

const smBreakPoint = screens.sm

const headerRenderNode = {
    // eslint-disable-next-line react/display-name
    [BLOCKS.PARAGRAPH]: (_node, children) => (
        <h2 className="section--header text-1xl lg:text-3.25xl font-medium leading-120%">{children}</h2>
    ),
}

export const ScrollingGridSection = ({ section }: IProps): React.ReactElement => {
    if (!section) {
        return null
    }

    const { header, mobileMediaCollection, mediaCollection, primaryCtaLink } = section

    const renderGrid = () => {
        const elements = []

        for (let i = 0; i < Math.max(mobileMediaCollection.items.length, mediaCollection.items.length); i++) {
            const mobileImage = mobileMediaCollection.items[i]
            const desktopImage = mediaCollection.items[i]

            if (mobileImage?.url || desktopImage?.url) {
                elements.push(
                    <Link
                        key={mobileImage?.sys?.id || desktopImage?.sys?.id}
                        href={primaryCtaLink?.href ?? ''}
                        className="snap-start sm:pr-5 first:sm:pl-6 first:lg:pl-15 last:sm:pr-6 last:lg:pr-15 flex-shrink-0"
                    >
                        <picture className="relative block before:block sm:before:hidden sm:static before:pt-[100%]">
                            {desktopImage?.url && (
                                <>
                                    <source
                                        type="image/webp"
                                        media={`(min-width: ${smBreakPoint})`}
                                        srcSet={desktopImage.url + '?fm=webp&w=420'}
                                    />
                                    <source
                                        type="image/jpg"
                                        media={`(min-width: ${smBreakPoint})`}
                                        srcSet={desktopImage.url + '?fm=jpg&w=420'}
                                    />
                                </>
                            )}

                            {mobileImage?.url && (
                                <>
                                    <source type="image/webp" srcSet={mobileImage.url + '?fm=webp&w=420'} />
                                    <source type="image/jpg" srcSet={mobileImage.url + '?fm=jpg&w=420'} />
                                </>
                            )}

                            <img
                                src={(mobileImage?.url || desktopImage?.url) + '?fm=jpg&w=420'}
                                alt={
                                    mobileImage?.description ||
                                    desktopImage?.description ||
                                    mobileImage?.title ||
                                    desktopImage?.title
                                }
                                height={mobileImage?.height || desktopImage?.height}
                                width={mobileImage?.width || desktopImage?.width}
                                loading="lazy"
                                className="h-full sm:h-[275px] w-[275px] object-cover rounded-2lg absolute left-0 top-0 sm:static"
                            />
                        </picture>
                    </Link>,
                )
            }
        }

        return elements
    }

    return (
        <section className="section--v2">
            {header?.json && <ContentfulRichText content={header.json} renderNode={headerRenderNode} />}

            {/* grid */}
            <div className="mt-7.5 lg:mt-12.5 sm:-mx-6 lg:-mx-15 lg:flex justify-center">
                <div className="grid grid-cols-2 gap-2.5 overflow-auto scrollbar-hidden snap-x sm:scroll-p-6 lg:scroll-p-15 sm:flex">
                    {renderGrid()}
                </div>
            </div>
        </section>
    )
}
