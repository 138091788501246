/* eslint-disable react/display-name */
import * as React from 'react'
import ContentfulRichText from 'components/rich-text'
import { IGenericPageSection } from 'interfaces/generic-page-section'
import { IImage } from 'interfaces/media'
import twConfig from 'tailwind-light-config'
const { screens } = twConfig
import { BLOCKS } from '@contentful/rich-text-types'
import styles from './style.module.css'

interface IProps {
    section: IGenericPageSection
}

const lgBreakPoint = screens.lg

const LayeredImage = ({
    mobileImage,
    desktopImage,
    className = '',
    style,
    pictureClassName = '',
}: {
    mobileImage: IImage
    desktopImage: IImage
    className?: string
    style?: React.CSSProperties
    pictureClassName?: string
}): React.ReactElement => {
    if (!mobileImage?.url && !desktopImage?.url) {
        return null
    }

    return (
        <picture className={pictureClassName}>
            {desktopImage?.url && (
                <source type="image/svg+xml" srcSet={desktopImage.url} media={`(min-width: ${lgBreakPoint})`} />
            )}
            {mobileImage?.url && <source type="image/svg+xml" srcSet={mobileImage.url} />}

            <img
                src={(mobileImage?.url || desktopImage?.url) + '?fm=jpg&w=420'}
                alt={mobileImage?.title || mobileImage?.description || desktopImage?.title || desktopImage?.description}
                height={mobileImage?.height || desktopImage?.height}
                width={mobileImage?.width || desktopImage?.width}
                className={className}
                style={style}
                loading="lazy"
            />
        </picture>
    )
}

export const BottlesUnderWaterSection = ({ section }: IProps): React.ReactElement => {
    if (!section) {
        return null
    }

    const { header, richSubHeader, mediaCollection, mobileMediaCollection, backgroundColor } = section

    const fixedLayerDesktopImage = mediaCollection?.items?.[2]
    const fixedLayerMobileImage = mobileMediaCollection?.items?.[2]

    const movingLayerDesktopImage = mediaCollection?.items?.[1]
    const movingLayerMobileImage = mobileMediaCollection?.items?.[1]

    const backgroundDesktopImage = mediaCollection?.items?.[0]
    const backgroundMobileImage = mobileMediaCollection?.items?.[0]

    let headerChildrenNodeCount = 0

    const headerRenderNode = {
        [BLOCKS.PARAGRAPH]: (_node, children) => {
            headerChildrenNodeCount++
            return (
                <p
                    className={`${
                        headerChildrenNodeCount === 1 ? 'text-5xl lg:text-6.5xl' : 'text-1xl lg:text-3.25xl'
                    } text-center lg:text-left font-medium leading-120% tracking-tight`}
                >
                    {children}
                </p>
            )
        },
    }

    let subHeaderChildrenNodeCount = 0

    const subHeaderRenderNode = {
        [BLOCKS.PARAGRAPH]: (_node, children) => {
            subHeaderChildrenNodeCount++
            return (
                <p
                    className={`${
                        subHeaderChildrenNodeCount === 1 ? 'text-5xl lg:text-6.5xl' : 'text-1xl lg:text-3.25xl'
                    } text-white font-medium text-center lg:text-left`}
                >
                    {children}
                </p>
            )
        },
    }

    return (
        <section className={`section p-0 grid lg:grid-cols-2 ${styles['section-grid']}`} style={{ backgroundColor }}>
            <div className="py-16 lg:py-24 row-start-1 row-end-2 col-start-1 col-end-2 lg:col-start-1 lg:col-end-3 lg:max-h-[var(--screenHeightDeductingHeader)] flex justify-center lg:justify-start lg:items-center">
                {/* header */}
                {header?.json && (
                    <ContentfulRichText
                        content={header.json}
                        className="header px-10 lg:px-15 lg:w-1/2 lg:ml-auto"
                        renderNode={headerRenderNode}
                        parseSuperscriptTag
                    />
                )}
            </div>

            {/* background image with content */}
            <div className="relative max-h-[var(--screenHeightDeductingHeader)] overflow-hidden row-start-2 row-end-3 col-start-1 col-end-3 lg:col-start-1 lg:col-end-3 lg:row-start-2">
                {/* background image */}
                {(backgroundDesktopImage?.url || backgroundMobileImage?.url) && (
                    <picture>
                        {backgroundDesktopImage?.url && (
                            <>
                                <source
                                    type="image/webp"
                                    media={`(min-width: ${lgBreakPoint})`}
                                    srcSet={`
                                        ${backgroundDesktopImage.url}?fm=webp&w=1024 1024w,
                                        ${backgroundDesktopImage.url}?fm=webp&w=1280 1280w,
                                        ${backgroundDesktopImage.url}?fm=webp&w=1600 1600w,
                                        ${backgroundDesktopImage.url}?fm=webp&w=2000 2000w
                                    `}
                                />

                                <source
                                    type="image/jpeg"
                                    media={`(min-width: ${lgBreakPoint})`}
                                    srcSet={`
                                        ${backgroundDesktopImage.url}?fm=jpg&w=1024 1024w,
                                        ${backgroundDesktopImage.url}?fm=jpg&w=1280 1280w,
                                        ${backgroundDesktopImage.url}?fm=jpg&w=1600 1600w,
                                        ${backgroundDesktopImage.url}?fm=jpg&w=2000 200ow
                                    `}
                                />
                            </>
                        )}

                        {backgroundMobileImage?.url && (
                            <>
                                <source
                                    type="image/webp"
                                    srcSet={`
                                        ${backgroundMobileImage.url}?fm=webp&w=360 360w,
                                        ${backgroundMobileImage.url}?fm=webp&w=820 820w,
                                        ${backgroundMobileImage.url}?fm=webp&w=1024 1024w,
                                        ${backgroundMobileImage.url}?fm=webp&w=1200 1200w
                                    `}
                                />

                                <source
                                    type="image/jpeg"
                                    srcSet={`
                                        ${backgroundMobileImage.url}?fm=jpg&w=360 360w,
                                        ${backgroundMobileImage.url}?fm=jpg&w=820 820w,
                                        ${backgroundMobileImage.url}?fm=jpg&w=1024 1024w,
                                        ${backgroundMobileImage.url}?fm=jpg&w=1200 1200w
                                    `}
                                />
                            </>
                        )}

                        <img
                            src={(backgroundMobileImage?.url || backgroundDesktopImage?.url) + '?fm=jpg&w=1024'}
                            alt={
                                backgroundMobileImage?.title ||
                                backgroundMobileImage?.description ||
                                backgroundDesktopImage?.title ||
                                backgroundDesktopImage?.description
                            }
                            height={backgroundMobileImage?.height || backgroundDesktopImage?.height}
                            width={backgroundDesktopImage?.height || backgroundDesktopImage?.height}
                            className="h-full object-cover"
                            loading="lazy"
                        />
                    </picture>
                )}

                {/* content */}
                {richSubHeader?.json && (
                    <ContentfulRichText
                        content={richSubHeader.json}
                        renderNode={subHeaderRenderNode}
                        className="sub-header absolute bottom-16 px-10 lg:px-15 lg:w-1/2 lg:left-1/2 lg:top-1/2 lg:-translate-y-15"
                        parseSuperscriptTag
                    />
                )}
            </div>

            {/* layered images */}
            <div className="col-start-1 col-end-2 row-start-1 row-end-3 lg:col-start-1 lg:col-end-2 grid justify-center">
                {/* fixed layer */}
                <LayeredImage
                    className="py-52 lg:py-28 w-40 lg:w-72"
                    pictureClassName="col-start-1 row-start-1"
                    mobileImage={fixedLayerDesktopImage}
                    desktopImage={fixedLayerMobileImage}
                />

                {/* moving layer */}
                <LayeredImage
                    className="sticky top-15 lg:top-28 py-52 lg:py-28 w-40 lg:w-72"
                    pictureClassName="col-start-1 row-start-1 pb-24 sm:pb-0"
                    mobileImage={movingLayerMobileImage}
                    desktopImage={movingLayerDesktopImage}
                />
            </div>
        </section>
    )
}
