/* eslint-disable react/display-name */
import * as React from 'react'
import { IGenericPageSection } from 'interfaces/generic-page-section'
import ContentfulRichText from 'components/rich-text'
import { BLOCKS } from '@contentful/rich-text-types'
import twConfig from 'tailwind-light-config'
const { screens } = twConfig

interface IProps {
    section: IGenericPageSection
}

const headerRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => (
        <h2 className="section--header section--header-max-width mx-auto text-center font-medium">{children}</h2>
    ),
}

const subHeaderRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => (
        <p className="font-bold text-xsm tracking-wider text-center">{children}</p>
    ),
}

const paragraphRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => (
        <p className="font-medium leading-140% text-center lg:text-left">{children}</p>
    ),
}

const lgBreakPoint = screens.lg

export const GenericBadgeAndParagraphSection = ({ section }: IProps): React.ReactElement => {
    if (!section) {
        return null
    }

    const { header, paragraphCopy, richSubHeader, mobileMediaCollection, mediaCollection } = section

    const mobileImage = mobileMediaCollection?.items?.[0]
    const desktopImage = mediaCollection?.items?.[0]

    return (
        <section className="section--v2">
            {richSubHeader?.json && (
                <ContentfulRichText content={richSubHeader.json} renderNode={subHeaderRenderNode} />
            )}

            {header?.json && (
                <ContentfulRichText content={header.json} renderNode={headerRenderNode} className="mt-5" />
            )}

            {/* badge + paragraph */}
            <div className="mt-6 lg:mt-17.5 flex flex-col lg:flex-row items-center max-w-lg mx-auto">
                {/* badge */}
                <picture className="flex-shrink-0">
                    {desktopImage?.url && (
                        <>
                            {desktopImage.url.endsWith('.svg') && (
                                <source
                                    type="image/svg+xml"
                                    srcSet={desktopImage.url}
                                    media={`(min-width: ${lgBreakPoint})`}
                                />
                            )}

                            <source
                                type="image/webp"
                                srcSet={desktopImage.url + '?fm=webp&w=400'}
                                media={`(min-width: ${lgBreakPoint})`}
                            />
                            <source
                                type="image/webp"
                                srcSet={desktopImage.url + '?fm=webp&w=400'}
                                media={`(min-width: ${lgBreakPoint})`}
                            />
                        </>
                    )}

                    {mobileImage?.url && (
                        <>
                            {mobileImage.url.endsWith('.svg') && (
                                <source type="image/svg+xml" srcSet={mobileImage.url} />
                            )}

                            <source
                                type="image/webp"
                                srcSet={mobileImage.url + '?fm=webp&w=400'}
                                media={`(min-width: ${lgBreakPoint})`}
                            />
                            <source type="image/webp" srcSet={mobileImage.url + '?fm=webp&w=400'} />
                        </>
                    )}

                    <img
                        src={(mobileImage?.url || desktopImage?.url) + '?fm=jpg&w=400'}
                        alt={
                            mobileImage?.description ||
                            desktopImage?.description ||
                            mobileImage?.title ||
                            desktopImage?.title
                        }
                        height={mobileImage?.height || desktopImage?.height}
                        width={mobileImage?.width || desktopImage?.width}
                        loading="lazy"
                    />
                </picture>

                {/* paragraph */}
                {paragraphCopy?.json && (
                    <ContentfulRichText
                        content={paragraphCopy.json}
                        renderNode={paragraphRenderNode}
                        className="mt-5 lg:mt-0 lg:ml-15"
                    />
                )}
            </div>
        </section>
    )
}
