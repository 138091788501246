/* eslint-disable react/display-name */
import * as React from 'react'
import { IGenericPageSection } from 'interfaces/generic-page-section'
import twConfig from 'tailwind-light-config'
const { screens } = twConfig
import ContentfulRichText from 'components/rich-text'
import { BLOCKS } from '@contentful/rich-text-types'

interface IProps {
    section: IGenericPageSection
}

const lgBreakPoint = screens.lg

const headerRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => (
        <p className="text-white font-medium text-1xl lg:text-3.25xl leading-120% tracking-tight">{children}</p>
    ),
}

const subHeaderRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => (
        <p className="text-white font-bold text-xsm leading-120% tracking-wider">{children}</p>
    ),
}

export const IconLeftTextRightWithBackgroundImage = ({ section }: IProps): React.ReactElement => {
    if (!section) {
        return null
    }

    const { mediaCollection, mobileMediaCollection, header, richSubHeader } = section

    const iconDesktop = mediaCollection?.items?.[0]
    const iconMobile = mobileMediaCollection?.items?.[0]

    const backgroundImageDesktop = mediaCollection?.items?.[1]
    const backgroundImageMobile = mobileMediaCollection?.items?.[1]

    return (
        <section className="section p-0 grid grid-cols-1 grid-rows-1 relative">
            {/* background image */}
            {(backgroundImageDesktop?.url || backgroundImageMobile?.url) && (
                <div className="relative col-start-1 row-start-1">
                    <picture>
                        {/* desktop */}
                        {backgroundImageDesktop?.url && (
                            <>
                                <source
                                    type="image/webp"
                                    media={`(min-width: ${lgBreakPoint})`}
                                    srcSet={`
                                    ${backgroundImageDesktop.url}?fm=webp&w=1024 1024w,
                                    ${backgroundImageDesktop.url}?fm=webp&w=1280 1280w,
                                    ${backgroundImageDesktop.url}?fm=webp&w=1600 1600w,
                                    ${backgroundImageDesktop.url}?fm=webp&w=1900 1900w
                                `}
                                />

                                <source
                                    type="image/jpeg"
                                    media={`(min-width: ${lgBreakPoint})`}
                                    srcSet={`
                                    ${backgroundImageDesktop.url}?fm=jpg&w=1024 1024w,
                                    ${backgroundImageDesktop.url}?fm=jpg&w=1280 1280w,
                                    ${backgroundImageDesktop.url}?fm=jpg&w=1600 1600w,
                                    ${backgroundImageDesktop.url}?fm=jpg&w=1900 1900w
                                `}
                                />
                            </>
                        )}

                        {/* mobile */}
                        {backgroundImageMobile?.url && (
                            <>
                                <source
                                    type="image/webp"
                                    srcSet={`
                                    ${backgroundImageMobile.url}?fm=webp&w=320 320w,
                                    ${backgroundImageMobile.url}?fm=webp&w=640 640w,
                                    ${backgroundImageMobile.url}?fm=webp&w=820 820w,
                                    ${backgroundImageMobile.url}?fm=webp&w=1024 1024w
                                `}
                                />

                                <source
                                    type="image/jpeg"
                                    srcSet={`
                                    ${backgroundImageMobile.url}?fm=jpg&w=320 320w,
                                    ${backgroundImageMobile.url}?fm=jpg&w=640 640w,
                                    ${backgroundImageMobile.url}?fm=jpg&w=820 820w,
                                    ${backgroundImageMobile.url}?fm=jpg&w=1024 1024w
                                `}
                                />
                            </>
                        )}

                        <img
                            src={(backgroundImageMobile?.url || backgroundImageDesktop?.url) + '?fm=jpg&w=1024'}
                            alt={
                                backgroundImageMobile?.title ||
                                backgroundImageDesktop?.title ||
                                backgroundImageMobile?.description ||
                                backgroundImageDesktop?.description
                            }
                            height={backgroundImageMobile?.height || backgroundImageDesktop?.height}
                            width={backgroundImageMobile?.width || backgroundImageDesktop?.width}
                            className="max-h-[var(--screenHeightDeductingHeader)] h-full w-full object-cover"
                            loading="lazy"
                        />
                    </picture>

                    {/* background overlay */}
                    <div className="absolute left-0 top-0 bg-gray lg:bg-gray-darker w-full h-full mix-blend-multiply" />
                </div>
            )}

            <div className="col-start-1 row-start-1 relative px-5 lg:px-15 section--bigger-padding flex flex-col lg:flex-row lg:items-center">
                {/* icon */}
                <picture className="lg:flex-basis-half">
                    {/* desktop */}
                    {iconDesktop?.url && (
                        <>
                            {iconDesktop.url.endsWith('.svg') && (
                                <source
                                    type="image/svg+xml"
                                    srcSet={iconDesktop.url}
                                    media={`(min-width: ${lgBreakPoint})`}
                                />
                            )}

                            <source
                                type="image/webp"
                                media={`(min-width: ${lgBreakPoint})`}
                                srcSet={`
                                    ${iconDesktop.url}?fm=webp&w=220 220w,
                                    ${iconDesktop.url}?fm=webp&w=440 440w,
                                    ${iconDesktop.url}?fm=webp&w=550 550w
                                `}
                                sizes="220px"
                            />

                            <source
                                type="image/jpeg"
                                media={`(min-width: ${lgBreakPoint})`}
                                srcSet={`
                                    ${iconDesktop.url}?fm=jpg&w=220 220w,
                                    ${iconDesktop.url}?fm=jpg&w=440 440w,
                                    ${iconDesktop.url}?fm=jpg&w=550 550w
                                `}
                                sizes="220px"
                            />
                        </>
                    )}

                    {/* mobile */}
                    {iconMobile?.url && (
                        <>
                            {iconMobile.url.endsWith('.svg') && <source type="image/svg+xml" srcSet={iconMobile.url} />}

                            <source
                                type="image/webp"
                                srcSet={`
                                    ${iconMobile.url}?fm=webp&w=320 320w,
                                    ${iconMobile.url}?fm=webp&w=640 640w,
                                    ${iconMobile.url}?fm=webp&w=820 820w,
                                    ${iconMobile.url}?fm=webp&w=1024 1024w
                                `}
                                sizes="110px"
                            />

                            <source
                                type="image/jpeg"
                                srcSet={`
                                    ${iconMobile.url}?fm=jpg&w=320 320w,
                                    ${iconMobile.url}?fm=jpg&w=640 640w,
                                    ${iconMobile.url}?fm=jpg&w=820 820w,
                                    ${iconMobile.url}?fm=jpg&w=1024 1024w
                                `}
                                sizes="110px"
                            />
                        </>
                    )}

                    <img
                        src={(iconMobile?.url || iconDesktop?.url) + '?fm=jpg&w=1024'}
                        alt={
                            iconMobile?.title ||
                            iconDesktop?.title ||
                            iconMobile?.description ||
                            iconDesktop?.description
                        }
                        height={iconMobile?.height || iconDesktop?.height}
                        width={iconMobile?.width || iconDesktop?.width}
                        className="w-[110px] lg:w-[220px] mx-auto"
                        loading="lazy"
                    />
                </picture>

                {/* content */}
                <div className="mt-15 lg:mt-0 lg:flex-basis-half lg:pl-3">
                    {
                        // subheader is rendered above header
                        richSubHeader?.json && (
                            <ContentfulRichText
                                content={richSubHeader.json}
                                className="subgeader text-white"
                                renderNode={subHeaderRenderNode}
                            />
                        )
                    }

                    {header?.json && (
                        <ContentfulRichText
                            content={header.json}
                            className="header text-white mt-7"
                            renderNode={headerRenderNode}
                        />
                    )}
                </div>
            </div>

            {/* vertical line at the bottom of section */}
            <div className="w-px h-7.5 lg:h-32 absolute left-1/2 -translate-x-1/2 bottom-0 bg-white" />
        </section>
    )
}
