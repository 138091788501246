/* eslint-disable react/display-name */
import * as React from 'react'
import ContentfulRichText from 'components/rich-text'
import { IPageSectionTestimonials } from 'interfaces/page-section-testimonials'
import { BLOCKS } from '@contentful/rich-text-types'
import styles from './style.module.css'
import twConfig from 'tailwind-light-config'
const { screens } = twConfig
import Link from 'next/link'

interface IProps {
    section: IPageSectionTestimonials
}

const headerRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => (
        <p className="text-3xl lg:text-6xl leading-110% tracking-tight">{children}</p>
    ),
}

const xsBreakPoint = screens.xs
const smBreakPoint = screens.sm
const lgBreakPoint = screens.lg

export const FeaturedReviewSquareSection = ({ section }: IProps): React.ReactElement => {
    if (!section) {
        return null
    }

    const { header, featuredReviewsCollection, secondaryCtaLink } = section
    const featuredReviews = featuredReviewsCollection?.items ?? []

    const headerRenderNodeWithLink = {
        [BLOCKS.PARAGRAPH]: (_node, children) => (
            <p className="text-3xl lg:text-6xl leading-110% tracking-tight">
                {React.Children.map(children, (child, index) => {
                    if (typeof child === 'string') {
                        return child.split(' ').map((word) => {
                            if (word.startsWith('{')) {
                                return (
                                    <Link key={index} href={secondaryCtaLink.href}>
                                        {word.replace('{', '').replace('}', '')}
                                    </Link>
                                )
                            }

                            return word + ' '
                        })
                    }

                    return child
                })}
            </p>
        ),
    }

    return (
        <section className="section bg-white py-15 lg:py-20">
            {header?.json && (
                <ContentfulRichText
                    className="text-center"
                    content={header.json}
                    renderNode={secondaryCtaLink?.href ? headerRenderNodeWithLink : headerRenderNode}
                />
            )}

            {featuredReviews.length > 0 && (
                <div className="-mx-5 lg:-mx-15">
                    <div
                        className={`mt-10 lg:mt-15 px-5 lg:px-15 flex space-x-4 lg:space-x-5 overflow-auto ${styles['scroll-container']}`}
                    >
                        {featuredReviews.map((review, index) => {
                            if (!review) {
                                return null
                            }

                            const { authorPhoto, authorHandleLink, sys } = review

                            return (
                                <a
                                    href={authorHandleLink}
                                    key={sys?.id || index}
                                    className="rounded-2lg overflow-hidden block w-52 lg:w-72 shrink-0"
                                >
                                    <picture>
                                        {/* webp */}
                                        <source
                                            type="image/webp"
                                            srcSet={`
                                            ${authorPhoto}?fm=webp&w=375 375w,
                                            ${authorPhoto}?fm=webp&w=640 640w
                                        `}
                                            sizes={`(min-width: ${lgBreakPoint}) 20vw, (min-width: ${smBreakPoint}) 30vw, (min-width: ${xsBreakPoint}) 50vw, 75vw`}
                                        />

                                        <source
                                            type="image/jpeg"
                                            srcSet={`
                                            ${authorPhoto}?fm=jpg&w=375 375w,
                                            ${authorPhoto}?fm=jpg&w=640 640w
                                        `}
                                            sizes={`(min-width: ${lgBreakPoint}) 20vw, (min-width: ${smBreakPoint}) 30vw, (min-width: ${xsBreakPoint}) 50vw, 75vw`}
                                        />

                                        <img
                                            src={authorPhoto?.url + '?fm=jpg&w=640'}
                                            alt={authorPhoto?.description || authorPhoto?.title}
                                            height={authorPhoto?.height}
                                            width={authorPhoto?.width}
                                            loading="lazy"
                                        />
                                    </picture>
                                </a>
                            )
                        })}
                    </div>
                </div>
            )}
        </section>
    )
}
