import * as React from 'react'
import { IPageSectionComparisonTable } from 'interfaces/page-section-comparison-table'
import ContentfulRichText from 'components/rich-text'
import { BLOCKS } from '@contentful/rich-text-types'
import { IImage } from 'interfaces/media'
import styles from './style.module.css'
import twConfig from 'tailwind-light-config'
const { screens } = twConfig

interface IProps {
    section: IPageSectionComparisonTable
}

const lgBreakPoint = Number(screens.lg.replace('px', ''))

const headerRenderNode = {
    // eslint-disable-next-line react/display-name
    [BLOCKS.PARAGRAPH]: (_node, children) => <p className="section--header text-3.5xl lg:text-6.25xl">{children}</p>,
}

const Icon = ({
    icon,
    className = '',
    mobileIcon,
}: {
    icon: IImage
    className?: string
    mobileIcon?: IImage
}): React.ReactElement => {
    if (!icon && !mobileIcon) {
        return null
    }

    return (
        <picture>
            {/* mobile icon */}
            {mobileIcon && (
                <>
                    {/* svg */}
                    <source media={`(max-width: ${lgBreakPoint - 1}px)`} type="image/svg+xml" srcSet={mobileIcon.url} />
                    {/* webp */}
                    <source
                        media={`(max-width: ${lgBreakPoint - 1}px)`}
                        type="image/webp"
                        srcSet={mobileIcon.url + '?fm=webp&w=200'}
                    />
                    {/* svg */}
                    <source
                        media={`(max-width: ${lgBreakPoint - 1}px)`}
                        type="image/jpeg"
                        srcSet={mobileIcon.url + '?fm=jpg&w=200'}
                    />
                </>
            )}

            {/* svg */}
            <source type="image/svg+xml" srcSet={icon.url} />
            {/* webp */}
            <source type="image/webp" srcSet={icon.url + '?fm=webp&w=200'} />
            {/* svg */}
            <source type="image/jpeg" srcSet={icon.url + '?fm=jpg&w=200'} />

            <img
                src={icon.url + '?fm=jpg&w=200'}
                height={icon.height}
                width={icon.width}
                alt={icon.description || icon.title}
                className={className}
                loading="lazy"
            />
        </picture>
    )
}

export const ComparisonTableSection = ({ section }: IProps): React.ReactElement => {
    if (!section) {
        return null
    }

    const {
        richTitle,
        comparisons,
        cabinetColumnHeaderCollection,
        othersColumnHeader,
        trueIcon,
        falseIcon,
        mediaCollectionCollection,
        alignImage,
    } = section

    // cabinet column header image
    const desktopCabinetColumnHeaderImage = cabinetColumnHeaderCollection?.items?.[0]
    const mobileCabinetColumnHeaderImage = cabinetColumnHeaderCollection?.items?.[1]

    const comparisonItems = comparisons?.items || []

    // first image is section image
    const sectionImage = mediaCollectionCollection?.items?.[0]
    const sectionImageMobile = sectionImage?.mobileImagesCollection?.items?.[0]
    const sectionImageDesktop = sectionImage?.desktopImagesCollection?.items?.[0]

    // second image is icon image
    const iconImage = mediaCollectionCollection?.items?.[1]
    const iconImageMobile = iconImage?.mobileImagesCollection?.items?.[0]
    const iconImageDesktop = iconImage?.desktopImagesCollection?.items?.[0]

    const isSectionImageDefined = sectionImageMobile || sectionImageDesktop

    return (
        <section className={`bg-blue-rx ${styles['comparison-table-section-grid']}`}>
            <div
                className={`
                    section py-10 lg:py-15 order-2
                    ${alignImage === 'left' ? '' : 'lg:order-1'}
                    ${styles['comparison-table-grid']}
                    ${isSectionImageDefined ? styles['comparison-table-grid-single-column'] : ''}`}
            >
                <div>{richTitle && <ContentfulRichText content={richTitle.json} renderNode={headerRenderNode} />}</div>

                {/* comparisons */}
                {comparisonItems.length > 0 && (
                    <table className={`w-full ${isSectionImageDefined ? '' : 'lg:mt-8'}`}>
                        <thead>
                            <tr className="border-b">
                                <th className="pb-5" />
                                <th className="w-20 sm:w-40 pb-5">
                                    <Icon
                                        icon={desktopCabinetColumnHeaderImage}
                                        mobileIcon={mobileCabinetColumnHeaderImage}
                                        className={`${
                                            mobileCabinetColumnHeaderImage?.url ? 'h-6' : 'h-3'
                                        } lg:h-4 w-auto mx-auto mt-1`}
                                    />
                                </th>
                                <th className="w-12 sm:w-40 pt-2 pb-5 pl-2.5 text-xsm lg:text-base font-bold">
                                    {othersColumnHeader}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {comparisonItems.map((comparison) => {
                                const isCabinetTrue = comparison.cabinet
                                const areOthersTrue = comparison.others

                                return (
                                    <tr key={comparison.key} className="border-b">
                                        <td className="py-3 lg:text-xl">{comparison.key}</td>
                                        <td>
                                            <Icon
                                                icon={isCabinetTrue ? trueIcon : falseIcon}
                                                className={`mx-auto w-auto ${
                                                    // define different height and position for
                                                    // true and false icons because the icons
                                                    // we're using currently are slightly off
                                                    isCabinetTrue
                                                        ? 'h-6 lg:h-8'
                                                        : 'h-5 lg:h-7 mt-1 relative -left-0.5 lg:-left-1'
                                                }`}
                                            />
                                        </td>
                                        <td className="pl-2.5">
                                            <Icon
                                                icon={areOthersTrue ? trueIcon : falseIcon}
                                                className={`mx-auto w-auto ${
                                                    areOthersTrue
                                                        ? 'h-6 lg:h-8'
                                                        : 'h-5 lg:h-7 mt-1 relative -left-0.5 lg:-left-1'
                                                }`}
                                            />
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                )}
            </div>

            {/* section image */}
            {isSectionImageDefined && (
                <div className={`relative order-1 ${alignImage === 'left' ? '' : 'lg:order-2'}`}>
                    <picture>
                        {/* desktop image */}
                        {sectionImageDesktop && (
                            <>
                                {/* desktop - webp */}
                                <source
                                    media={`(min-width: ${lgBreakPoint}px)`}
                                    type="image/webp"
                                    srcSet={`
                                        ${sectionImageDesktop.url}?fm=webp&w=1024 1024w,
                                        ${sectionImageDesktop.url}?fm=webp&w=1240 1240w,
                                        ${sectionImageDesktop.url}?fm=webp&w=1600 1600w
                                    `}
                                    sizes={`(min-width: ${lgBreakPoint}px) 50vw, 100vw`}
                                />
                                {/* desktop - jpeg */}
                                <source
                                    media={`(min-width: ${lgBreakPoint}px)`}
                                    type="image/jpeg"
                                    srcSet={`
                                        ${sectionImageDesktop.url}?fm=jpg&w=1024 1024w,
                                        ${sectionImageDesktop.url}?fm=jpg&w=1240 1240w,
                                        ${sectionImageDesktop.url}?fm=jpg&w=1600 1600w
                                    `}
                                    sizes={`(min-width: ${lgBreakPoint}px) 50vw, 100vw`}
                                />
                            </>
                        )}

                        {/* mobile image */}
                        {sectionImageMobile && (
                            <>
                                {/* mobile - webp */}
                                <source
                                    type="image/webp"
                                    srcSet={`
                                        ${sectionImageMobile.url}?fm=webp&w=360 360w,
                                        ${sectionImageMobile.url}?fm=webp&w=420 420w,
                                        ${sectionImageMobile.url}?fm=webp&w=640 640w,
                                        ${sectionImageMobile.url}?fm=webp&w=1024 1024w,
                                        ${sectionImageMobile.url}?fm=webp&w=1280 1280w
                                    `}
                                    sizes={`(min-width: ${lgBreakPoint}px) 50vw, 100vw`}
                                />
                                {/* mobile - jpeg */}
                                <source
                                    type="image/jpeg"
                                    srcSet={`
                                        ${sectionImageMobile.url}?fm=jpg&w=360 360w,
                                        ${sectionImageMobile.url}?fm=jpg&w=420 420w,
                                        ${sectionImageMobile.url}?fm=jpg&w=640 640w,
                                        ${sectionImageMobile.url}?fm=jpg&w=1024 1024w,
                                        ${sectionImageMobile.url}?fm=jpg&w=1280 1280w
                                    `}
                                    sizes={`(min-width: ${lgBreakPoint}px) 50vw, 100vw`}
                                />
                            </>
                        )}

                        <img
                            src={(sectionImageMobile?.url || sectionImageDesktop?.url) + '?fm=jpg&w=1024'}
                            alt={sectionImage.description}
                            width={sectionImageMobile?.width || sectionImageDesktop?.width}
                            height={sectionImageMobile?.height || sectionImageDesktop?.height}
                            className="h-full w-full object-cover"
                            loading="lazy"
                        />
                    </picture>

                    {/* icon */}
                    {(iconImageMobile || iconImageDesktop) && (
                        <picture>
                            {/* desktop oimage */}
                            {iconImageDesktop && (
                                <>
                                    {/* desktop - webp */}
                                    <source
                                        media={`(min-width: ${lgBreakPoint}px)`}
                                        type="image/webp"
                                        srcSet={`
                                        ${iconImageDesktop.url}?fm=webp&w=400 400w
                                    `}
                                    />
                                    {/* desktop - jpeg */}
                                    <source
                                        media={`(min-width: ${lgBreakPoint}px)`}
                                        type="image/jpeg"
                                        srcSet={`
                                        ${iconImageDesktop.url}?fm=jpg&w=400 400w
                                    `}
                                    />
                                </>
                            )}

                            {/* mobile image */}
                            {iconImageMobile && (
                                <>
                                    {/* mobile - webp */}
                                    <source
                                        type="image/webp"
                                        srcSet={`
                                        ${iconImageMobile.url}?fm=webp&w=300 300w
                                    `}
                                    />
                                    {/* mobile - jpeg */}
                                    <source
                                        type="image/jpeg"
                                        srcSet={`
                                        ${iconImageMobile.url}?fm=jpg&w=300 300w,
                                    `}
                                    />
                                </>
                            )}

                            <img
                                src={(iconImageMobile?.url || iconImageDesktop?.url) + '?fm=jpg&w=400'}
                                alt={iconImage.description}
                                width={iconImageMobile?.width || iconImageDesktop?.width}
                                height={iconImageMobile?.height || iconImageDesktop?.height}
                                className="h-20 w-20 lg:h-40 lg:w-40 absolute right-9 top-9 lg:right-15 lg:top-15"
                                loading="lazy"
                            />
                        </picture>
                    )}
                </div>
            )}
        </section>
    )
}
