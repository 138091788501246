import * as React from 'react'
import { IAuthor } from 'interfaces/author'
import CabinetTokenMask from 'components/cabinet-token/cabinet-token'
import styles from './style.module.css'
import globalManifest from 'data/global-manifest.json'
import CarouselWithSlider from 'components/carousel-with-slider/carousel-with-slider'
import Link from 'next/link'
import { getShortName } from 'lib/util/misc'
import { IUiResources } from 'interfaces/ui-resource'

interface IProps {
    authorsCollection: {
        items: IAuthor[]
    }
    uiResources: IUiResources
}

const { resources: globalUIResources } = globalManifest

const TestimonialCarousel = ({ authorsCollection, uiResources }: IProps): React.ReactElement => {
    const items = authorsCollection?.items

    return (
        <CarouselWithSlider
            itemWidth={270} // 250 + 20 margin on every slide
            rangeSliderLabel={globalUIResources?.carouselSliderControlLabel?.value}
            rangeClassName="lg:w-2/3"
            snap={false} // carousel scroll breaks with snap and more than 1 slidesToShow
        >
            {/* testimonial */}
            {items?.map(({ title, name, sys: { id }, picture, homepageQuote, slug }) => (
                <div key={id} className="ml-5 first:ml-0">
                    <div className={`relative ${styles['flip-card']}`}>
                        <div className={styles['flip-card-inner']}>
                            <div className={`${styles['flip-card-front']}`}>
                                <CabinetTokenMask wrapperClassName="h-full">
                                    <picture>
                                        {/* large screens */}
                                        {/* webp */}
                                        <source
                                            type="image/webp"
                                            srcSet={`
                                                    ${picture?.url}?w=420&fm=webp&q=75 420w,
                                                    ${picture?.url}?w=640&fm=webp&q=75 640w,
                                                    ${picture?.url}?w=1024&fm=webp&q=75 1024w,
                                                    ${picture?.url}?w=1440&fm=webp&q=75 1440w,
                                                    ${picture?.url}?w=1600&fm=webp&q=75 1600w
                                                `}
                                            sizes="(min-width: 1280px) 20vw, (min-width:1024px) 25vw, (min-width: 540px) 40vw, 75vw"
                                        />
                                        {/* webp not supported */}
                                        <source
                                            type="image/jpeg"
                                            srcSet={`
                                                    ${picture?.url}?w=420&fm=jpg&q=75 420w,
                                                    ${picture?.url}?w=640&fm=jpg&q=75 640w,
                                                    ${picture?.url}?w=1024&fm=jpg&q=75 1024w,
                                                    ${picture?.url}?w=1440&fm=jpg&q=75 1440w,
                                                    ${picture?.url}?w=1600&fm=jpg&q=75 1600w
                                                `}
                                            sizes="(min-width: 1280px) 20vw, (min-width:1024px) 25vw, (min-width: 540px) 40vw, 75vw"
                                        />
                                        <img
                                            className="h-full object-cover"
                                            srcSet={`
                                                    ${picture?.url}?w=420&fm=jpg&q=75 420w,
                                                    ${picture?.url}?w=640&fm=jpg&q=75 640w,
                                                `}
                                            // sizes="(min-width: 1280px) 20vw, (min-width:1024px) 25vw, (min-width: 640px) 33vw, 75vw"
                                            src={picture?.url}
                                            alt={picture?.title}
                                            width={picture?.width}
                                            height={picture?.height}
                                            loading="lazy"
                                        />
                                    </picture>
                                    {/* overlay */}
                                    <div className="absolute top-0 left-0 w-full h-full bg-black/20" />
                                    <div className="w-full px-2 absolute bottom-4 left-1/2 -translate-x-1/2 flex flex-col items-center">
                                        <span className="text-white text-center">{name}</span>
                                        <span className="text-white text-center">{title}</span>
                                    </div>
                                </CabinetTokenMask>
                            </div>
                            <div className={`${styles['flip-card-back']} h-full`}>
                                <CabinetTokenMask wrapperClassName="h-full">
                                    <figure className="bg-white py-10 px-7 overflow-y-auto h-full">
                                        <div className="flex flex-col-reverse justify-end">
                                            <blockquote className="text-sm">
                                                <hr className="bg-gray my-1.5" />
                                                <span className="text-sm">{homepageQuote}</span>
                                            </blockquote>
                                            <figcaption className="text-lg font-bold not-italic">{name}</figcaption>
                                        </div>
                                        {slug && (
                                            <Link
                                                href={'/authors/' + encodeURIComponent(slug)}
                                                className="underline text-sm font-bold mt-4 block leading-130%"
                                            >
                                                {uiResources?.['readMoreAboutLabel']?.value} {getShortName(name)}
                                            </Link>
                                        )}
                                    </figure>
                                </CabinetTokenMask>
                            </div>
                        </div>
                    </div>
                    {/* </CabinetTokenMask> */}
                </div>
            ))}
        </CarouselWithSlider>
    )
}

export default TestimonialCarousel
