import * as React from 'react'
import { IPageSectionComparisonTable } from 'interfaces/page-section-comparison-table'
import ContentfulRichText from 'components/rich-text'
import { BLOCKS } from '@contentful/rich-text-types'
import { IImage } from 'interfaces/media'
import twConfig from 'tailwind-light-config'
const { screens } = twConfig

interface IProps {
    section: IPageSectionComparisonTable
}

const lgBreakPoint = Number(screens.lg.replace('px', ''))

const headerRenderNode = {
    // eslint-disable-next-line react/display-name
    [BLOCKS.PARAGRAPH]: (_node, children) => (
        <h2 className="section--header text-1xl lg:text-6xl leading-120% tracking-tight">{children}</h2>
    ),
}

const Icon = ({
    icon,
    className = '',
    mobileIcon,
}: {
    icon: IImage
    className?: string
    mobileIcon?: IImage
}): React.ReactElement => {
    if (!icon && !mobileIcon) {
        return null
    }

    return (
        <picture>
            {/* mobile icon */}
            {mobileIcon && (
                <>
                    {/* svg */}
                    <source media={`(max-width: ${lgBreakPoint - 1}px)`} type="image/svg+xml" srcSet={mobileIcon.url} />
                    {/* webp */}
                    <source
                        media={`(max-width: ${lgBreakPoint - 1}px)`}
                        type="image/webp"
                        srcSet={mobileIcon.url + '?fm=webp&w=200'}
                    />
                    {/* svg */}
                    <source
                        media={`(max-width: ${lgBreakPoint - 1}px)`}
                        type="image/jpeg"
                        srcSet={mobileIcon.url + '?fm=jpg&w=200'}
                    />
                </>
            )}

            {/* svg */}
            <source type="image/svg+xml" srcSet={icon.url} />
            {/* webp */}
            <source type="image/webp" srcSet={icon.url + '?fm=webp&w=200'} />
            {/* svg */}
            <source type="image/jpeg" srcSet={icon.url + '?fm=jpg&w=200'} />

            <img
                src={icon.url + '?fm=jpg&w=200'}
                height={icon.height}
                width={icon.width}
                alt={icon.description || icon.title}
                className={className}
                loading="lazy"
            />
        </picture>
    )
}

export const ComparisonTableColumnsSection = ({ section }: IProps): React.ReactElement => {
    if (!section) {
        return null
    }

    const { richTitle, comparisons, trueIcon, falseIcon, mediaCollectionCollection } = section

    const comparisonItems = comparisons?.items || []

    // first image is section image
    const sectionImage = mediaCollectionCollection.items[0]
    const sectionImageMobile = sectionImage.mobileImagesCollection.items[0]
    const sectionImageDesktop = sectionImage.desktopImagesCollection.items[0]

    // second image is used below the title
    const titleDecoration = mediaCollectionCollection.items[1]
    const titleDecorationMobile = titleDecoration?.mobileImagesCollection?.items?.[0]
    const titleDecorationDesktop = titleDecoration?.desktopImagesCollection?.items?.[0]

    const isSectionImageDefined = sectionImageMobile || sectionImageDesktop

    return (
        <section className="section--v2">
            <div className="flex flex-col">
                <div className="mx-auto">
                    {richTitle && (
                        <ContentfulRichText
                            content={richTitle.json}
                            renderNode={headerRenderNode}
                            className="text-center"
                            ignoreMarkdownStyles
                        />
                    )}

                    {/* title decoration */}
                    {(titleDecorationMobile || titleDecorationDesktop) && (
                        <picture>
                            {titleDecorationDesktop && (
                                <>
                                    {/* svg */}
                                    <source
                                        media={`(min-width: ${lgBreakPoint}px)`}
                                        type="image/svg+xml"
                                        srcSet={titleDecorationDesktop.url}
                                    />
                                    {/* webp */}
                                    <source
                                        media={`(min-width: ${lgBreakPoint}px)`}
                                        type="image/webp"
                                        srcSet={titleDecorationDesktop.url + '?fm=webp&w=200'}
                                    />
                                    {/* svg */}
                                    <source
                                        media={`(min-width: ${lgBreakPoint}px)`}
                                        type="image/jpeg"
                                        srcSet={titleDecorationDesktop.url + '?fm=jpg&w=200'}
                                    />
                                </>
                            )}

                            {titleDecorationMobile && (
                                <>
                                    {/* svg */}
                                    <source type="image/svg+xml" srcSet={titleDecorationMobile.url} />
                                    {/* webp */}
                                    <source type="image/webp" srcSet={titleDecorationMobile.url + '?fm=webp&w=200'} />
                                    {/* svg */}
                                    <source type="image/jpeg" srcSet={titleDecorationMobile.url + '?fm=jpg&w=200'} />
                                </>
                            )}

                            <img
                                src={(titleDecorationMobile.url || titleDecorationDesktop.url) + '?fm=jpg&w=200'}
                                aria-hidden
                                loading="lazy"
                                alt=""
                            />
                        </picture>
                    )}
                </div>

                <div className="grid grid-cols-1 lg:grid-cols-3 gap-x-5 mt-2.5 lg:mt-5 max-w-7xl mx-auto">
                    {/* comparisons mobile */}
                    <table className="lg:hidden mx-auto border-separate border-spacing-7.5">
                        <tbody>
                            {comparisonItems.map((comparison) => {
                                const isCabinetTrue = comparison.cabinet
                                const areOthersTrue = comparison.others

                                return (
                                    <tr key={comparison.key}>
                                        <td className="text-xbase text-right sm:text-left leading-140% font-medium">
                                            {comparison.key}
                                        </td>
                                        <td className="w-7 h-7">
                                            <Icon icon={isCabinetTrue ? trueIcon : falseIcon} />
                                        </td>
                                        <td className="w-7 h-7">
                                            <Icon icon={areOthersTrue ? trueIcon : falseIcon} />
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>

                    {/* comparisons desktop */}
                    {/* cabinet column */}
                    <table className="hidden lg:table border-separate border-spacing-x-9 border-spacing-y-8">
                        {comparisonItems.map((comparison) => {
                            return (
                                <tr key={comparison.key}>
                                    <td className="leading-140% font-medium">{comparison.key}</td>
                                    <td className="h-7 w-7">
                                        <Icon
                                            icon={comparison.cabinet ? trueIcon : falseIcon}
                                            mobileIcon={comparison.cabinet ? trueIcon : falseIcon}
                                        />
                                    </td>
                                </tr>
                            )
                        })}
                    </table>

                    {/* others column */}
                    <table className="hidden lg:table lg:order-3 border-separate border-spacing-x-9 border-spacing-y-8">
                        {comparisonItems.map((comparison) => {
                            return (
                                <tr key={comparison.key}>
                                    <td className="h-7 w-7">
                                        <Icon
                                            icon={comparison.others ? trueIcon : falseIcon}
                                            mobileIcon={comparison.others ? trueIcon : falseIcon}
                                        />
                                    </td>
                                    <td className="leading-140% font-medium">{comparison.key}</td>
                                </tr>
                            )
                        })}
                    </table>

                    {/* section image */}
                    {isSectionImageDefined && (
                        <picture className="lg:order-2 self-center">
                            {/* desktop image */}
                            {sectionImageDesktop && (
                                <>
                                    {/* desktop - webp */}
                                    <source
                                        media={`(min-width: ${lgBreakPoint}px)`}
                                        type="image/webp"
                                        srcSet={`
                                        ${sectionImageDesktop.url}?fm=webp&w=1024 1024w,
                                        ${sectionImageDesktop.url}?fm=webp&w=1240 1240w,
                                        ${sectionImageDesktop.url}?fm=webp&w=1600 1600w
                                    `}
                                        sizes={`(min-width: ${lgBreakPoint}px) 50vw, 100vw`}
                                    />
                                    {/* desktop - jpeg */}
                                    <source
                                        media={`(min-width: ${lgBreakPoint}px)`}
                                        type="image/jpeg"
                                        srcSet={`
                                        ${sectionImageDesktop.url}?fm=jpg&w=1024 1024w,
                                        ${sectionImageDesktop.url}?fm=jpg&w=1240 1240w,
                                        ${sectionImageDesktop.url}?fm=jpg&w=1600 1600w
                                    `}
                                        sizes={`(min-width: ${lgBreakPoint}px) 50vw, 100vw`}
                                    />
                                </>
                            )}

                            {/* mobile image */}
                            {sectionImageMobile && (
                                <>
                                    {/* mobile - webp */}
                                    <source
                                        type="image/webp"
                                        srcSet={`
                                        ${sectionImageMobile.url}?fm=webp&w=360 360w,
                                        ${sectionImageMobile.url}?fm=webp&w=420 420w,
                                        ${sectionImageMobile.url}?fm=webp&w=640 640w,
                                        ${sectionImageMobile.url}?fm=webp&w=1024 1024w,
                                        ${sectionImageMobile.url}?fm=webp&w=1280 1280w
                                    `}
                                        sizes={`(min-width: ${lgBreakPoint}px) 50vw, 100vw`}
                                    />
                                    {/* mobile - jpeg */}
                                    <source
                                        type="image/jpeg"
                                        srcSet={`
                                        ${sectionImageMobile.url}?fm=jpg&w=360 360w,
                                        ${sectionImageMobile.url}?fm=jpg&w=420 420w,
                                        ${sectionImageMobile.url}?fm=jpg&w=640 640w,
                                        ${sectionImageMobile.url}?fm=jpg&w=1024 1024w,
                                        ${sectionImageMobile.url}?fm=jpg&w=1280 1280w
                                    `}
                                        sizes={`(min-width: ${lgBreakPoint}px) 50vw, 100vw`}
                                    />
                                </>
                            )}

                            <img
                                src={(sectionImageMobile?.url || sectionImageDesktop?.url) + '?fm=jpg&w=1024'}
                                alt={sectionImage.description}
                                width={sectionImageMobile?.width || sectionImageDesktop?.width}
                                height={sectionImageMobile?.height || sectionImageDesktop?.height}
                                className="h-full w-full object-cover"
                                loading="lazy"
                            />
                        </picture>
                    )}
                </div>
            </div>
        </section>
    )
}
