import * as React from 'react'
import ContentfulRichText from 'components/rich-text'
import { IGenericPageSection } from 'interfaces/generic-page-section'
import { BLOCKS } from '@contentful/rich-text-types'
import twConfig from 'tailwind-light-config'
const { screens } = twConfig
import styles from './style.module.css'
import Link from 'next/link'
import { getContentfulImageBackgroundQueryFromTailwindClass } from 'lib/util/image'

interface IProps {
    section: IGenericPageSection
}

const lgBreakPoint = screens.lg
const sectionBackground = 'bg-green'
const contentfulBGQuery = getContentfulImageBackgroundQueryFromTailwindClass(sectionBackground)

const renderNode = {
    // eslint-disable-next-line react/display-name
    [BLOCKS.PARAGRAPH]: (_node, children) => <p className="mb-0">{children}</p>,
}

export const IconMiddleTextSidesSection = ({ section }: IProps): React.ReactElement => {
    if (!section) {
        return null
    }

    const { header, richSubHeader, mediaCollection, mobileMediaCollection, primaryCtaLink } = section

    const mobileMedia = mobileMediaCollection?.items?.[0]
    const desktopMedia = mediaCollection?.items?.[0]

    const contentClassName =
        'text-white text-1xl lg:text-3.25xl leading-120% tracking-tight max-w-[16rem] lg:max-w-none'

    return (
        <section className={`section lg:py-15 ${sectionBackground} ${styles['section-grid']}`}>
            {header?.json && (
                <h2>
                    <ContentfulRichText className={contentClassName} content={header.json} renderNode={renderNode} />
                </h2>
            )}

            {(mobileMedia?.url || desktopMedia?.url) && (
                <picture className="mx-auto lg:-mb-15 lg:mt-8">
                    {desktopMedia?.url && (
                        <>
                            {/* desktop webp  */}
                            <source
                                type="image/webp"
                                media={`(min-width: ${lgBreakPoint})`}
                                srcSet={`
                                    ${desktopMedia.url}?fm=webp&w=420 420w,
                                    ${desktopMedia.url}?fm=webp&w=640 640w
                `}
                            />

                            {/* desktop jpeg  */}
                            <source
                                type="image/jpeg"
                                media={`(min-width: ${lgBreakPoint})`}
                                srcSet={`
                                    ${desktopMedia.url}?fm=jpg&w=420&${contentfulBGQuery} 420w,
                                    ${desktopMedia.url}?fm=jpg&w=640&${contentfulBGQuery} 640w
                `}
                            />
                        </>
                    )}

                    {mobileMedia?.url && (
                        <>
                            {/* mobile webp  */}
                            <source
                                type="image/webp"
                                srcSet={`
                                    ${mobileMedia.url}?fm=webp&w=375 375w,
                                    ${mobileMedia.url}?fm=webp&w=420 420w
                `}
                            />

                            {/* mobile jpeg  */}
                            <source
                                type="image/jpeg"
                                media={`(min-width: ${lgBreakPoint})`}
                                srcSet={`
                                    ${mobileMedia.url}?fm=jpg&w=375&${contentfulBGQuery} 375w,
                                    ${mobileMedia.url}?fm=jpg&w=420&${contentfulBGQuery} 420w
                `}
                            />
                        </>
                    )}

                    <img
                        src={`${mobileMedia?.url || desktopMedia?.url}?fm=jpg&w=640&${contentfulBGQuery}`}
                        alt={
                            mobileMedia?.description ||
                            mobileMedia?.title ||
                            desktopMedia?.description ||
                            desktopMedia?.title
                        }
                        height={mobileMedia?.height || desktopMedia?.height}
                        width={mobileMedia?.width || desktopMedia?.width}
                        className="w-auto max-h-72 lg:max-h-96"
                        loading="lazy"
                    />
                </picture>
            )}

            {richSubHeader?.json && (
                <div className="self-end">
                    <ContentfulRichText
                        className={`${contentClassName} text-right ml-auto`}
                        content={richSubHeader.json}
                        renderNode={renderNode}
                    />

                    {primaryCtaLink?.href && (
                        <Link
                            href={primaryCtaLink.href}
                            className="text-gray-lighter text-xsm leading-140% tracking-wide underline block mt-2.5 text-right"
                        >
                            {primaryCtaLink.name}
                        </Link>
                    )}
                </div>
            )}
        </section>
    )
}
