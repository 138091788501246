import * as React from 'react'

const TargetIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width={64} height={64} {...props}>
        <path
            d="M32 42.89c6 0 11.11-4.89 11.11-11.11 0-6-4.89-11.11-11.11-11.11-6 0-11.11 4.89-11.11 11.11 0 6 5.11 11.11 11.11 11.11zm0 10.222c11.778 0 21.11-9.557 21.11-21.11S43.554 10.89 32 10.89 10.89 20.443 10.89 32 20.443 53.11 32 53.11zM0 32C0 14.222 14.443 0 32 0s32 14.443 32 32-14.443 32-32 32S0 49.778 0 32z"
            fill="#da2032"
        />
    </svg>
)

export default TargetIcon
