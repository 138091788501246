import { IPageSectionIcons } from 'interfaces/page-section-icons'
import * as React from 'react'
import BigIconsWithTextSection from './big-icons-with-text-section'
import IconsImpactSection from './icons-impact-section'
import IconsRepeatingTextSection from './icons-repeating-text-section'
import IconsSection from './icons-section'
import IconsWithQuotesSection from './icons-with-quotes-section'
import IconsIconsOnlySection from './icons-icons-only-section'

interface IProps {
    section: IPageSectionIcons
}

const PageSectionIcons = ({ section }: IProps): React.ReactElement => {
    if (!section) {
        return null
    }

    switch (section.renderType) {
        case 'Icons with header and text':
        case 'Icons with text':
        case 'Icons with text on the side':
        case 'Single icon':
            return <IconsSection section={section} />
        case 'Icons only':
        case 'Static icons desktop, animated icons mobile':
            return <IconsIconsOnlySection section={section} />
        case 'Impact':
            return <IconsImpactSection section={section} />
        case 'Icons with quotes':
            return <IconsWithQuotesSection section={section} />
        case 'Big icons with text':
            return <BigIconsWithTextSection section={section} />
        case 'Repeating text':
            return <IconsRepeatingTextSection section={section} />

        default:
            return null
    }
}

export default PageSectionIcons
