/* eslint-disable react/display-name */
import * as React from 'react'
import { BLOCKS } from '@contentful/rich-text-types'
import ContentfulRichText from 'components/rich-text'
import TitleWithColonIcon from 'components/title-with-colon-icon/title-with-colon-icon'
import { IPageSectionTestimonials } from 'interfaces/page-section-testimonials'
import styles from './style.module.css'
import Link from 'next/link'
import twConfig from 'tailwind-light-config'
import { RenderNode } from '@contentful/rich-text-react-renderer'
const { screens } = twConfig

interface IProps {
    section: IPageSectionTestimonials
}

const lgBreakPoint = screens.lg
const smBreakPoint = screens.sm

const headerRenderNode: RenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => <p className="text-3xl text-center tracking-tight">{children}</p>,
}

const subHeaderRenderNode: RenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => (
        <p className="section--subheader-big mt-4 text-center tracking-tight max-w-xl">{children}</p>
    ),
}

export const TestimonialsGridSection = ({ section }: IProps): React.ReactElement => {
    if (!section) {
        return null
    }

    const { header, richSubHeader, authorsCollection, backgroundColor } = section

    return (
        <section className="section section--bigger-padding flex justify-center" style={{ backgroundColor }}>
            <div className="section--container">
                {/* header */}
                {header?.json && (
                    <h2>
                        <ContentfulRichText content={header.json} renderNode={headerRenderNode} />
                    </h2>
                )}

                <div className="flex justify-center">
                    {richSubHeader?.json && (
                        <ContentfulRichText content={richSubHeader.json} renderNode={subHeaderRenderNode} />
                    )}
                </div>

                {/* testimonials */}
                {authorsCollection?.items?.length > 0 && (
                    <div className={`mt-12 grid gap-5 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 ${styles['grid']}`}>
                        {authorsCollection.items.map((author) => {
                            if (!author) {
                                return
                            }

                            const { picture, sys, title, name, homepageQuote, slug } = author

                            return (
                                <div key={sys?.id} className="rounded-[10px] overflow-hidden lg:max-w-xs flex flex-col">
                                    {/* author picture */}
                                    {picture && (
                                        <div className={styles['aspect-ratio-container']}>
                                            <picture>
                                                {/* webp */}
                                                <source
                                                    type="image/webp"
                                                    srcSet={`
                                            ${picture.url}?fm=webp&w=420 420w,
                                            ${picture.url}?fm=webp&w=640 640w,
                                            ${picture.url}?fm=webp&w=840 840w
                                        `}
                                                    sizes={`(min-width: ${lgBreakPoint}) 25vw, (min-width: ${smBreakPoint}) 30vw, 100vw`}
                                                />

                                                {/* jpeg */}
                                                <source
                                                    type="image/jpeg"
                                                    srcSet={`
                                            ${picture.url}?fm=jpg&w=420 420w,
                                            ${picture.url}?fm=jpg&w=640 640w,
                                            ${picture.url}?fm=jpg&w=840 840w
                                        `}
                                                    sizes={`(min-width: ${lgBreakPoint}) 25vw, (min-width: ${smBreakPoint}) 30vw, 100vw`}
                                                />

                                                <img
                                                    src={picture.url + '?fm=jpg&w=640w'}
                                                    alt={picture.title || picture.description}
                                                    height={picture.height}
                                                    width={picture.width}
                                                    loading="lazy"
                                                    className={`h-full object-cover ${styles['aspect-ratio-element']}`}
                                                />
                                            </picture>
                                        </div>
                                    )}

                                    {/* author details */}
                                    <div className="p-5 pb-10 lg:pb-5 bg-white grow">
                                        {title && (
                                            <>
                                                <TitleWithColonIcon
                                                    content={
                                                        <span className="text-xsm lg:text-sm tracking-wider uppercase">
                                                            {title}
                                                        </span>
                                                    }
                                                    colonIconHeight="14"
                                                />
                                                <hr className="h-0 mt-2.5 border-black/30" />
                                            </>
                                        )}
                                        <figure className="mt-4 flex flex-col-reverse">
                                            <blockquote className="mt-2.5 text-xbase lg:text-base leading-140%">
                                                {homepageQuote}
                                            </blockquote>
                                            <figcaption>
                                                <cite className="not-italic">
                                                    {name && (
                                                        <>
                                                            {slug ? (
                                                                <Link
                                                                    href={'/authors/' + slug}
                                                                    className="text-[19px] lg:text-xl tracking-tight leading-120% border-b border-transparent hover:border-black transition-colors duration-300"
                                                                >
                                                                    {name}
                                                                </Link>
                                                            ) : (
                                                                <span className="text-[19px] lg:text-xl tracking-tight leading-120%">
                                                                    {name}
                                                                </span>
                                                            )}
                                                        </>
                                                    )}
                                                </cite>
                                            </figcaption>
                                        </figure>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                )}
            </div>
        </section>
    )
}
