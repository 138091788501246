import * as React from 'react'
import ContentReviewedBy from 'components/content-reviewed-by/content-reviewed-by'
import { IMEDICAL_REVIEW } from 'interfaces/medical-review'

interface IProps {
    medicalReview: IMEDICAL_REVIEW
    reviewedContentLabel: string | React.ReactNode
    datePrepositionLabel: string
}

export const MedicalReviewSection = ({
    medicalReview,
    reviewedContentLabel,
    datePrepositionLabel,
}: IProps): React.ReactElement => {
    if (!medicalReview) {
        return null
    }

    return (
        <section className="section--v2">
            <ContentReviewedBy
                review={medicalReview}
                reviewedContentLabel={reviewedContentLabel}
                datePrepositionLabel={datePrepositionLabel}
            />
        </section>
    )
}
