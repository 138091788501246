import * as React from 'react'
import {
    pageSectionIconsContentTypename,
    pageSectionComparisonTableContentTypename,
    pageSectionTestimonialsTypename,
    pageSectionVisualizerContentTypename,
    pageSectionGenericTypename,
    pageSectionProjectTypename,
    pageSectionFormTypename,
    pageSectionHTMLTypename,
    pageSectionAccordionTypename,
    medicalReviewTypename,
    pageSectionProductsTypename,
    pageSectionProductCategoriesTypename,
} from 'misc-variables'
import GenericPageSection from './generic-page-section'
import { IGenericPageSection } from 'interfaces/generic-page-section'
import { IPageSectionComparisonTable } from 'interfaces/page-section-comparison-table'
import { IPageSectionIcons } from 'interfaces/page-section-icons'
import { IPageSectionTestimonials } from 'interfaces/page-section-testimonials'
import { IUiResources } from 'interfaces/ui-resource'
import { ICommonCopyDict } from 'interfaces/common-copy'
import { IPageSectionVisualizer } from 'interfaces/page-section-visualizer'
import PageSectionVisualizer from './page-section-visualizer'
import PageSectionTestimonials from './page-section-testimonials'
import { IPageSectionProject } from 'interfaces/page-section-project'
import ProjectSection from './project-section'
import { IPageSectionForm } from 'interfaces/page-section-form'
import { IPageSectionHTML } from 'interfaces/page-section-html'
import HTMLSection from './html-section'
import { IPageSectionAccordion } from 'interfaces/page-section-accordion'
import PageSectionAccordion from './page-section-accordion'
import { IMEDICAL_REVIEW } from 'interfaces/medical-review'
import { MedicalReviewSection } from './medical-review-section/medical-review-section'
import { IPageSectionProducts } from 'interfaces/page-section-products'
import ProductsPageSection from './products-page-section'
import PageSectionIcons from './page-section-icons'
import PageSectionComparisonTable from './page-section-comparison-table'
import { IPageSectionProductCategories } from 'interfaces/page-section-product-categories'
import PageSectionProductCategories from './page-section-product-categories'
import { PageSectionForm } from './form-section/page-section-form'

interface IProps {
    section:
        | IGenericPageSection
        | IPageSectionComparisonTable
        | IPageSectionVisualizer
        | IPageSectionTestimonials
        | IPageSectionIcons
        | IPageSectionProject
        | IPageSectionForm
        | IPageSectionHTML
        | IPageSectionAccordion
        | IMEDICAL_REVIEW
        | IPageSectionProducts
        | IPageSectionProductCategories
    uiResources?: IUiResources
    commonCopy?: ICommonCopyDict
}

export const PageSection = ({ section, uiResources, commonCopy }: IProps): React.ReactElement => {
    // section can be an object with __typename and sys property only
    // consider this as incomplete and return null
    if (Object.keys(section ?? {}).length < 3) {
        return null
    }

    switch (section.__typename) {
        case pageSectionIconsContentTypename:
            const iconsSection = section as IPageSectionIcons
            return <PageSectionIcons section={iconsSection} />

        case pageSectionComparisonTableContentTypename:
            const comparisonSection = section as IPageSectionComparisonTable
            return <PageSectionComparisonTable section={comparisonSection} />

        case pageSectionTestimonialsTypename:
            const testimonialSection = section as IPageSectionTestimonials
            return (
                <PageSectionTestimonials
                    section={testimonialSection}
                    uiResources={uiResources}
                    commonCopy={commonCopy}
                />
            )

        case pageSectionVisualizerContentTypename:
            const visualizerSection = section as IPageSectionVisualizer
            return <PageSectionVisualizer section={visualizerSection} uiResources={uiResources} />

        case pageSectionGenericTypename:
            const genericSection = section as IGenericPageSection
            return <GenericPageSection section={genericSection} uiResources={uiResources} />

        case pageSectionProjectTypename:
            const projectSection = section as IPageSectionProject
            return <ProjectSection section={projectSection} />

        case pageSectionFormTypename:
            const formSection = section as IPageSectionForm
            return <PageSectionForm section={formSection} uiResources={uiResources} commonCopy={commonCopy} />

        case pageSectionHTMLTypename:
            const htmlSection = section as IPageSectionHTML
            return <HTMLSection section={htmlSection} />
        case pageSectionAccordionTypename:
            const pageSectionAccordion = section as IPageSectionAccordion
            return <PageSectionAccordion section={pageSectionAccordion} />

        case medicalReviewTypename:
            const medicalReview = section as IMEDICAL_REVIEW

            /**
             * replace occurrence of {<br />} with <br /> element
             * the following logic splits the ui resource with '{<br />}'
             * and the adds <br /> element in between the spilt texts
             * note: this assumes there will only be single occurrence
             * of '{<br />}'
             */
            const reviewedContentLabel = uiResources['drugFactsAndFAQsReviewedByLabel'].value as string

            const reviewedContentLabelSplit = reviewedContentLabel?.split('{<br />}')

            // define JSX with raw ui resource
            let reviewedContentLabelAsJSXChildren: (string | React.ReactElement)[] = [reviewedContentLabel]

            // if '{<br />}' was found then add <br />
            // element in between split ui resource texts
            if (reviewedContentLabelSplit?.length > 1) {
                reviewedContentLabelAsJSXChildren = [
                    reviewedContentLabelSplit?.[0],
                    <br className="hidden sm:block" key="br" />,
                    reviewedContentLabelSplit?.[1],
                ]
            }

            return (
                <MedicalReviewSection
                    medicalReview={medicalReview}
                    reviewedContentLabel={reviewedContentLabelAsJSXChildren}
                    datePrepositionLabel={uiResources['onLabel'].value as string}
                />
            )

        case pageSectionProductsTypename:
            const productsSection = section as IPageSectionProducts
            return <ProductsPageSection section={productsSection} />

        case pageSectionProductCategoriesTypename:
            const productCategorySection = section as IPageSectionProductCategories
            return <PageSectionProductCategories section={productCategorySection} uiResources={uiResources} />

        default:
            return null
    }
}
