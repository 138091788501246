/* eslint-disable react/display-name */
import * as React from 'react'
import ContentfulRichText from 'components/rich-text'
import { BLOCKS } from '@contentful/rich-text-types'
import TestimonialCarousel from 'components/testimonial-carousel/testimonial-carousel'
import { IUiResources } from 'interfaces/ui-resource'
import { IPageSectionTestimonials } from 'interfaces/page-section-testimonials'

interface IProps {
    section: IPageSectionTestimonials
    uiResources: IUiResources
}

const headerRenderOptions = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (_node, children) => (
            <h2 className="mt-0 section--header lg:font-bold leading-9 lg:leading-13">{children}</h2>
        ),
    },
}

const subHeaderRenderOptions = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (_node, children) => (
            <span className="section--subheader lg:text-2xl tracking-tight">{children}</span>
        ),
    },
}

const TestimonialCarouselSection = ({ section, uiResources }: IProps): React.ReactElement => {
    const { header, richSubHeader, authorsCollection } = section ?? {}

    return (
        <section className="section section">
            <div>
                <div>
                    <ContentfulRichText className="header" content={header?.json} renderOptions={headerRenderOptions} />
                </div>
                <div className="mt-5">
                    <ContentfulRichText
                        className="subheader"
                        content={richSubHeader?.json}
                        renderOptions={subHeaderRenderOptions}
                    />
                </div>
            </div>
            <div className="mt-7">
                <TestimonialCarousel uiResources={uiResources} authorsCollection={authorsCollection} />
            </div>
        </section>
    )
}

export default TestimonialCarouselSection
