import * as React from 'react'
import Button from 'components/buttons/button'
import { IMedicine } from 'interfaces/medicine'
import Link from 'next/link'
import twConfig from 'tailwind-light-config'
const { screens } = twConfig

interface IProps {
    medicine: IMedicine
    cardCtaHrefEligibility: string
    genericForLabel: string
    rxCardCtaLabel: string
}

const lgBreakPoint = screens.lg

export const PageSectionProductCategoryRxCard = ({
    medicine,
    cardCtaHrefEligibility,
    genericForLabel,
    rxCardCtaLabel,
}: IProps): React.ReactElement => {
    const {
        sys,
        abstractImage,
        cabinetDrugName,
        compareTo,
        linkedFrom: { productPageMedicineCollection },
    } = medicine

    const medicineProductPage = productPageMedicineCollection.items[0].linkedFrom.productPageCollection.items[0]
    const { slug } = medicineProductPage ?? {}

    return (
        <div key={sys.id}>
            <Link href={'/products/' + slug} className="flex flex-col snap-center">
                {abstractImage?.url && (
                    <picture>
                        <source
                            type="image/webp"
                            media={`(min-width: ${lgBreakPoint})`}
                            srcSet={abstractImage.url + '?fm=webp&w=1200'}
                        />
                        <source
                            type="image/jpeg"
                            media={`(min-width: ${lgBreakPoint})`}
                            srcSet={abstractImage.url + '?fm=jpg&w=1200'}
                        />

                        <source type="image/webp" srcSet={abstractImage.url + '?fm=webp&w=800'} />
                        <source type="image/jpeg" srcSet={abstractImage.url + '?fm=jpg&w=800'} />

                        <img
                            src={abstractImage.url + '?fm=jpg&w=1200'}
                            alt={abstractImage.description || abstractImage.title}
                            height={abstractImage.height}
                            width={abstractImage.width}
                            className="h-full w-full object-cover rounded-2lg"
                            loading="lazy"
                        />
                    </picture>
                )}
            </Link>

            <div className="flex flex-col justify-between self-end flex-grow w-full px-4">
                <div className="mt-5">
                    <div>
                        <div className="flex justify-start lg:text-lg font-bold leading-120%">
                            <span>{cabinetDrugName}</span>
                        </div>
                    </div>
                    <p className="mt-2 text-sm lg:text-base leading-140$ font-medium">
                        {genericForLabel} {compareTo}
                    </p>
                </div>
            </div>
            <Link href={cardCtaHrefEligibility}>
                <Button className="px-10 mt-5 w-full">{rxCardCtaLabel}</Button>
            </Link>
        </div>
    )
}
