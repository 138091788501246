import Head from 'next/head'
import * as React from 'react'
import { IImage } from 'interfaces/media'
import twConfig from 'tailwind-light-config'

const { screens } = twConfig

interface IProps {
    image: IImage
    mobileImage?: IImage | null
    className?: string
    pictureClassName?: string
    width?: number | null
    height?: number | null
    isPreload?: boolean
    desktopImageSrcSizesOverride?: Array<string> | null
    mobileImageSrcSizesOverride?: Array<string> | null
}

const smBreakPoint = Number(screens.sm.replace('px', ''))

const generateImageSrcSet = (url: string, srcSizes: Array<string>, format = 'jpg', quality = 90) => {
    return srcSizes.map((size) => `${url}?w=${size}&fm=${format}&q=${quality} ${size}w`).join(',')
}

export const ContentfulImage = ({
    image,
    mobileImage = null,
    className = '',
    pictureClassName = '',
    width = null,
    height = null,
    isPreload = false,
    desktopImageSrcSizesOverride = null,
    mobileImageSrcSizesOverride = null,
}: IProps): React.ReactElement => {
    // Default to the main image if a mobile alternative is not provided
    mobileImage = mobileImage || image
    width = width || mobileImage?.width
    height = height || mobileImage?.height
    const loadingType = isPreload ? 'eager' : 'lazy'
    const desktopImageSrcSizes = desktopImageSrcSizesOverride || ['768', '1280', '1440', '1600']
    const mobileImageSrcSizes = mobileImageSrcSizesOverride || ['360', '420', '640']
    return (
        <>
            {isPreload && (
                <Head>
                    {/* we're only preloading webp image formats,
                    this does mean that browsers that don't support webp won't preload
                    webp has great browser support now, only IE and safari for macOS < 11 don't support it
                */}
                    <link
                        media={`(max-width: ${smBreakPoint - 1}px)`}
                        rel="preload"
                        as="image"
                        href={`${mobileImage?.url}?fm=webp&w=640&q=50`}
                        type="image/webp"
                        imageSrcSet={generateImageSrcSet(mobileImage?.url, mobileImageSrcSizes, 'webp')}
                    />
                    <link
                        media={`(min-width: ${smBreakPoint}px)`}
                        rel="preload"
                        as="image"
                        href={`${image?.url}?fm=webp&w=1024&q=50`}
                        type="image/webp"
                        imageSrcSet={generateImageSrcSet(image?.url, desktopImageSrcSizes, 'webp')}
                    />
                </Head>
            )}
            <picture className={pictureClassName}>
                {/* large screens */}
                <source
                    type="image/webp"
                    media={`(min-width: ${screens.sm})`}
                    srcSet={generateImageSrcSet(image?.url, desktopImageSrcSizes, 'webp')}
                />
                <source
                    type="image/jpeg"
                    media={`(min-width: ${screens.sm})`}
                    srcSet={generateImageSrcSet(image?.url, desktopImageSrcSizes)}
                />
                {/* small screens */}
                {/* svg */}
                {mobileImage.url.endsWith('.svg') && <source type="image/svg+xml" srcSet={mobileImage.url} />}
                <source type="image/webp" srcSet={generateImageSrcSet(mobileImage?.url, mobileImageSrcSizes, 'webp')} />
                <source type="image/jpeg" srcSet={generateImageSrcSet(mobileImage?.url, mobileImageSrcSizes)} />
                <img
                    width={width}
                    height={height}
                    alt={mobileImage?.description || mobileImage?.title}
                    src={`${mobileImage?.url}?w=1024&fm=jpg&q=70`}
                    className={className}
                    loading={loadingType}
                />
            </picture>
        </>
    )
}
