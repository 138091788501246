/* eslint-disable react/display-name */
import * as React from 'react'
import { BLOCKS } from '@contentful/rich-text-types'
import ContentfulRichText from 'components/rich-text'
import { IGenericPageSection } from 'interfaces/generic-page-section'
import styles from './style.module.css'

interface IProps {
    section: IGenericPageSection
}

const headerRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => <p className="text-xbase lg:text-base font-bold">{children}</p>,
}

const paragraphRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => (
        <p
            className={`text-xs lg:text-xsm tracking-wide leading-140% font-medium first:mt-0 mt-5 ${styles['reference']}`}
        >
            {children}
        </p>
    ),
}

export const ReferencesSection = ({ section }: IProps): React.ReactElement => {
    if (!section) {
        return null
    }

    const { header, paragraphCopy, backgroundColor } = section

    return (
        <section
            style={{ backgroundColor }}
            className={`section--v2 ${backgroundColor ? 'section--small-margin-top' : ''}`}
        >
            <hr className="border-gray-darker/30" />

            <div className="mt-2.5 flex">
                {header?.json && (
                    <ContentfulRichText className="header" content={header.json} renderNode={headerRenderNode} />
                )}

                {/* references */}
                {paragraphCopy?.json && (
                    <ContentfulRichText
                        className={`references pl-5 lg:pl-16 xl:pl-28 ${styles['references-container']}`}
                        content={paragraphCopy.json}
                        renderNode={paragraphRenderNode}
                    />
                )}
            </div>
        </section>
    )
}
