import * as React from 'react'
import ColoredTextSection from './colored-text-section/colored-text-section'
import TwoColumnsWithCoverImageSection from './two-columns-with-cover-image-section/two-columns-with-cover-image-section'
import TwoColumnsWithImageAlignSection from './two-columns-with-image-align-section/two-columns-with-image-align-section'
import TwoImagesSection from './two-images-section/two-image-section'
import { IGenericPageSection } from 'interfaces/generic-page-section'
import MediaSlideShowSection from './media-slideshow-section'
import LargeTextWithIconSection from './large-text-with-icon-section'
import TwoColumnsWithTokenImageAndIllustration from './two-columns-with-token-image-and-illistration'
import { IUiResources } from 'interfaces/ui-resource'
import SingleIconWithTextSection from './single-icon-with-text-section'
import ThreeInARowSection from './three-in-a-row'
import IconMiddleTextSidesSection from './icon-middle-text-sides-section'
import TextOnPlainBackgroundSection from './text-on-plain-background'
import TextLeftRightOverMedia from './text-left-right-over-media'
import IconLeftTextRightWithBackgroundImage from './icon-left-text-right-with-background-image'
import ReferencesSection from './references-section'
import TheoryOfChange from './theory-of-change-section'
import BottlesUnderWaterSection from './bottles-under-water-section'
import CenteredLayoutWithZigZagText from './centered-layout-with-zigzag-text'
import TwoCardsSideBySideSection from './two-cards-side-by-side-section'
import ScrollingGridSection from './scrolling-grid-section'
import GenericBadgeAndParagraphSection from './generic-badge-and-paragraph-section'
import GenericBannerSection from './generic-banner-section'
import GenericMasonrySection from './generic-masonry-section'
import { IconMiddleImageSidesSection } from './icon-middle-image-sides-section/icon-middle-image-sides-section'
import SingleIconWithCopySideBySideSection from './single-icon-with-copy-side-by-side-section'
import StoreLocatorSection from './store-locator-section'
import GenericHeroTextLeftVideoRightSection from './generic-hero-text-left-video-right'
import HeaderWithLargeButtonsSection from './header-with-two-large-buttons'
import HeaderWithCenteredImageAndCTASection from './header-with-centered-image-and-cta-section'

interface IProps {
    section: IGenericPageSection
    uiResources?: IUiResources
}

const GenericPageSection = ({ section, uiResources }: IProps): React.ReactElement => {
    switch (section?.renderType) {
        case 'Colored Text':
            return <ColoredTextSection {...section} />

        case 'Two Columns With Token Image':
            return <TwoColumnsWithImageAlignSection section={section} playMediaLabel={uiResources['playMediaLabel']} />

        case 'Two Columns With Cover Image':
            return <TwoColumnsWithCoverImageSection {...section} />

        case 'Two Images':
            return <TwoImagesSection {...section} />

        case 'Media Slide Show':
            return (
                <MediaSlideShowSection
                    section={section}
                    stepLabelUIResource={uiResources?.['mediaSlideShowStepLabel']}
                    videoProgressBarAriaLabel={uiResources?.['videoProgressBarLabel']}
                    playMediaLabel={uiResources['playMediaLabel']}
                />
            )
        case 'Large Text With Icon':
            return <LargeTextWithIconSection section={section} />
        case 'Two Colums With Token Image And Illustration':
            return <TwoColumnsWithTokenImageAndIllustration section={section} />

        case 'Single Icon With Text':
            return <SingleIconWithTextSection section={section} />

        case 'Single Icon With Copy Side By Side':
            return <SingleIconWithCopySideBySideSection section={section} />

        case 'Three In A Row':
        case 'Three In A Row Left Aligned With Callouts':
        case 'Three In A Row Center Aligned With Image':
            return <ThreeInARowSection section={section} />

        case 'Icon Middle Text Both Sides':
            return <IconMiddleTextSidesSection section={section} />

        case 'Icon Middle Image Both Sides':
        case 'Icon Middle Image Both Sides V2':
            return <IconMiddleImageSidesSection section={section} />

        case 'Text On Plain Background':
            return <TextOnPlainBackgroundSection section={section} />

        case 'Text On Left And On Right Over Background Media':
            return <TextLeftRightOverMedia section={section} />

        case 'Icon On Left And Text On Right Over Background Image':
            return <IconLeftTextRightWithBackgroundImage section={section} />

        case 'References':
            return <ReferencesSection section={section} />

        case 'Theory Of Change':
            return <TheoryOfChange section={section} />

        case 'Bottles Under Water':
            return <BottlesUnderWaterSection section={section} />

        case 'Centerd layout with zigzag text':
            return <CenteredLayoutWithZigZagText section={section} />
        case 'Two cards - side by side':
            return <TwoCardsSideBySideSection section={section} />
        case 'Scrolling grid':
            return <ScrollingGridSection section={section} />
        case 'Badge and paragraph columns':
            return <GenericBadgeAndParagraphSection section={section} />
        case 'Banner':
            return <GenericBannerSection section={section} />
        case 'Masonry':
            return <GenericMasonrySection section={section} uiResources={uiResources} />
        case 'Store Locator':
            return <StoreLocatorSection section={section} uiResources={uiResources} />
        case 'Header With Centered Image and CTA':
            return <HeaderWithCenteredImageAndCTASection section={section} uiResources={uiResources} />
        case 'Generic Hero Text Left Video Right':
            return <GenericHeroTextLeftVideoRightSection section={section} uiResources={uiResources} />
        case 'Header With Two Large Buttons':
            return <HeaderWithLargeButtonsSection section={section} />

        default:
            return null
    }
}

export default GenericPageSection
