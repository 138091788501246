/* eslint-disable react/display-name */
import * as React from 'react'
import ContentfulRichText from 'components/rich-text'
import { IGenericPageSection } from 'interfaces/generic-page-section'
import { BLOCKS } from '@contentful/rich-text-types'
import { getContentfulTransparentBackgroundImageQuery } from 'lib/util/image'
import twConfig from 'tailwind-light-config'
const { screens } = twConfig

interface IProps {
    section: IGenericPageSection
}
const lgBreakPoint = screens.lg

const contentfulBGquery = getContentfulTransparentBackgroundImageQuery()

const headerRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => (
        <span className="block section--header text-6xl lg:text-[4.375rem] sm:whitespace-pre">{children}</span>
    ),
}

const paragraphRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => <p className="section--paragraph text-1xl lg:text-3.25xl">{children}</p>,
}

export const LargeTextWithIconSection = ({ section }: IProps): React.ReactElement => {
    if (!section) {
        return null
    }

    const { header, paragraphCopy, mediaCollection, mobileMediaCollection, backgroundColor } = section

    const sectionImageMobile = mobileMediaCollection?.items?.[0]
    const sectionImageDesktop = mediaCollection?.items?.[0]

    return (
        <section className="section flex flex-col lg:flex-row" style={{ backgroundColor }}>
            {/* section content */}
            <div className="grow">
                {header?.json && (
                    <h1>
                        <ContentfulRichText content={header.json} renderNode={headerRenderNode} />
                    </h1>
                )}

                {paragraphCopy?.json && (
                    <div className="mt-10 lg:mt-15 max-w-xl">
                        <ContentfulRichText content={paragraphCopy.json} renderNode={paragraphRenderNode} />
                    </div>
                )}
            </div>

            {/* section image */}
            {(sectionImageMobile || sectionImageDesktop) && (
                <div className="shrink-0 flex items-end justify-end mt-10 lg:mt-0 lg:ml-10 lg:pr-7.5 2xl:pr-15">
                    <picture>
                        {/* desktop image */}
                        {sectionImageDesktop && (
                            <>
                                {/* desktop - svg */}
                                {sectionImageDesktop.url?.endsWith('.svg') && (
                                    <source
                                        type="image/svg+xml"
                                        media={`(min-width: ${lgBreakPoint})`}
                                        srcSet={sectionImageDesktop.url}
                                    />
                                )}

                                {/* desktop - webp */}
                                <source
                                    type="image/webp"
                                    media={`(min-width: ${lgBreakPoint})`}
                                    srcSet={sectionImageDesktop.url + '?fm=webp&w=400 400w'}
                                />

                                {/* desktop - jpeg */}
                                <source
                                    type="image/jpeg"
                                    media={`(min-width: ${lgBreakPoint})`}
                                    srcSet={`
                                        ${sectionImageDesktop.url}?fm=jpg&w=400&${contentfulBGquery} 400w
                                    `}
                                />
                            </>
                        )}

                        {/* mobile image */}
                        {sectionImageMobile && (
                            <>
                                {/* desktop - svg */}
                                {sectionImageMobile.url?.endsWith('.svg') && (
                                    <source type="image/svg+xml" srcSet={sectionImageDesktop.url} />
                                )}

                                {/* mobile - webp */}
                                <source type="image/webp" srcSet={sectionImageMobile.url + '?fm=webp&w=200 200w'} />

                                {/* mobile - jpeg */}
                                <source
                                    type="image/jpeg"
                                    srcSet={`
                                        ${sectionImageMobile.url}?fm=jpg&w=200&${contentfulBGquery} 200w
                                    `}
                                />
                            </>
                        )}

                        <img
                            src={`
                                ${sectionImageMobile.url || sectionImageDesktop.url}?fm=jpg&w=400&${contentfulBGquery}
                            `}
                            alt={
                                sectionImageMobile.title ||
                                sectionImageMobile.description ||
                                sectionImageDesktop.title ||
                                sectionImageDesktop.description
                            }
                            width={sectionImageMobile.width || sectionImageDesktop.width}
                            height={sectionImageMobile.height || sectionImageDesktop.height}
                            className="w-24 lg:w-32"
                            loading="lazy"
                        />
                    </picture>
                </div>
            )}
        </section>
    )
}
